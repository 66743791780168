/**
* Forms
*/

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.form-item {
  @include margin(null null 15px);

  &:last-child {
    @include margin(null null 0);
  }

  &--with-icon {
    input {
      @include padding(null null null 50px);
    }
  }
}

#upload-preview-url {
  background: $dark-gray-2;
  border: none;
  color: $white;
  font-size: 1.8vh;
  line-height: 1;
  padding: 2vh;
  width: 70%;

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $white;
    font-size: 1vw;
    font-weight: $font-medium;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $white;
    font-size: 1vw;
    font-weight: $font-medium;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $white;
    font-size: 1vw;
    font-weight: $font-medium;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $white;
    font-size: 1vw;
    font-weight: $font-medium;
  }
}

.form-input {
  @include padding(null 15px);
  // @include font(18, 24);

  background: $dark-gray;
  background-size: 18px 18px !important;
  border: 1px solid $gray-light;
  color: $white;
  font-size: 16px;
  height: 40px;
  outline: none;
  width: 100%;

  @include grid-media($md) {
    font-size: 1vw;
    height: 5.5vh;
  }

  &--checkbox {
    &.hidelabel {
      + label {
        color: transparent;
        margin: 0 auto;
        padding: 0 !important;
        width: 25px;
      }
    }
  }

  &--date {
    background: url('../../../images/svg-images/icon-calendar.svg') left 15px
      center no-repeat;
  }

  &--time {
    background: url('../../../images/svg-images/icon-clock.svg') left 15px
      center no-repeat;
  }

  &--search {
    background: url('../../../images/svg-images/icon-search.svg') left 0.9vw
      center no-repeat;
    background-color: rgba(35, 41, 47, 0.6);
    background-size: 1vw !important;
    border: none;
    border-radius: 3vw;
    color: $white;
    font-family: $font;
    font-size: 2vh;
    height: 50%;
    padding-left: 2.5vw;
    width: 80%;

    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
      color: $white;
    }
  }
}

input {
  line-height: 2;
}

.form-actions {
  display: flex;
  flex-wrap: wrap;

  &--right {
    button {
      &:first-child {
        @include margin(null null null auto);

        margin-right: 1vw;
      }
    }
  }
}

.form {
  margin-top: 30px;

  &__form--fields {
    label {
      color: $white;
      cursor: pointer;
      font-family: $font;
      font-size: 12px;
      font-weight: normal;
      height: 100%;

      @include grid-media($md) {
        font-size: 0.9vw;
      }
    }

    a {
      color: $lightOrange;
      float: right;
      font-family: $font;
      font-size: 14px;
      font-weight: $font-normal;
      margin: 0;
      margin-top: 10px;
    }
  }

  &--field {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    &:last-of-type {
      margin: 0;
    }
  }

  &--buttons {
    margin-top: 50px;
  }
}

input[type='checkbox'].form-input--checkbox {
  &:not(:checked),
  &:checked {
    + label {
      color: $white;

      &:before,
      &:after {
        border-radius: 0;
      }
    }
  }

  &:checked {
    + label {
      &:after {
        background-image: url($icon-brown-tick-box);
      }
    }
  }
}

input[type='checkbox'],
input[type='radio'] {
  display: none;

  &:disabled {
    & + label {
      &:before,
      &:after {
        cursor: not-allowed;
        filter: grayscale(70%);
      }
    }
  }

  &:not(:checked),
  &:checked {
    + label {
      display: block;
      position: relative;
      padding-left: 30px;

      @include grid-media($md) {
        padding-left: 2.5vw;
      }

      &:before,
      &:after {
        border-radius: 50%;
        content: '';
        height: 18px;
        left: 0;
        position: absolute;
        width: 18px;
        top: -0.2vw;

        @include grid-media($md) {
          height: 1.2vw;
          width: 1.2vw;
        }
      }

      &:before {
        border: 0.133vw solid $white;
      }

      &:after {
        background-repeat: no-repeat;
        background-position: center;
        transform: scale(0.7);
        // transition: scale .4s ease;

        @include grid-media($md) {
          background-size: 1.6vw;
        }
      }
    }
  }

  &:checked {
    + label {
      &:after {
        background-image: url($icon-brown-tick);
        transform: scale(1);
        border: 0.133vw solid #fff;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // background: url('../../../images/svg-images/icon-search.svg') left 0.9vw center no-repeat;
  // border: 1px solid $orange;
  -webkit-text-fill-color: $white;
  -webkit-box-shadow: 0 0 0px 1000px rgba(35, 41, 47, 0.6) inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type='date'] {
  position: relative;
}

input[type='search'] {
  -moz-appearance: none; /* older firefox */
  -webkit-appearance: none; /* safari, chrome, edge and ie mobile */
  appearance: none; /* rest */
}

input::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: -15px;
  // background: url('../../../images/svg-images/icon-calendar.svg');
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: right;
  // background: red;
  // width: 77%;
}

.correct {
  input[type='checkbox'] {
    &:checked {
      + label {
        color: $green;

        &:after {
          background-image: url('../../../images/green-tick.svg');
        }
      }
    }
  }
}

.incorrect {
  input[type='checkbox'] {
    &:checked {
      + label {
        color: $red;

        &:after {
          background-image: url('../../../images/red-cross.svg');
        }
      }
    }
  }
}
