// ==========================================================================
// Colors
// ==========================================================================

// $plyr-color-main: hsl(360, 60%, 49%) !default;
// $plyr-color-main: #091754 !default;
$plyr-color-main: #ff9900 !default;

// Grayscale
$plyr-color-gray-9: hsl(210, 15%, 16%);
$plyr-color-gray-8: lighten($plyr-color-gray-9, 9%);
$plyr-color-gray-7: lighten($plyr-color-gray-8, 9%);
$plyr-color-gray-6: lighten($plyr-color-gray-7, 9%);
$plyr-color-gray-5: lighten($plyr-color-gray-6, 9%);
$plyr-color-gray-4: lighten($plyr-color-gray-5, 9%);
$plyr-color-gray-3: lighten($plyr-color-gray-4, 9%);
$plyr-color-gray-2: lighten($plyr-color-gray-3, 9%);
$plyr-color-gray-1: lighten($plyr-color-gray-2, 9%);
$plyr-color-gray-0: lighten($plyr-color-gray-1, 9%);
