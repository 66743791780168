.reel-view {
  margin-top: 5vh;

  .Dropdown-root {
    margin-bottom: 0 !important;
  }

  .btn--back {
    display: inline-block;
    margin-left: 12%;
  }

  &--wrapper {
    display: flex;
    flex-direction: row;

    &-column {
      width: 100%;
      &:first-of-type {
        // max-width: 20%;
      }

      &:last-of-type {
        // max-width: 80%;
      }
    }

    &.singlereel {
      .Dropdown-root {
        margin-bottom: 0 !important;
      }

      .reel-view--grid-noitem {
        margin-top: 0;
      }

      .reel-view--grid {
        flex-direction: row;
        margin-bottom: 10vh;

        .reel-view--grid__reel-title {
          align-items: center;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          justify-content: center;
          margin-right: 2%;
          height: 45vh;
          max-width: 15%;
          position: relative;
          width: 100%;

          &:before {
            background: $black;
            content: '';
            height: 100%;
            left: 0;
            opacity: 0.7;
            position: absolute;
            top: 0;
            width: 100%;
          }

          p {
            font-size: 2.3vh;
            font-weight: $font-bold;
            position: relative;
            z-index: 10;
          }

          .reel-view--grid__reel-title--settings {
            align-items: center;
            background: $black;
            bottom: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            height: 5vh;
            position: absolute;
            opacity: 0.7;
            left: 0;
            width: 100%;

            img {
              cursor: pointer;
              width: 6.5%;
            }

            .spacer {
              width: 0.1vh;
              height: 3vh;
              display: inline-block;
              background: $light-gray-2;
            }
          }
        }

        .reel-view--grid-wrapper {
          max-width: 83%;
          width: 100%;

          .reel-view--grid-video-wrapper {
            margin-right: 1.25%;
            max-width: 19%;

            .reel-view--grid-video {
              margin-right: 0;
            }

            &:nth-child(5n) {
              // .reel-view--grid-video {
              margin-right: 0;
              // }
            }
          }
        }
      }
    }
  }

  &--options {
  }
  &--option {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 20vh;
    justify-content: center;
    position: relative;
    margin-right: 2vh;

    &:before {
      content: '';
      background: $dark-gray-2;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 0.4vw;
    }

    h3 {
      margin-bottom: 0;
    }

    &.active {
      &:before {
        background: $orange;
      }

      h3 {
        // color: $orange;
        font-weight: $font-bold;
      }

      .reel-view--option-icon {
        &.groupimg {
          background-image: url($icon-group-hover);
        }

        &.userimg {
          background-image: url($icon-user-hover);
        }

        &.reelimg {
          background-image: url($icon-reel-hover);
        }

        &.assessmentimg {
          background-image: url($icon-assessment-hover);
        }
      }
    }

    &:hover {
      cursor: pointer;

      h3 {
        // color: $orange;
        font-weight: $font-bold;
      }

      .reel-view--option-icon {
        &.groupimg {
          background-image: url($icon-group-hover);
        }

        &.userimg {
          background-image: url($icon-user-hover);
        }

        &.reelimg {
          background-image: url($icon-reel-hover);
        }

        &.assessmentimg {
          background-image: url($icon-assessment-hover);
        }
      }
    }

    &-icon {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 2.7vw;
      width: 5vw;

      &.groupimg {
        background-image: url('../../../images/svg-images/icon-group.svg');
      }

      &.userimg {
        background-image: url('../../../images/svg-images/icon-user.svg');
      }

      &.reelimg {
        background-image: url('../../../images/svg-images/icon-reel.svg');
      }

      &.assessmentimg {
        background-image: url('../../../images/svg-images/icon-assessment.svg');
      }
    }
  }

  &--page {
    &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 2vh;
    }
    &-selection {
      max-width: 40%;
      width: 100%;

      h2 {
        font-size: 2vw;
        font-weight: $font-bold;
        margin: 0;
        // max-width: 30%;
        width: 100%;
        // overflow: hidden;
        padding: 0 1vh;
        padding-left: 0;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }

      .breadcrumbs {
        color: $gray;
        cursor: pointer;

        a {
          color: $gray;
          text-decoration: none;

          &:last-of-type {
            color: $white;
            text-decoration: underline;
          }
        }
      }
    }

    &-settings {
      // align-items: center;
      display: flex;
      flex-direction: row;
      margin-top: 6vh;
      max-width: 55%;
      width: 100%;

      .form-input--search {
        background: url('../../../images/svg-images/icon-search-gray.svg') right
          0.9vw center no-repeat;
        background-color: $dark-gray-2;
        background-size: 1.5vw !important;
        border-radius: 0;
        height: 5vh;
        margin-right: 2vh;
        padding-left: 1vw;
        padding-right: 2.5vw;
        width: 100%;
      }

      .Dropdown-control {
        height: 5vh !important;
        margin-right: 2vh !important;
        padding: 1.3vh 2vh !important;
      }

      .btn--createnew {
        font-size: 1vw;
        margin-right: 2vh;
        min-width: 10vw;
        padding-left: 3.5%;
        position: relative;
        // width: 100%;

        &:before {
          content: '+';
          font-size: 2vw;
          font-weight: $font-thin;
          left: 5%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &-reelselection {
      display: flex;

      p {
        color: $gray;
        cursor: pointer;
        margin-right: 3vh;

        &:last-of-type {
          margin-right: 0;
        }

        &.active {
          color: $orange;
          border-bottom: 0.05vw solid $orange;
          padding-bottom: 2vh;
        }
      }
    }

    &-data {
      display: flex;
      justify-content: space-between;

      &-cell {
        align-items: center;
        background-color: $orange;
        display: flex;
        justify-content: space-between;
        max-width: 33%;
        padding: 1vh 2vh;
        width: 100%;

        &:nth-of-type(2n) {
          background-color: $dark-gray-3;
        }

        p {
          font-size: 0.8vw;
          font-weight: $font-bold;
        }

        span {
          font-size: 1vw;
          font-weight: $font-bold;
        }
      }
    }

    &-info-message {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }

  &--table {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5vh;
    width: 100%;

    &-header,
    &-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    &-body {
      margin-top: 2vh;
    }

    &-header {
      border-bottom: 0.1vw solid $dark-gray-2;

      .all-users-table--table-cell {
        h5 {
          font-size: 1vw;
          font-weight: $font-bold;
          margin: 2vh 0;
        }
      }
    }

    &-body {
      flex-wrap: wrap;

      .all-users-table--table-row {
        &:hover {
          background-color: $dark-gray-3;
        }
      }

      .all-users-table--table-cell {
        &:nth-of-type(5) {
          p {
            padding-left: 2vh;
          }
        }
      }

      .reel-view--grid-video-wrapper {
        max-width: 12%;

        .reel-view--grid-video {
          margin-right: 0;
          max-height: 11vh;
        }
      }
    }

    &-row {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1vh 0;
      width: 100%;
    }

    &-cell {
      &:nth-of-type(1) {
        // text-align: center;
        max-width: 12%;
        width: 100%;
      }
      &:nth-of-type(2) {
        max-width: 14%;
        width: 100%;
      }
      &:nth-of-type(3) {
        max-width: 10%;
        width: 100%;
      }
      &:nth-of-type(4) {
        max-width: 10%;
        width: 100%;
      }
      &:nth-of-type(5) {
        max-width: 10%;
        width: 100%;
      }
      &:nth-of-type(6) {
        max-width: 10%;
        width: 100%;
      }
      &:nth-of-type(7) {
        max-width: 14%;
        width: 100%;
      }
      // &:nth-of-type(8) {
      //   max-width: 13%;
      //   width: 100%;
      // }

      h5 {
        margin-top: 2vh;
      }

      p {
        color: $gray;
      }

      .Dropdown-root {
        width: 70% !important;

        .Dropdown-control {
          &:before {
            right: 4% !important;
          }
        }
      }
    }

    &-avatarimg {
      border: 0.1vw solid $white;
      border-radius: 10vw;
      height: 2vw;
      width: 2vw;
    }

    &__category-name {
      font-size: 1vw;
      font-weight: $font-bold;
      margin-bottom: 0;
    }
  }

  &--grid {
    display: flex;
    flex-direction: column;

    &-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // padding: 0 1.5vw;
    }

    &-title {
      border-bottom: 0.1vh solid $dark-gray-1;
      font-size: 1vw;
      font-weight: $font-bold;
      padding-bottom: 1vh;
    }

    &-noitem {
      background-color: $dark-gray;
      color: transparent;
      height: 20vh;
      position: relative;
      width: 15vw;

      &:before {
        align-items: center;
        color: $white;
        content: 'No Items';
        display: flex;
        font-size: 0.7vw;
        justify-content: center;
        height: 100%;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }
    }

    &-video {
      // background: $red;
      background-position: center;
      background-size: cover;
      height: 20vh;
      margin-right: 2vh;
      // overflow: hidden;
      position: relative;
      // width: 15vw;

      &:hover {
        .reel-view--grid-video-settings {
          display: flex;
        }
      }

      &.unpublished {
        position: relative;
        // width: 100%;

        // .reel-thumbnail {

        // }

        .reel-view--grid-video-unpublished {
          cursor: pointer;
          display: inline-block;
          height: 100%;
          width: 100%;
          position: absolute;
          z-index: 1;

          &:before {
            align-items: center;
            content: 'Unpublished';
            display: flex;
            font-size: 1vw;
            font-weight: $font-medium;
            justify-content: center;
            height: 100%;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            z-index: 2;
          }

          &:after {
            background-color: $dark-gray;
            content: '';
            left: 0;
            height: 100%;
            opacity: 0.8;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
          }
        }

        .reel-view--grid-video-settings {
          z-index: 2;
        }
      }

      .reel-thumbnail {
        //    left: 50%;
        // position: absolute;
        //    top: 50%;
        //    transform: translate(-50%, -50%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        height: 100%;
        width: 100%;
      }

      &.emptyreel-document {
        background-color: $dark-gray;
        position: relative;
        width: 100%;

        &:before {
          align-items: center;
          content: 'No Documents';
          display: flex;
          font-size: 0.9vw;
          justify-content: center;
          height: 100%;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
        }
      }

      &-wrapper {
        max-width: 20%;
        width: 100%;

        p {
          margin-bottom: 5vh;
          margin-right: 2vh;
        }
      }

      &-settings {
        align-items: center;
        background: $popup-black;
        display: none;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
        right: 0;
        position: absolute;
        top: 0;

        img {
          cursor: pointer;
          width: 45%;
        }

        &-stats {
          position: relative;
          text-align: center;

          img {
            width: 80%;
          }

          &-datawrapper {
            background: $black;
            box-shadow: 0.1vw 0.1vw 0.1vw 0.1vw $dark-gray-3;
            display: flex;
            flex-direction: column;
            padding: 2vh;
            position: absolute;
            // right: -5px;
            text-align: left;
            // top: -50%;
            // transform: translateX(90%);
            right: 0;
            top: 0;
            z-index: 5;
          }

          &-data {
            align-items: center;
            border-bottom: 0.05vw solid $light-gray-2;
            display: flex;
            justify-content: space-between;
            margin-bottom: 2vh;
            padding-bottom: 2vh;

            &:last-of-type {
              border: none;
              padding-bottom: 0;
              margin-bottom: 0;
            }

            p {
              font-size: 0.9vw;
              margin: 0;
              margin-right: 5vh;
              text-overflow: unset !important;
              white-space: unset !important;
              width: 50%;
            }

            span {
              font-size: 0.9vw;
              text-align: right;
              width: 50%;
            }
          }
        }

        &.datamodal {
          display: flex;
        }
      }

      &.addbutton {
        align-items: center;
        background: $dark-gray-2;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 1vh;
        text-align: center;

        img {
          width: 15%;
        }

        p {
          margin-bottom: 0;
          margin-right: 0;
        }
      }
    }

    &.groups {
      display: flex;
      flex-direction: row;
      margin-bottom: 10vh;

      .reel-view--grid__reel-title {
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        margin-right: 2%;
        height: 35vh;
        max-width: 15%;
        position: relative;
        width: 100%;

        &:before {
          background: $black;
          content: '';
          height: 100%;
          left: 0;
          opacity: 0.7;
          position: absolute;
          top: 0;
          width: 100%;
        }

        p {
          font-size: 2.3vh;
          font-weight: $font-bold;
          position: relative;
          z-index: 10;
        }

        .reel-view--grid__reel-title--settings {
          align-items: center;
          background: $black;
          bottom: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          height: 5vh;
          position: absolute;
          opacity: 0.7;
          left: 0;
          width: 100%;

          img {
            cursor: pointer;
            width: 6.5%;
          }

          .spacer {
            width: 0.1vh;
            height: 3vh;
            display: inline-block;
            background: $light-gray-2;
          }
        }
      }

      .reel-view--grid-wrapper {
        max-width: 83%;
        width: 100%;

        .reel-view--grid-video-wrapper {
          margin-right: 1.25%;
          max-width: 19%;

          .reel-view--grid-video {
            margin-right: 0;
          }

          &:nth-child(5n) {
            // .reel-view--grid-video {
            margin-right: 0;
            // }
          }
        }
      }
    }

    p {
      margin-right: 2vh;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &--image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    height: 100%;
    width: 7.5vh;

    &.grid {
      background-image: url('../../../images/svg-images/icon-gridview.svg');
      margin-right: 2vh;

      &:hover,
      &.active {
        background-image: url($icon-gridview-hover);
      }
    }

    &.list {
      background-image: url('../../../images/svg-images/icon-listview.svg');

      &:hover,
      &.active {
        background-image: url($icon-listview-hover);
      }
    }
  }

  &--popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $popup-black;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;

    &-innerRow {
      width: 70%;
    }

    &-innerRow2 {
      align-items: center;
      border-left: 1px solid;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding-left: 20px;
      width: 30%;
    }

    &-title {
      font-size: 0.7vw;
      font-weight: $font-bold;
      margin-bottom: 0.5vh !important;
      width: 100%;
    }

    &-title2 {
      font-size: 0.7vw;
      font-weight: $font-bold;
      margin-bottom: 0 !important;
    }

    &-title3 {
      font-size: 0.7vw;
      font-weight: $font-bold;
      margin-bottom: 0 !important;
      width: 50%;
    }

    &-title4 {
      font-size: 1.5vw !important;
      font-weight: $font-bold;
      margin-bottom: 0 !important;
      width: 30%;
    }

    input {
      &[type='text'],
      &[type='email'],
      &[type='password'] {
        background: $dark-gray-2;
        border: none;
        color: $white;
        font-size: 16px;
        padding: 10px;
        width: 100%;

        @include grid-media($md) {
          font-size: 1vw;
          padding: 0.7vw 1vw;
        }

        &::-webkit-input-placeholder {
          /* Edge */
          color: $light-gray-2;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $light-gray-2;
        }

        &::placeholder {
          color: $light-gray-2;
        }
      }
    }

    &.addvideoassessment {
      .reel-view--popup-field {
        padding-top: 1vh;
      }

      .reel-view--popup-row {
        &:last-of-type {
          label {
            margin-right: 2vh;
            margin-top: 1vh;
          }
        }
      }
    }

    &-container {
      background: $dark-gray-3;
      max-width: 70%;
      width: 100%;

      @include grid-media($md) {
        max-width: 40%;
      }
    }

    &-header {
      border-bottom: 0.1vh solid $dark-gray-2;
      display: flex;
      justify-content: space-between;
      padding-top: 2vh;
      position: relative;

      h3 {
        font-weight: $font-bold;
        padding-left: 4vh;
        margin-top: 0;
        margin-bottom: 2vh;
      }

      &.noBorder {
        border-bottom: none;
        padding-bottom: 3vh;
        padding-top: 5vh;

        h3 {
          font-size: 3vh;
        }
      }

      .Dropdown-root {
        padding-left: 4vh;
        margin-bottom: 2vh !important;
        width: 35% !important;

        .Dropdown-control {
          border-radius: 3vw;
          padding-left: 2vh !important;

          .Dropdown-placeholder {
            color: $white !important;
            font-weight: $font-bold !important;
          }
        }
      }

      a {
        color: $white;
        padding-right: 4vh;
        top: 50%;
        transform: translateY(-50%);

        &:hover {
          color: $white;
        }
      }
    }

    &-body {
      padding: 4vh;

      &.noSidePadding {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;

        .all-users-table--table-header {
          .reel-view--table-cell {
            &:nth-of-type(1) {
              padding-left: 4vh;
            }

            &:nth-of-type(2) {
              max-width: 15%;
            }

            &:nth-of-type(3) {
              padding-right: 4vh;
              max-width: 28%;
            }

            h5 {
              font-size: 2vh;
              font-weight: $font-bold;
            }
          }
        }

        .all-users-table--table-body {
          max-height: 27vh;
          overflow-y: auto;

          .reel-view--table-row {
            padding: 2vh 4vh;

            p {
              color: $white;
              font-size: 2vh;
            }
          }
        }

        .reel-view--table-cell {
          &:nth-of-type(1) {
            max-width: 25%;
          }
          &:nth-of-type(2) {
            max-width: 20%;
          }
          &:nth-of-type(3) {
            max-width: 25%;
          }
        }

        .btn-stats-download {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    &-row {
      margin-bottom: 3vh;

      &.body--row1 {
        background: $dark-gray-1;
        display: flex;
        padding: 3vh 4vh;
        margin-bottom: 0;
      }

      &.body--row2 {
      }

      &:last-of-type {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;

        button {
          &:nth-of-type(2) {
            margin-left: 2vh;
          }
        }
      }

      label {
        display: inline-block;
        font-size: 16px;
        margin-bottom: 2vh;

        @include grid-media($md) {
          font-size: 18px;
        }
      }

      .Dropdown-root {
        .Dropdown-control {
          padding-left: 2vh !important;

          .Dropdown-placeholder {
          }
        }
      }

      button {
        &.push-left {
          margin-right: 3vh;
        }

        &:last-of-type {
          // min-width: 12vw;
        }
      }

      &.warning {
        h4 {
          span {
            color: $red;
          }
        }
      }

      &-innerrow {
        border-bottom: 0.1vw solid $dark-gray-2;
        border-top: 0.1vw solid $dark-gray-2;
        display: flex;
        justify-content: space-between;

        &:last-of-type {
          border-top: 0;
        }

        &--wrapper {
          max-height: 30vh;
          overflow-y: auto;
          padding-right: 10px;
        }
      }
    }

    &.useraccesscontrol {
      .reel-view--popup-container {
      }

      .reel-view--popup-header {
        flex-direction: column;

        h3 {
          margin-bottom: 0;
        }

        h4 {
          padding-left: 4vh;
        }
      }

      .reel-view--popup-body {
        padding: 0;
      }

      .reel-view--popup-row {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;

        &:last-of-type {
          justify-content: flex-end;
          margin-bottom: 2vh;

          button {
            margin-right: 4vh;
          }
        }

        &.top {
          background: $orange;

          .reel-view--popup-cell-left {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              width: 0.1vw;
              height: 70%;
              background: $dark-gray-3;
            }
          }

          .reel-view--popup-cell-right {
            text-align: center;
          }
        }

        &.data {
          flex-direction: column;
          max-height: 400px;
          margin-bottom: 2vh;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 1vh;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          }

          &::-webkit-scrollbar-thumb {
            background-color: $orange;
            outline: 1px solid $blue;
          }

          .reel-view--popup-row {
            width: 100%;
          }
        }

        .reel-view--popup-cell-left {
          max-width: 70%;
          padding-left: 4vh;
          width: 100%;
        }

        .reel-view--popup-cell-right {
          max-width: 30%;
          padding-right: 4vh;
          width: 100%;
        }
      }

      .header__switch {
        justify-content: space-evenly;
        max-width: 100%;

        .switch {
          height: 3.5vh;
          margin-bottom: 0;
        }

        .slider {
          background: $dark-gray-2;

          &:before {
            background-color: $dark-gray-1;
          }
        }

        input:checked + .slider {
          &:before {
            background-color: $orange;
          }
        }
      }
    }

    &.message {
      text-align: center;

      .reel-view--popup-container {
        max-width: 80%;

        @include grid-media($md) {
          max-width: 30%;
        }
      }

      .reel-view--popup-header {
        border-bottom: 0;
      }

      .reel-view--popup-row {
        justify-content: center;

        ul {
          margin-top: 0;
          padding: 0;

          li {
            // color: $red;
            font-weight: $font-bold;
            margin-bottom: 1vh;
            position: relative;
          }
        }
      }

      h3 {
        padding: 0;
        width: 100%;
      }
    }

    &.selectBannerType {
      h3 {
        margin: 0;
        margin-bottom: 1vh;
      }

      .reel-view--popup-container {
        background: none;
        max-width: 25%;
      }

      .reel-view--popup-header {
        border: 0;
        padding: 0;
      }

      .reel-view--popup-body {
        background: $dark-gray-3;

        .reel-view--popup-row {
          flex-wrap: wrap;
          justify-content: center;

          .reel-view--popup-field {
            align-items: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 15vh;
            max-width: 50%;
            width: 100%;

            &:nth-of-type(1),
            &:nth-of-type(2) {
              margin-bottom: 5vh;
            }

            p {
              margin-bottom: 0;
            }
          }
        }
      }

      .reel-view--popup-header a {
        right: -5vh;
      }
    }

    &.addreel {
      .reel-view--popup-row {
        // margin-bottom: 0;

        .reel-view--popup-field {
          padding-top: 1vh;
        }

        &:first-of-type {
          .reel-view--popup-field {
            &::-webkit-scrollbar {
              width: 1vh;
            }

            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }

            &::-webkit-scrollbar-thumb {
              background-color: $orange;
            }

            ul {
              margin-bottom: 5vh;

              li {
                input[type='checkbox'] {
                  + label {
                    padding-left: 1.5vw;

                    &:before,
                    &:after {
                      height: 0.8vw;
                      top: 0;
                      width: 0.8vw;
                    }
                  }
                }
              }
            }
          }
        }

        &:last-of-type {
          label {
            margin-bottom: 0;
            margin-right: 3vh;
            margin-top: 0.6vh;
          }
        }
      }
    }

    &.videoInfo {
      z-index: 20;

      .reel-view--popup-row {
        margin-bottom: 0;

        &:last-of-type {
          display: inline-block;
        }
      }
    }
  }

  &.single-reel {
    .reel-view--page-title {
      flex-wrap: wrap;
    }

    .breadcrumbs {
      color: $gray;
      cursor: pointer;
      width: 100%;

      a {
        color: $white;
        text-decoration: underline;
      }
    }

    .reel-view--page-selection {
      max-width: 50%;

      h2 {
        max-width: 100%;
      }
    }

    .reel-view--page-settings {
      max-width: 30%;

      .Dropdown-control {
        margin-right: 0 !important;
      }
    }

    .btn--downloadcsv {
      margin-right: 2vh;
      width: 100%;
    }

    .reel-view--image {
      width: 6vh;
    }

    .all-users-table--table-cell {
      text-align: left;

      &:nth-of-type(1) {
        // text-align: center;
        max-width: 20%;
        width: 100%;
      }
      &:nth-of-type(2) {
        max-width: 20%;
        width: 100%;
      }
      &:nth-of-type(3) {
        max-width: 10%;
        width: 100%;
      }
      &:nth-of-type(4) {
        max-width: 10%;
        width: 100%;
      }
      &:nth-of-type(5) {
        max-width: 10%;
        width: 100%;
      }
      &:nth-of-type(6) {
        max-width: 10%;
        width: 100%;
      }
    }
  }
}

.bulkreel-page {
  .featured-video__item {
    margin-bottom: 5vh;

    .miniPlayer {
      padding-top: 0;
    }
  }
}
