.create-user {
  .upload-btn-wrapper {
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .btn--upload {
    align-items: center;
    background-color: $dark-gray-2;
    background-image: url('../../../images/svg-images/icon-photo.svg');
    background-position: center 5vh;
    background-repeat: no-repeat;
    background-size: 30%;
    border-radius: 3vw;
    color: $white;
    display: flex;
    flex-direction: column;
    font-size: 0.8vw;
    justify-content: flex-end;
    height: 20vh;
    padding: 0 1vw;
    padding-bottom: 3vh;
    width: 100%;
  }

  .btn--upload-video {
    align-items: baseline;
    background-color: $dark-gray-2;
    background-image: url('../../../images/svg-images/icon-upload.svg');
    background-position: right 2vh center;
    background-repeat: no-repeat;
    background-size: 2.5%;
    color: $white;
    display: flex;
    flex-direction: column;
    font-size: 0.8vw;
    justify-content: center;
    height: 5vh;
    width: 100%;
  }

  .upload-btn-wrapper input[type='file'] {
    cursor: pointer;
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .Dropdown-root {
    margin-bottom: 0 !important;
  }

  h2 {
    font-size: 22px;
    font-weight: $font-bold;
    margin: 0;
    margin-bottom: 5vh;

    @include grid-media($md) {
      font-size: 2vw;
    }
  }

  h4 {
    font-size: 1.1vw;
    font-weight: $font-bold;
    width: 100%;
  }

  &--wrapper {
    padding-top: 90px;

    @include grid-media($md) {
      padding-top: 0;
    }
  }

  .breadcrumbs {
    color: $gray;
    cursor: pointer;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  &--page {
    &-title {
    }
    &-content {
    }
    &-column {
      display: flex;
      flex-direction: column;
    }

    &-column.center-column {
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;

      .create-user--field {
        width: 100%;

        input[type='text'],
        .upload-btn-wrapper button {
          padding-left: 1.5vh;
          color: $light-gray-2;
        }

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          max-width: 50%;
          width: 100%;
        }

        &.selectvideo {
          position: absolute;
          top: 7vh;
        }
      }
    }

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      &--inner {
        display: flex;
        flex-direction: row;
        width: 100%;

        .create-user--page-column {
          margin-right: 2.5vh;

          &:nth-of-type(4) {
            margin-right: 0;
          }
        }

        &.table {
          background-color: $dark-gray-2;
          padding: 1.5%;
        }
      }

      &.credentials {
        flex-wrap: wrap;

        @include grid-media($md) {
          flex-wrap: unset;
        }

        .create-user--page-column {
          &:nth-of-type(1) {
            max-width: 40%;
            width: 100%;

            @include grid-media($md) {
              max-width: 10%;
            }
          }
          &:nth-of-type(2) {
            max-width: 50%;
            width: 100%;

            @include grid-media($md) {
              max-width: 20%;
            }
          }
          &:nth-of-type(3) {
            max-width: 100%;
            width: 100%;

            @include grid-media($md) {
              max-width: 40%;
            }
          }
          &:nth-of-type(4) {
            max-width: 20%;
            width: 100%;
          }
        }
      }

      &.groups {
        flex-wrap: wrap;

        h4 {
          font-size: 18px;
          max-width: 80%;
          width: 100%;
        }

        p.addanother {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          font-size: 14px;
          justify-content: center;
          max-width: 200px;
          padding-left: 20px;
          position: relative;
          width: 100%;

          @include grid-media($md) {
            font-size: 1.1vw;
            padding-left: 2vw;
          }

          &:before {
            align-items: center;
            background: $orange;
            border-radius: 5vw;
            content: '+';
            display: flex;
            font-size: 16px;
            justify-content: center;
            height: 15px;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 15px;

            @include grid-media($md) {
              font-size: 1.1vw;
              height: 1.5vw;
              width: 1.5vw;
            }
          }
        }

        .create-user--page-row--inner {
          flex-direction: row;
          flex-wrap: wrap;
        }

        .create-user--page-column {
          margin-bottom: 3vh;
          max-width: 100%;
          width: 100%;

          @include grid-media($md) {
            max-width: 24%;
          }
        }
      }

      &.activity {
        flex-wrap: wrap;
      }
    }
  }

  &--group {
    background: $dark-gray-2;
    padding: 3% 5%;
    width: 100%;

    h5 {
      font-weight: $font-bold;
      margin-bottom: 1vh;
      margin-top: 0;
    }

    span {
      color: $orange;
    }

    &.edituser {
      background-image: url('../../../images/svg-images/icon-edit.svg');
      background-position: right 2vh center;
      background-repeat: no-repeat;
      background-size: 2.5vh;
      cursor: pointer;
    }
  }

  &--download {
    &.edituser {
      button {
        margin-right: 2vh;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &--uploadphoto {
    img {
      border-radius: 3vw;
    }
  }

  &--field {
    margin-bottom: 3vh;

    label {
      color: $white;
      display: inline-block;
      font-size: 16px;
      font-weight: $font-bold;
      margin-bottom: 1vh;
      width: 100%;

      @include grid-media($md) {
        font-size: 0.9vw;
      }
    }

    video {
      width: 100%;
      height: 30vh;
    }

    input {
      &[type='text'],
      &[type='email'],
      &[type='password'] {
        background: none;
        border: none;
        color: $light-gray-2;
        width: 100%;

        &::-webkit-input-placeholder {
          /* Edge */
          color: $light-gray-2;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $light-gray-2;
        }

        &::placeholder {
          color: $light-gray-2;
        }
      }

      &[type='text'] {
        &::-webkit-input-placeholder {
          /* Edge */
          color: $white;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $white;
        }

        &::placeholder {
          color: $white;
        }
      }
    }

    &.designation {
      input {
        &[type='text'] {
          background: none;
          border: none;
          color: $light-gray-2;
          padding-left: 0;

          &::-webkit-input-placeholder {
            /* Edge */
            color: $light-gray-2;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $light-gray-2;
          }

          &::placeholder {
            color: $light-gray-2;
          }
        }
      }
    }

    &.username {
      input {
        &[type='text'] {
          background: none;
          border: none;
          color: $white;
          font-size: 16px;
          font-weight: $font-bold;
          padding-left: 0;
          padding-top: 0;

          @include grid-media($md) {
            font-size: 1.7vw;
          }

          &::-webkit-input-placeholder {
            /* Edge */
            color: $light-gray-2;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $light-gray-2;
          }

          &::placeholder {
            color: $light-gray-2;
          }
        }
      }
    }

    .url {
      &[type='text'] {
        background-image: url('../../../images/svg-images/icon-upload.svg');
        background-position: right 2vh center;
        background-repeat: no-repeat;
        background-size: 2.5%;
      }
    }

    &.password {
      span {
        color: $light-gray-2;
        cursor: pointer;
        display: block;
        padding-left: 2vh;
        position: relative;
      }

      .create-user--image {
        left: 0;
        position: absolute;
        top: 0;
        width: 1.3vh;
      }
    }
  }

  &--table {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    &-header,
    &-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    &-header {
      .create-user--table-cell {
        background-image: url('../../../images/svg-images/icon-decending.svg');
        background-position: right 0.3vh;
        background-repeat: no-repeat;
        background-size: 2.3vh;

        &:nth-of-type(2) {
          background-image: none;
        }
      }
    }

    &-body {
      flex-wrap: wrap;
    }

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    &-cell {
      &:nth-of-type(1) {
        max-width: 20%;
        width: 100%;
      }
      &:nth-of-type(2) {
        max-width: 20%;
        width: 100%;
      }
      &:nth-of-type(3) {
        max-width: 10%;
        width: 100%;
      }
      &:nth-of-type(4) {
        max-width: 10%;
        width: 100%;
      }
      &:nth-of-type(5) {
        max-width: 10%;
        width: 100%;
      }

      h5 {
        border-bottom: 0.1vw solid $orange;
        font-size: 0.9vw;
        font-weight: $font-bold;
        padding-bottom: 1vh;
        margin-top: 0;
      }

      p {
        margin-top: 0;
      }
    }
  }

  &.update-user {
    .create-user--page-row.credentials {
      .create-user--field {
        &.username {
          margin-top: -2vh;
          padding-top: 0;
        }
      }
    }
  }

  &.view-user {
    width: 100%;

    .credentials {
      .create-user--page-column {
        &:nth-of-type(2) {
          max-width: 43%;
        }
      }
    }
  }

  &--text-area {
    resize: none;
    color: $light-gray-2;
    background-color: unset;
    border: none;
    font-family: $font-family;
  }
}
