// Theme Change
// This mixin is used to change the theme color globally according to the class from the API
@mixin theme_change($theme-color, $theme-color-secondary) {
  //scroll bars
  body::-webkit-scrollbar-thumb,
  div::-webkit-scrollbar-thumb {
    background-color: $theme-color !important;
  }

  //admin screen
  .group-users-table--option:hover h3 {
    color: $theme-color !important;
  }

  // allusers.scss
  .all-users-table--grid-header p {
    color: $theme-color !important;
  }

  // addDocument.scss
  .admin__adddocument {
    .addanother::before {
      background: $theme-color !important;
    }

    &--reels,
    &--assessments,
    &--documents {
      h3 {
        color: $theme-color !important;
      }
    }
  }

  // createAssessment.scss
  .admin__createassessment {
    .addanother {
      &:before {
        background: $theme-color !important;
      }
    }

    &--placement,
    &--assessments,
    &--documents,
    &--reels {
      h3 {
        color: $theme-color !important;
      }
    }

    &--question {
      .form-input {
        &--checkbox {
          &.hidelabel {
            &:checked + label:after {
              background-color: $theme-color !important;
            }
          }
        }
      }

      .orientation-radiobutton {
        &.selected {
          &:before {
            background-color: $theme-color !important;
          }
        }
      }
    }
  }

  // createUser.scss
  .create-user {
    &--page {
      &-row {
        &.groups {
          p.addanother {
            &:before {
              background: $theme-color !important;
            }
          }
        }
      }
    }

    &--group {
      span {
        color: $theme-color !important;
      }
    }

    &--table {
      &-cell {
        h5 {
          border-bottom-color: $theme-color !important;
        }
      }
    }
  }

  // createVideo.scss
  .admin__createvideo {
    .addanother::before {
      background: $theme-color !important;
    }

    &--reels,
    &--assessments,
    &--documents {
      h3 {
        color: $theme-color !important;
      }
    }
  }

  // groupUsers.scss
  .group-users {
    &-table {
      &--option {
        &.active {
          &:before {
            background: $theme-color !important;
          }

          h3 {
            color: $theme-color !important;
          }

          &:hover {
            h3 {
              color: $theme-color !important;
            }
          }
        }
      }

      &--page {
        &--grid {
          p {
            color: $theme-color !important;
          }

          &-card {
            .Dropdown-control {
              background: $theme-color !important;
            }
          }
        }
      }

      &--grid {
        &-header {
          p {
            color: $theme-color !important;
          }
        }

        &-card {
          .Dropdown-control {
            background: $theme-color !important;
          }
        }
      }
    }
  }

  // nodeTree.scss
  .nodeTree {
    &--chart {
      svg {
        rect {
          fill: $theme-color !important;
          stroke: $theme-color !important;
        }

        .nodeBase:first-of-type rect {
          fill: $white !important;
          stroke: $white !important;
        }
      }
    }
  }

  // notifications.scss
  .notification {
    &-screen {
      &__nav {
        ul {
          li {
            &.active {
              border-right-color: $theme-color !important;
              color: $theme-color !important;
            }

            ul {
              li {
                &.active {
                  color: $theme-color !important;
                }
              }
            }
          }
        }
      }

      &__settings {
        &--option {
          .header__switch {
            input {
              &:checked + .slider {
                &:before {
                  background: $theme-color !important;
                }
              }
            }
          }
        }
      }
    }

    &-list {
      &__user {
        &--selection {
          a {
            &.active {
              color: $theme-color !important;

              &:after {
                background: $theme-color !important;
              }
            }
          }
        }
      }

      &__content {
        &--leftcol {
          p {
            color: $theme-color !important;
          }
        }
      }
    }
  }

  // reelView.scss
  .reel-view {
    &--option {
      &.active {
        &:before {
          background: $theme-color !important;
        }
      }
    }

    &--page {
      &-reelselection {
        p {
          &.active {
            color: $theme-color !important;
            border-bottom: 0.05vw solid $theme-color !important;
          }
        }
      }

      &-data {
        &-cell {
          background-color: $theme-color !important;
        }
      }
    }

    &--popup {
      &-header {
        &.noBorder {
          h3 {
            color: $theme-color !important;
          }
        }
      }

      &-title2 {
        color: $theme-color !important;
      }

      &-innerRow2 {
        border-left-color: 1px solid $theme-color;
      }

      &-body {
        &.noSidePadding {
          .all-users-table--table-header {
            border-bottom-color: $theme-color !important;
          }
        }
      }

      &.useraccesscontrol {
        .reel-view--popup-row {
          &.top {
            background: $theme-color !important;
          }

          &.data {
            &::-webkit-scrollbar-thumb {
              background-color: $theme-color !important;
            }
          }
        }

        .header__switch {
          input:checked + .slider {
            &:before {
              background-color: $theme-color !important;
            }
          }
        }
      }

      &.addreel {
        .reel-view--popup-row {
          &:first-of-type {
            .reel-view--popup-field {
              &::-webkit-scrollbar-thumb {
                background-color: $theme-color !important;
              }
            }
          }
        }
      }
    }
  }

  // trainingRequest.scss
  .feedback {
    &--reel-label {
      background: $theme-color !important;
    }
  }

  .createtraining {
    &-popup {
      &__row {
        &.last {
          label {
            color: $theme-color !important;
          }
        }
      }
    }
  }

  // trainingSummary.scss
  .training-summary {
    &--feedback {
      &.selected,
      &:hover {
        background: $theme-color !important;
      }
    }
  }

  .progress-bar {
    background-color: $theme-color !important;
  }

  // assessment-popup.scss
  .theatre__assessment {
    &-pagination {
      > li {
        &.active {
          background: $theme-color !important;
        }
      }
    }
  }

  // assessment-results.scss
  .assessment {
    &__result {
      &--title {
        h2 {
          span {
            color: $theme-color !important;
          }
        }
      }

      &--count {
        background-color: $theme-color !important;
      }
    }

    &__endvideo {
      &--heading {
        h3 {
          color: $theme-color !important;
        }
      }

      &--duration,
      &--questions-count {
        span {
          color: $theme-color !important;
        }
      }
    }
  }

  // assessments.scss
  .assessment {
    &__question {
      &:before {
        background-color: $theme-color !important;
      }
    }

    &__start {
      &--related-content {
        h4 {
          color: $theme-color !important;
        }
      }
    }

    .assessment__start--duration span,
    .assessment__start--questions-count span {
      color: $theme-color !important;
    }
  }

  .assessment-page {
    .header__nav-item.user .user_avatar .userdropdown--settings ul li {
      &:hover {
        a {
          color: $theme-color !important;
        }
      }
    }
  }

  // buttons.scss
  .btn {
    &--primary {
      background: $theme-color !important;
    }

    &--loader {
      background: none !important;
      border: 3px solid $theme-color !important;
      border-bottom-color: $white !important;
    }
  }

  // forms.scss
  .form {
    &__form--fields {
      a {
        color: $theme-color-secondary;
      }
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    & + label {
      &:before,
      &:after {
        border-color: $theme-color !important;
      }
    }
  }

  // header.scss
  .header {
    &__switch {
      input:checked + .slider {
        // background-color: $theme-color !important;
      }
    }

    .notification {
      &__count {
        background: $theme-color !important;
      }

      &__dropdown {
        .dropdown {
          &__header {
            .user-selection {
              a {
                &.active,
                &:hover {
                  background-color: $theme-color !important;
                }
              }
            }
          }

          .view-all {
            color: $theme-color !important;
          }
        }
      }
    }

    &__nav-item {
      @include grid-media($md) {
        &.user {
          .user_avatar {
            border: 2px solid $theme-color !important;

            .userdropdown {
              &--settings {
                ul {
                  li {
                    a {
                      &:hover {
                        color: $theme-color-secondary;
                      }

                      &.active {
                        color: $theme-color-secondary;
                      }
                    }
                  }
                }
              }

              &--logout {
                a {
                  &:hover {
                    color: $theme-color !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // Login Screen
  .loginscreen {
    &:before {
      background: $theme-color;
      background: linear-gradient(145deg, $theme-color 0%, $black 100%);
    }
    &__form {
      .form--buttons {
        .btn {
          border-radius: 1vh;

          &:first-of-type {
            background-color: $theme-color-secondary !important;
            color: $black;
          }

          &.btn--loader {
            background-color: transparent !important;
            border-radius: 100%;
            border-color: $theme-color !important;
            border-bottom-color: $theme-color-secondary !important;
            position: relative;
            top: -0.2vw;
          }
        }
      }
    }
  }

  // homeSectionOne.scss
  .home-screen {
    .admin-reel-add--icons {
      p {
        &:before {
          background: $theme-color !important;
        }
      }
    }
  }

  // lightmode.scss
  &.light-mode {
    .theatre__controllers_play div svg {
      stroke: $theme-color !important;
    }

    .theatre__info__message_item {
      background: $theme-color !important;
    }

    .assessment__questions--list {
      h5 {
        color: $theme-color !important;
      }
    }
  }

  // miniPlayer.scss
  .miniPlayer {
    &__info {
      &--play-icon {
        svg {
          fill: $theme-color !important;
        }
      }
    }

    &__info-arrow {
      svg {
        &:hover {
          fill: $theme-color !important;
        }
      }
    }
  }

  .plyr__control--overlaid,
  .plyr--video .plyr__control.plyr__tab-focus,
  .plyr--video .plyr__control:hover,
  .plyr--video .plyr__control[aria-expanded='true'] {
    background: $theme-color !important;
  }

  .fullScreen {
    .plyr__progress {
      input::-webkit-slider-thumb {
        background: $theme-color !important;
      }
    }
  }

  .fullScreen,
  .smallScreen {
    .plyr--full-ui input[type='range'] {
      color: $theme-color !important;
    }
  }

  // navDropdown.scss
  .dropdown-content a:hover {
    // background-color: $theme-color !important;
  }

  // statspopup.scss
  .statspopup {
    &__header {
      &--content {
        &-title {
          h4 {
            color: $theme-color !important;
          }
        }
      }
    }
  }

  // theatre.scss
  .theatre {
    &__controllers {
      &_play {
        div {
          &:hover {
            svg {
              stroke: $theme-color !important;
            }
          }
        }
      }

      &_volume {
        .slidecontainer {
          .slider::-webkit-slider-thumb {
            background: $theme-color !important;
          }
        }
      }

      &_next-hover {
        background: $theme-color !important;

        &:before {
          border-top: 1.5vw solid $theme-color !important;
        }
      }
    }

    &__info {
      &_tab-title {
        &.active {
          background: $theme-color !important;
        }
      }
    }
  }

  .MuiGrid-root.MuiGrid-container {
    .MuiSlider-root.MuiSlider-colorPrimary {
      color: $theme-color !important;
    }
  }

  .theatre-video-slider-track {
    height: 2px;
    width: 100%;
    margin-bottom: 10px;
    background-color: $theme-color !important;
  }

  .theatre-video-slider {
    height: 20px;
    width: 20px;
    border-radius: 20px;
    background-color: $theme-color !important;
    outline: none;
  }

  // tilegrid.scss
  .tilegrid {
    &__content {
      &--image {
        &:after {
          background: $theme-color !important;
        }
      }
    }

    &__subtitle {
      color: $theme-color !important;

      @include grid-media($md) {
        color: $white !important;
      }
    }

    &.reel {
      .tilegrid__row--bottom {
        h4 {
          color: $theme-color !important;
        }
      }
    }
  }

  // overrides.scss
  &::-webkit-scrollbar-thumb {
    background-color: $theme-color !important;
  }

  // utility.scss
  .addanother {
    &:before {
      background: $theme-color !important;
    }
  }

  // animation.scss
  .loading-screen {
    .lds-facebook div:nth-child(2) {
      background: $theme-color !important;
    }
  }

  .base-timer__path-remaining.green {
    color: $theme-color !important;
  }

  .base-timer__path-remaining.red {
    color: $theme-color-secondary;
  }

  .loading-dotted-circle div {
    background: $theme-color !important;
  }

  // custom-select.scss
  .Dropdown-root {
    .Dropdown-menu {
      &::-webkit-scrollbar-thumb {
        background-color: $theme-color !important;
      }
    }

    .Dropdown-option.is-selected,
    .Dropdown-option:hover {
      background-color: $theme-color !important;
    }
  }

  .multi-level-selector-container {
    .multi-level-options-container {
      &.menu-open {
        .options-container {
          .options-sub-menu-container {
            .options-sub-menu {
              input:checked ~ .checkbox .checkmark {
                background: $theme-color !important;
              }
            }
          }
        }
      }
    }
  }

  // scrollbar.scss
  .scrollbar-thumb,
  .ScrollbarsCustom-Thumb {
    background: $theme-color !important;
  }

  // Calendar
  .dateTimeReminder
    .picky-date-time-calendar__body-container
    .picky-date-time-calendar__date-item.xs.active {
    background: $theme-color !important;
  }

  .dateTimeReminder
    .picky-date-time-calendar__body-container
    .picky-date-time-calendar__date-item.xs:hover {
    background: $theme-color !important;
  }

  .picky-date-time-calendar__table-cel.today {
    color: $theme-color-secondary !important;
    fill: $theme-color-secondary !important;
  }

  .dateTimeReminder .picky-date-time-calendar__today:hover {
    background: $theme-color !important;
  }

  .btn-read-more {
    color: $theme-color !important;
  }

  .btn-stats-download {
    color: $theme-color !important;
  }

  // Pagination
  ul.react-paginate {
    li {
      &.active {
        background-color: $theme-color !important;
      }

      &:hover {
        background-color: $theme-color-secondary !important;
      }
    }
  }
}
