/**
* Assessment Popup
*/

.theatre__assessment {
  @include padding(5% 2.5%);

  background: rgba($dark-gray-1, 0.98);
  max-height: 55vh;
  padding-top: 40px;
  width: 100%;

  @include grid-media($md) {
    @include padding(5% 2.5%);
    height: 100vh;
  }

  &-wrapper {
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    // padding-left: 0.5%;
    position: absolute;
    left: 0;
    width: 100%;
  }

  &-pagination {
    position: absolute;
    right: -5px;

    @include padding(8px 2.5% null null);

    @include margin(null null null auto);
    top: 2px;

    @include grid-media($md) {
      right: 0.5vh;
      top: -6vh;
    }

    > li {
      @include margin(null 4px null null);

      &:last-child {
        @include margin(null 0 null null);
      }

      background: $white;
      float: left;
      height: 3px;
      width: 25px;

      &.active {
        background: $orange;
      }
    }
  }

  &-question {
    // max-height: 40vh;

    form {
      max-width: 75%;
    }

    .form-item {
      @include margin(null null 20px);

      label {
        font-size: 16px;

        @include grid-media($md) {
          font-size: 1.2vw;
        }
      }

      input[type='radio']:checked + label:before,
      input[type='radio']:checked + label:after,
      input[type='radio']:not(:checked) + label:before,
      input[type='radio']:not(:checked) + label:after {
        // height: 1vw;
        // top: 0;
        // width: 1vw;
        top: -0.1vw;
      }

      // input[type=radio]:checked + label:after,
      // input[type=radio]:not(:checked) + label:after {
      //   top: 0.1vw;
      // }
    }

    &--answer {
      &-youranswer {
        font-size: 14px;
        margin-bottom: 10vh;
        padding-left: 3.5vw;
        position: relative;

        &:before,
        &:after {
          border: 2px solid $white;
          border-radius: 50%;
          content: '';
          height: 15px;
          left: -10px;
          position: absolute;
          width: 15px;
          top: 0;

          @include grid-media($md) {
            border: 1px solid $white;
            height: 1.5vw;
            left: 0;
            top: 3px;
            width: 1.5vw;
          }
        }

        &:before {
        }

        &:after {
          background-color: $white;
          background-image: url('../../../images/red-cross.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          // left: 0.15vw;
          // top: -0.1vw;
          // transform: scale(.9);
          transition: all 0.4s $cubic-transition;

          @include grid-media($md) {
            background-size: 1.6vw;
          }
        }

        span {
          display: inline-block;
          font-size: 1.8vh;
          position: absolute;
          top: -3vh;
          width: 100%;
        }
      }

      &-correct {
        font-size: 14px;
        padding-left: 3.5vw;
        position: relative;

        &:before,
        &:after {
          border: 2px solid $white;
          border-radius: 50%;
          content: '';
          height: 15px;
          left: -10px;
          position: absolute;
          width: 15px;
          top: 0;

          @include grid-media($md) {
            border: 1px solid $white;
            height: 1.5vw;
            left: 0;
            top: 3px;
            width: 1.5vw;
          }
        }

        &:before {
        }

        &:after {
          background-color: $white;
          background-image: url('../../../images/green-tick.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          // left: 0.15vw;
          // top: -0.1vw;
          // transform: scale(.9);
          transition: all 0.4s $cubic-transition;

          @include grid-media($md) {
            background-size: 1.6vw;
          }
        }

        span {
          color: $green;
          display: inline-block;
          font-size: 1.8vh;
          position: absolute;
          top: -3vh;
          width: 100%;
        }
      }
    }
  }

  &-col {
    flex: 0 0 100%;
    max-width: 100%;

    @include grid-media($md) {
      flex: 0 0 45%;
      max-width: 45%;
    }

    &-wrapper {
      display: flex;
      flex-direction: column-reverse;
      height: 30vh;
      overflow: scroll;

      &.answers-screen {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        overflow: unset;

        .theatre__assessment-col {
          flex: 0 0 45%;
          max-width: 45%;
        }
      }

      @include grid-media($md) {
        flex-wrap: wrap;
        justify-content: space-between;
        padding-left: 6vh;
      }

      &.oriented {
        h4 {
          font-size: 16px;
          margin-bottom: 2vh;
          margin-top: 0;
          max-width: 100%;
          width: 100%;

          @include grid-media($md) {
            font-size: 1.5vw;
          }
        }

        h3 {
          margin-bottom: 5vh;
          margin-top: 0;
        }

        .theatre__assessment-question--image {
          img {
            height: 100vh;
            margin-top: 50px;
            max-height: 40vh;

            @include grid-media($md) {
              margin-top: 0;
            }
          }
        }

        &.orientation-1 {
        }

        &.orientation-2 {
          flex-direction: row-reverse;
        }

        &.orientation-3 {
        }
      }
    }

    h5 {
    }
  }

  .form-actions {
    // @include margin(1vh null null);

    justify-content: flex-end;
    width: 100%;

    img {
      cursor: pointer;
      height: 2.6vw;
      margin-right: 1vw;
      width: 2.6vw;
    }

    .btn {
      // height: 2.6vw;
    }
  }
}
