// --------------------------------------------------------------
// Video styles
// --------------------------------------------------------------

.plyr--video {
    background: #000;
    overflow: hidden;

    // Menu open
    &.plyr--menu-open {
        overflow: visible;
    }
}

.plyr__video-wrapper {
    background: #000;
    border-radius: inherit;
    overflow: hidden;
    position: relative;
    // Require z-index to force border-radius
    z-index: 0;
}

// Default to 16:9 ratio but this is set by JavaScript based on config
$embed-padding: ((100 / 16) * 9);

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
    height: 0;
    padding-bottom: to-percentage($embed-padding);
}

.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    user-select: none;
    width: 100%;
}

// If the full custom UI is supported
.plyr--full-ui .plyr__video-embed {
    $height: 240;
    $offset: to-percentage(($height - $embed-padding) / ($height / 50));

    // Only used for Vimeo
    > .plyr__video-embed__container {
        padding-bottom: to-percentage($height);
        position: relative;
        transform: translateY(-$offset);
    }
}
