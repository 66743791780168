/*!
Theme Name: TRAINING-Koach
Author: Koach
Author URI: https://koachhub.com/
Description: Custom theme developed for Training client
*/

@import 'icons/training-icons';
@import 'common-style.scss';

.theme--training {
  .loginscreen {
    &:before {
      background: $green-training;
    }
    h2,
    h4 {
      display: none;
    }
    &__form {
      .icon--logo {
        width: 30%;
      }
      .form__form--fields {
        margin-top: 10vh;

        .form-input {
          background-color: $white !important;
          color: $black !important;
        }
      }
      .form--buttons {
        .btn {
          &:first-of-type {
            background-color: $light-green-training !important;
          }
          &:last-of-type {
            color: $white !important;
          }
          &.btn--loader {
            border-radius: 100% !important;
          }
        }
      }
    }
  }
  .header__logo {
    max-width: 4% !important;
  }
  .footer__logo img {
    height: 7vh;
  }
}
