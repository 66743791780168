/**
* Utility classes
*/

.pt-none {
  padding-top: 0;
}

.pb-none {
  padding-bottom: 0;
}

.ptpb-none {
  @include padding(0);
}

.tal {
  text-align: left;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.addanother {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  max-width: 25%;
  padding-left: 20px;
  position: relative;
  width: 100%;

  @include grid-media($md) {
    font-size: 1.1vw;
    padding-left: 2vw;
  }

  &:before {
    align-items: center;
    background: $orange;
    border-radius: 5vw;
    content: '+';
    display: flex;
    font-size: 16px;
    justify-content: center;
    height: 15px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;

    @include grid-media($md) {
      font-size: 1.1vw;
      height: 1.5vw;
      width: 1.5vw;
    }
  }

  &.homepageadmin {
    display: none;
    margin-bottom: 5vh;
    margin-left: 3vw;
    margin-top: -2vh;
  }
}

.unpublished {
  position: relative;

  .miniPlayer {
    &:before {
      align-items: center;
      background: #000000e0;
      content: 'Unpublished';
      font-size: 1vw;
      font-weight: $font-medium;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }

  .miniPlayer__info-action {
    .miniPlayer__info,
    .miniPlayer__action,
    .miniPlayer__info-arrow {
      opacity: 0 !important;
    }
  }
}

.player_section {
  .unpublished {
    position: relative;

    &:before {
      align-items: center;
      background: #000000e0;
      content: 'Unpublished';
      font-size: 1vw;
      font-weight: $font-medium;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }
}

.mandatory-field {
  color: $red !important;
}
