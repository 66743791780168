body.admin {
  .admin {
    &-nav,
    &-player,
    &-reel_video,
    &-reel-title,
    &-reel-position,
    &-reel-upload,
    &-reel-add,
    &-advanced-setting,
    &-theater,
    &-player_II {
      &--icons {
        display: flex !important;

        img {
          cursor: pointer;
        }
      }
    }

    &-policy--icon {
      display: flex !important;
    }

    &-reel-add-icons {
      .admin-reel-position--icons {
        display: flex !important;
      }
    }
  }

  .hompage--advance-settings {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 50px;
    width: 50%;

    @include grid-media($md) {
      margin-bottom: 0;
      margin-left: 3.2%;
      width: auto;
    }
  }

  .addanother.homepageadmin {
    display: flex !important;
  }

  .theater-page,
  .training-page {
    .header {
      height: 10vh !important;

      .admin-nav--icons {
        display: none !important;
      }
    }
  }

  .header {
  }

  .miniPlayer__action {
    display: none !important;
  }

  .middle-video-reel {
    .admin-player--icons {
      // left: -8vw;
      flex-direction: column;
      justify-content: space-between;
      height: 90px;
      left: 4%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, 0%);
      width: 20px;
      z-index: 10;

      @include grid-media($md) {
        height: 10vh;
        left: 15%;
        transform: translate(-50%, -50%);
        width: 1.6vw;
      }
    }

    .admin-player_II--icons {
      bottom: 20%;
      flex-direction: row;
      left: 48%;
      pointer-events: auto !important;
      position: absolute;
      // right: 0;
      transform: translate(-50%, -50%);
      width: 70px;
      z-index: 10;

      @include grid-media($md) {
        bottom: 20%;
        width: 10vw;
      }

      img {
        height: 2.5vh;
      }

      hr {
        background: $white;
        border: 0;
        margin-bottom: 0;
        margin-top: 0;
        width: 0.1vw;
      }
    }

    .slick-list {
      margin: 0 auto;
      width: 90%;

      @include grid-media($md) {
        width: 68%;
      }
    }
  }

  .featured-video__item--assessment {
    &:hover {
      .admin-reel_video--icons.assessment {
        display: flex !important;
      }
    }
  }

  .item-policy {
    margin-top: 12vh;
  }
}

.admin-player--icons,
.admin-player_II--icons {
  display: none;
}
