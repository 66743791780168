/**
* Home page  Player Section
*/

.banner-video {
  position: relative;

  &:first-of-type {
    &:after {
      background: linear-gradient(#00000000 25%, #000);
      bottom: 0;
      content: '';
      display: block;
      height: 30vh;
      position: absolute;
      width: 100%;
    }
  }

  .player_section__video {
    &-click {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }
}

.middle-video-reel {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 30vh;
  height: 100%;
  margin-left: -15px;
  padding-top: 70px;
  width: calc(100% + 30px);

  @include grid-media($sm) {
    height: 70vh;
  }

  @include grid-media($md) {
    background-position: 0% 40%;
    display: block;
    margin-bottom: 5vh;
    padding-top: 0;
    position: relative;
    max-height: 60vh;
    width: 100%;
    margin-left: 0;
  }

  &:before {
    background-color: rgba($black, 0.8);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .slick-slider {
    padding: 0 !important;
    position: relative;
    z-index: 1;

    .slick-list {
      margin: 0 auto;
      width: 85%;
      max-width: 500px;

      @include grid-media($md) {
        max-width: unset;
        width: 68%;
      }
    }
  }

  .player_section__video {
    &-click {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    div {
      &:nth-of-type(2) {
        div {
          height: 45vh !important;
        }
      }
    }
  }
}

.player_section {
  margin: auto;

  .container {
    display: flex;
    flex-direction: column-reverse;

    @include grid-media($md) {
      flex-direction: row;
      justify-content: center;
    }
  }

  .player_section__controls {
    margin-top: 2vh;
  }

  &.assessmentplayer {
    .player_section__controls {
      width: 15%;
    }
  }

  &--wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 12vh 0;
    width: 100%;

    @include grid-media($sm) {
      height: 50vh;
      padding-top: 1vh;
    }

    @include grid-media($md) {
      height: 70vh;
      padding-bottom: 4vh;
      padding-top: 8vh;
      flex-direction: row;
      max-height: 65vh;
    }
  }

  &__video {
    background-size: cover;
    height: 43vh;
    position: relative;
    margin-top: 20px;
    max-height: 200px;
    width: 100%;

    @include grid-media($md) {
      align-items: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
      margin-top: 0;
      max-height: unset;
      max-width: 55%;
      width: 100%;
    }

    .plyr {
      &__controls {
        display: none !important;
      }

      &__control {
        display: none !important;
      }
    }

    @include grid-media($md) {
      align-items: center;
      background-size: cover;
      display: flex;
      justify-content: center;
      overflow: hidden;
      max-width: 55%;
      height: 43vh;
      width: 100%;
    }
  }

  &__video-action {
    bottom: 100px;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;

    &--age {
      align-items: center;
      background-color: rgba(51, 51, 51, 0.6);
      border-left: solid 3px #dcdcdc;
      box-sizing: border-box;
      color: $white;
      display: flex;
      font-family: $font-family;
      font-size: 1.1vw;
      height: 2.4vw;
      padding: 0.5vw 3.5vw 0.5vw 0.8vw;
    }
  }

  &__info {
    color: $white;
    font-family: $font-family;
    line-height: 1.2;
    width: 100%;
    z-index: 1;

    @include grid-media($md) {
      @include padding(0 2vw 0 2vw);
      max-width: 45%;
      width: 100%;
    }
  }

  &__teacher {
    font-size: 1.36vw;
  }
  &__title {
    @include margin(1vw 0 0);

    font-size: 18px;
    font-weight: $font-bold;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;

    @include grid-media($md) {
      font-size: 2.6vw;
      font-weight: $font-medium;
    }

    &.mandatory {
      display: inline-block;
      position: relative;

      &:after {
        background-color: $red;
        border-radius: 2vw;
        bottom: 0.5vw;
        content: 'Mandatory!';
        font-size: 0.8vw;
        position: absolute;
        right: -6vw;
        padding: 0.6vh 1.5vh;
      }
    }

    &.watched {
      padding-left: 5vh;
      position: relative;

      &:before {
        background-image: url('../../../images/svg-images/icon-watched-check.svg');
        background-repeat: no-repeat;
        background-size: 3.5vh;
        background-position: left 1.5vh;
        content: '';
        left: 0;
        height: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }
    }
  }

  &__action {
    @include margin(1.875vw 0 1.25vw);

    display: flex;
    justify-content: space-between;

    .btn {
      background-color: rgba(51, 51, 51, 0.4);
      border-radius: 0.2vw;
      border-width: 0;
      box-shadow: none;
      color: $white;
      cursor: pointer;
      display: flex;
      font-size: 1.1vw;
      height: 1.2vw;
      margin-bottom: 0.75em;
      padding: 0.75em 1.5em;
      transition: all 0.4s $cubic-transition-2;

      &:hover {
        background-color: $white;
        color: $black;

        svg {
          fill: $black;
        }
      }

      svg {
        @include margin(0 10px 0);
        fill: $white;
        height: 20px;
        width: 20px;
      }
    }

    &--title {
      display: flex;
      align-items: center;
    }
  }

  &__action-icon {
    border-radius: 50%;
    border: 1px solid rgba($white, 0.5);
    display: flex;
    height: 1.5em;
    margin-top: 0.25em;
    padding: 6px;
    transition: border 0.4s $cubic-transition-2;
    width: 1.5em;

    &.likeBtn {
      @include margin(0 10px 0);

      cursor: pointer;
    }

    svg {
      fill: rgba($white, 0.5);
      height: 100%;
      transition: fill 0.4s $cubic-transition-2;
      width: 100%;
    }

    &:hover {
      border: 1px solid $white;

      svg {
        fill: $white;
      }
    }
  }

  &__message {
    font-size: 1.2vw;
    margin-top: 0.46875vw;
  }

  &__synopsis {
    font-size: 14px;
    margin-top: 0.46875vw;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;

    @include grid-media($md) {
      font-size: 0.9vw;
    }
  }

  &__duedate {
    align-items: center;
    display: flex;
    padding: 1.7vh 0;
    width: 65%;

    img {
      margin-right: 0.5vw;
      width: 1vw;
    }

    span {
      color: $white;
      font-size: 0.8vw;
    }
  }

  &__controls {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 60%;

    svg,
    img {
      cursor: pointer;
      fill: $white;
      height: 15px;
      width: 15px;

      @include grid-media($md) {
        height: 1.2vw;
        width: 1.2vw;
      }
    }

    div {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100%;
      margin-right: 2vh;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &--audio-off,
    &--audio-on {
      svg {
        height: 27px;
        width: 30px;

        @include grid-media($md) {
          height: 1.8vw;
          width: 2vw;
        }
      }
    }
  }

  &--wrapper {
    &.assessment,
    &.policy {
      width: 100%;

      .player_section__video {
        img {
          width: 100%;
        }
      }

      .player_section__readmore {
        margin-top: 2vh;
      }
    }

    .player_section__video {
      &.remove-loading-image {
        background-image: unset !important;
      }

      .admin-player--icons {
        display: none;
        flex-direction: column;
        left: -5vw;
        pointer-events: auto !important;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;

        img {
          &:first-of-type {
            margin-bottom: 2vw;
          }
        }
      }

      .admin-player_II--icons {
        display: none;
        flex-direction: row;
        right: 0;
        pointer-events: auto !important;
        position: absolute;
        bottom: -4vh;
        z-index: 10;

        img {
          height: 2.5vh;
        }

        hr {
          background: $gray-light;
          border: 0;
          height: auto;
          margin: 0 1.5vw;
          width: 0.1vw;
        }
      }
    }
  }
}
