.tilegrid {
  padding-top: 8vh;

  &__title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 30px;

    @include grid-media($md) {
      font-size: 24px;
      font-weight: 400;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    padding-top: 10px;

    @include grid-media($md) {
      font-size: 20px;
      font-weight: 400;
    }
  }

  &__row {
    margin-bottom: 50px;
    @include grid-media($md) {
      margin-bottom: 12vh;
    }

    &--top {
      display: flex;
      flex-direction: row;
      // justify-content: space-between;

      .icon--sort {
        &_accending,
        &_decending {
          margin-left: 20px;
          padding-top: 10px;
          width: 15px;

          @include grid-media($md) {
            margin-left: 1.5vw;
            width: 1.55vw;
          }
        }
      }
    }

    &--bottom {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__content {
    align-items: center;
    background-color: $dark-gray;
    border: 0.1vh solid $dark-gray-1;
    border-radius: 1vw;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    margin-right: 9px;
    margin-top: 2.5vh;
    padding: 3vh 3.5vh;
    width: 30%;

    @include grid-media($md) {
      width: 10.5vw;
      margin-right: 1vw;
    }

    &--image {
      background-image: url($icon-pdf-fill-invert);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      height: 50px;
      margin-bottom: 2.5vh;
      position: relative;
      width: 100%;

      @include grid-media($md) {
        height: 4vw;
      }

      &:after {
        content: '';
        background: $orange;
        bottom: -2vh;
        height: 0.1vh;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }

    &--title {
      margin-top: 1.4vh;
      text-align: center;

      p {
        margin: 0;
        font-size: 14px;
        font-weight: $font-thin;

        @include grid-media($md) {
          font-size: 1.1vw;
        }
      }
    }
  }

  &.reel {
    .featured-video__item {
      margin-top: 5vh;
      max-width: 49%;
      width: 100%;

      @include grid-media($md) {
        max-width: 19.7%;
      }
    }

    .tilegrid__row--bottom {
      position: relative;

      &.restricted {
        max-height: 65vh;
        overflow: hidden;
      }

      h4 {
        bottom: 0;
        color: $orange;
        cursor: pointer;
        position: absolute;
        right: 2vw;
      }

      .featured-video__item {
        cursor: pointer;
        height: 21.3vh;

        .miniPlayer__info-action {
          &:hover {
            opacity: 1;
          }
        }

        .miniPlayer {
          padding-top: 0;
        }
      }
    }
  }

  &.policies {
    .tilegrid__content {
      padding: 1.5vh 1.5vh;
      // width: 8.5vw;
    }
  }
}
