// --------------------------------------------------------------
// Controls
// --------------------------------------------------------------

// Hide native controls
.plyr--full-ui ::-webkit-media-controls {
    display: none;
}

// Playback controls
.plyr__controls {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    text-align: center;

    .plyr__progress__container {
        flex: 1;
        min-width: 0; // Fix for Edge issue where content would overflow
    }

    // Spacing
    .plyr__controls__item {
        margin-left: ($plyr-control-spacing / 4);

        &:first-child {
            margin-left: 0;
            margin-right: auto;
        }

        &.plyr__progress__container {
            padding-left: ($plyr-control-spacing / 4);
        }

        &.plyr__time {
            padding: 0 ($plyr-control-spacing / 2);
        }

        &.plyr__progress__container:first-child,
        &.plyr__time:first-child,
        &.plyr__time + .plyr__time {
            padding-left: 0;
        }

        &.plyr__volume {
            padding-right: ($plyr-control-spacing / 2);
        }

        &.plyr__volume:first-child {
            padding-right: 0;
        }
    }

    // Hide empty controls
    &:empty {
        display: none;
    }
}

// Audio controls
.plyr--audio .plyr__controls {
    background: $plyr-audio-controls-bg;
    border-radius: inherit;
    color: $plyr-audio-control-color;
    padding: $plyr-control-spacing;
}

// Video controls
.plyr--video .plyr__controls {
    background: linear-gradient(rgba($plyr-video-controls-bg, 0), rgba($plyr-video-controls-bg, 0.7));
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    bottom: 0;
    color: $plyr-video-control-color;
    left: 0;
    padding: ($plyr-control-spacing * 2) ($plyr-control-spacing / 2) ($plyr-control-spacing / 2);
    position: absolute;
    right: 0;
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
    z-index: 3;

    @media (min-width: $plyr-bp-sm) {
        padding: ($plyr-control-spacing * 3.5) $plyr-control-spacing $plyr-control-spacing;
    }
}

// Hide video controls
.plyr--video.plyr--hide-controls .plyr__controls {
    opacity: 0;
    pointer-events: none;
    transform: translateY(100%);
}

// Some options are hidden by default
.plyr [data-plyr='captions'],
.plyr [data-plyr='pip'],
.plyr [data-plyr='airplay'],
.plyr [data-plyr='fullscreen'] {
    display: none;
}
.plyr--captions-enabled [data-plyr='captions'],
.plyr--pip-supported [data-plyr='pip'],
.plyr--airplay-supported [data-plyr='airplay'],
.plyr--fullscreen-enabled [data-plyr='fullscreen'] {
    display: inline-block;
}
