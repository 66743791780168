// Mini Player
@import './miniPlayer';
.featured-video {
  font-family: $font-family;
  position: relative;
  &__title {
    color: $white;
    font-size: 16px;
    font-weight: $font-medium;
    position: absolute;
    width: 100%;

    @include grid-media($md) {
      padding-left: 2.7vw;
      top: 3vh;
      width: 100%;
    }

    &.moveup {
      z-index: 5;
    }

    p {
      cursor: pointer;
      font-size: 2.4vh;
      font-weight: $font-bold;
      display: inline-block;
      margin: 0;

      &:hover {
        span:not(.admin-reel-title--icons) {
          left: 1vw;
          opacity: 1;
        }
      }

      span:not(.admin-reel-title--icons) {
        cursor: pointer;
        font-size: 80%;
        font-weight: $font-normal;
        left: -3vw;
        position: relative;
        opacity: 0;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;

        &:before {
          background-image: url('../../../images/svg-images/reel-right-arrow.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          content: '';
          height: 100%;
          right: -20px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 7px;

          @include grid-media($md) {
            right: -0.7vw;
            width: 0.4vw;
          }
        }
      }

      .admin-reel-title--icons {
        align-items: center;
        display: none;
        height: 2.5vh;
        justify-content: space-between;
        left: 0;
        position: absolute;
        top: -20px;
        width: 45px;

        @include grid-media($md) {
          justify-content: unset;
          left: 2.7vw;
          top: -3.5vh;
          width: 4vw;
        }

        hr {
          background: $gray-light;
          border: 0;
          height: 100%;
          margin: 0;
          width: 0.1vw;
        }

        img {
          height: 2vh;

          &:first-of-type {
            margin-right: 0.75vw;
          }

          &:nth-of-type(2) {
            margin-left: 0.75vw;
          }
        }
      }
    }

    .admin-reel-position--icons {
      display: none;
      flex-direction: column;
      height: 100%;
      left: 0.6vh;
      justify-content: center;
      position: absolute;
      top: 0;
      z-index: 10;
    }

    .admin-reel-upload--icons {
      align-items: center;
      display: none;
      flex-direction: row;
      position: absolute;
      right: 0;
      top: 1px;

      @include grid-media($md) {
        right: 5.5vh;
        top: -8vh;
      }

      img {
        margin-right: 2vh;
        width: 3vh;
      }

      p {
        cursor: pointer;
        font-size: 2vh;
        font-weight: $font-normal;
      }
    }
  }
  .slick-prev,
  .slick-next {
    height: 24px;
    width: 15px;
    svg {
      fill: $red;
      height: 100%;
      width: 100%;
    }
    &:before {
      content: none;
      display: none;
    }
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
    svg {
      transform: rotate(180deg);
    }
  }
  .slick-track {
    // display: flex;
    // margin: 0;
    margin-left: 0;
  }
  &.zoomEffectRow {
    .first-element {
      &:hover {
        transform: translate3d(3.15vw, 0, 0);
        ~ .push-left {
          transform: translate3d(6.15vw, 0, 0);
        }
      }
    }
    .last-element {
      &:hover {
        transform: translate3d(-3.15vw, 0, 0);
      }
    }

    &.renderleft {
      .slick-slide.slick-active.slick-current {
        .featured-video__item.zoomEffect {
          transform-origin: left;
        }
      }
    }
  }
  &.push-element-right {
    .push-right {
      transform: translate3d(-6.15vw, 0, 0);
    }
  }
  .slick-list {
    @include margin(0 auto);

    @include grid-media($md) {
      // display: flex;
      // flex-direction: row;
      // justify-content: flex-start !important;
      width: calc(100% - 50px);
    }
  }
  .slick-slide {
    cursor: pointer;
    transform: translate3d(0, 0, 0);
    transition: all 0.5s $cubic-transition-2;
  }
  &__info {
    &--close {
      margin: 3vh 1vh;

      .slick-track {
        @include padding(32px 0);
        // padding-bottom: 7vh;
      }

      @include grid-media($md) {
        .push-right {
          transform: translate3d(-3.15vw, 0, 0);
        }
        .push-left {
          transform: translate3d(3.15vw, 0, 0);
        }
      }
    }
    &--open {
      margin: 3vh 1vh;

      .slick-track {
        @include padding(32px 0);
        padding-bottom: 7vh;
      }

      @include grid-media($md) {
        .push-right {
          transform: translate3d(-3.15vw, 0, 0);
        }
        .push-left {
          transform: translate3d(3.15vw, 0, 0);
        }
      }
    }
  }
  &__item {
    outline: none;
    margin-right: 0.5vh;

    &:nth-of-type(5) {
      margin-right: 0;
    }

    &.zoomEffect {
      height: 21.3vh;
      transition: all 0.6s $cubic-transition-2;
      &:hover {
        @include grid-media($lg) {
          transform: scale(1.3);
        }
        position: relative;
        z-index: 20;
        .miniPlayer {
          &__info-action,
          &__info-arrow {
            opacity: 1;
          }
          &__info--duedate {
            display: none;
          }
        }
      }
    }

    &--assessment {
      margin-right: 0.5vh;
      position: relative;

      &:after {
        // content: "";
        background-color: $black;
        position: absolute;
        height: 100%;
        left: 0;
        opacity: 0.75;
        top: 0;
        width: 100%;
        z-index: 1;
      }

      h3 {
        border: 0.1vw solid $white;
        color: $white;
        font-size: 0.8vw;
        left: 50%;
        padding: 0.5vw;
        position: absolute;
        top: 40%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }

      .assessment__image-wrapper img {
        height: 21.3vh;
        width: 100%;
      }

      &:hover {
        .miniPlayer__action {
          display: flex !important;
        }
      }

      &.unpublished {
        .assessment__image-unpublished {
          &:before {
            align-items: center;
            content: 'Unpublished';
            display: flex;
            font-size: 1vw;
            font-weight: $font-medium;
            justify-content: center;
            height: 100%;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            z-index: 2;
          }

          &:after {
            background-color: $dark-gray;
            content: '';
            left: 0;
            height: 100%;
            opacity: 0.8;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
          }
        }
      }

      .miniPlayer__action {
        display: none !important;
        height: 100%;
        justify-content: center;
      }

      .miniPlayer__click {
        position: absolute;
        z-index: 2;
      }

      .admin-reel_video--icons {
        align-items: center;
        background: $black;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
        padding-bottom: 0.2vw;
        position: absolute;
        opacity: 0.7;
        right: 0;
        top: 0;
        width: 2.5vw;
        z-index: 13;

        img {
          height: 2vh;
        }

        &.assessment {
          display: none !important;
          left: unset;
        }

        &-background {
          height: 21.3vh;
          width: 100%;
        }
      }
    }

    &.unpublished {
      .miniPlayer {
        height: 100%;
        position: relative;

        &:before {
          align-items: center;
          background: #000000e0;
          content: 'Unpublished';
          font-size: 1vw;
          font-weight: $font-medium;
          display: flex;
          height: 100%;
          justify-content: center;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 10;
        }

        &:hover {
          .miniPlayer__info-action {
            opacity: 1;
          }
        }
      }

      .miniPlayer__info-action {
        .miniPlayer__info,
        .miniPlayer__action,
        .miniPlayer__info-arrow {
          opacity: 0 !important;
        }
      }
    }
  }
}

// .slick-slide.slick-active.slick-current
