/* !important used to override the library styles */

.scroll-content,
.ScrollbarsCustom-Content {
  padding-right: 0.7vw !important;
}

.scrollbar-track,
.ScrollbarsCustom-Track {
  background: $gray !important;
  opacity: 0.4 !important;
  width: 0.5vw !important;

  &:hover {
    opacity: 1 !important;
  }
}

.scrollbar-thumb,
.ScrollbarsCustom-Thumb {
  background: $orange !important;
  width: 0.5vw !important;
  border-radius: 5vw !important;
}

.scrollbar-track.scrollbar-track-x,
.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
	// width: 100% !important;

	.scrollbar-thumb.scrollbar-thumb-x,
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX {
		width: 2.5vw !important;
	}
}

.ScrollbarsCustom {
  // height: calc(100vh - 145px) !important;
  width: 100% !important;
}