.nodeTree {
  &--wrapper {
  }
  &--page {
    &-title {
      margin-top: 4vh;

      .Dropdown-root {
        margin-bottom: 0 !important;
      }

      .all-users-table--page-settings {
        max-width: 20%;
      }
    }
  }
  &--chart {
    svg {
      background: $dark-gray;
      height: 70vh;

      > g {
        // transform: translate(50%, 10%) !important;
      }

      .nodeBase g,
      .leafNodeBase g {
        transform: translate(-2%, 3%);
      }

      .nodeBase,
      .leafNodeBase {
        position: relative;

        &:before {
          content: '+';
          position: absolute;
          width: 1vw;
          height: 1vw;
          top: 0;
          left: 0;
          color: $white;
        }
      }

      .nodeBase {
        &:first-of-type {
          rect {
            fill: $white;
            stroke: $white;
          }

          .nodeTree--chart-nodetitle {
            h2 {
              color: $black;
            }
          }

          .nodeTree--chart-button {
            &:before {
              // background: $black;
              // color: $white;
            }
          }

          button {
            &:before {
              background-image: url($icon-colorback);
            }
          }
        }
      }

      .leafNodeBase {
        // rect {
        //     fill: $black;
        //     stroke: $light-blue;
        // }

        .nodeTree--chart-nodetitle {
          h2 {
            // margin-top: 2.6vh;
          }
        }

        .nodeTree--chart-button {
          &:before {
            // background: $orange;
            // content: "-";
            // color: $white;
          }
        }
      }

      .nodeNameBase {
        fill: $white;
        font-size: 1.7vh;
        stroke: $white;
        stroke-width: 0.2;
        transform: translateX(-5px);
      }

      .linkBase {
        stroke: $light-gray-2;
      }

      rect {
        fill: $orange;
        stroke: $orange;
        rx: 10;
      }
    }

    foreignObject {
      text-align: center;
      position: relative;
    }

    &-nodetitle {
      left: 50%;
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      h2 {
        font-family: $font;
        font-size: 2vh;
        font-weight: $font-normal;
        margin: 0;
        overflow: hidden;
        padding: 0 1vh;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-button {
      background: none;
      color: transparent;
      height: 1vw;
      margin: 0 auto;
      position: relative;
      width: 1vw;

      &:before {
        //    align-items: center;
        // background: $black;
        // border-radius: 100%;
        // color: $white;
        // display: flex;
        // // font-family: $font;
        // font-size: 0.8vw;
        // font-weight: $font-bold;
        // height: 90%;
        //    justify-content: center;
        // left: 50%;
        // position: absolute;
        // top: 50%;
        // transform: translate(-50%, -50%);
        // width: 86%;

        background-image: url($icon-whiteback);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 98%;
        content: '';
        height: 20px;
        right: -4px;
        position: absolute;
        top: 0;
        width: 20px;

        @include grid-media($md) {
          height: 1vw;
          right: 0;
          top: 0.4vw;
          width: 1vw;
        }
      }

      &.expand {
        &:before {
          // content: "+";
        }
      }

      &.collapse {
        &:before {
          content: '-';
        }
      }
    }
  }

  .all-users-table--page-selection {
    max-width: 100%;

    @include grid-media($md) {
      max-width: 15%;
    }
  }
}
