.notification {
  &-screen {
    &__wrapper {
      display: flex;
      flex-direction: column;

      @include grid-media($md) {
        flex-direction: row;
      }
    }

    &__leftcol {
      width: 100%;

      @include grid-media($md) {
        max-width: 20%;
        margin-right: 2%;
      }
    }

    &__rightcol {
      width: 100%;

      @include grid-media($md) {
        max-width: 78%;
      }

      &--title {
        h2 {
          font-size: 4vh;
          font-weight: $font-bold;
          margin-top: 0;
          padding-left: 3vh;
        }
      }
    }

    &__nav {
      ul {
        padding-left: 0;

        @include grid-media($md) {
          padding-left: 40px;
        }

        li {
          font-size: 20px;
          padding: 3vh 0;

          @include grid-media($md) {
            border-right: 0.8vh solid $dark-gray-2;
            font-size: 22px;
          }

          &.active {
            border-right-color: $orange;
            color: $orange;
          }

          &:hover {
            cursor: pointer;
          }

          ul {
            display: flex;
            justify-content: space-evenly;
            padding-left: 0;

            @include grid-media($md) {
              display: block;
              padding-top: 1vh;
              padding-bottom: 0;
              padding-left: 40px;
            }

            li {
              border-right: 0;
              font-size: 16px;
              padding: 1vh;
              color: $white;

              @include grid-media($md) {
                font-size: 0.9vw;
              }

              &.active {
                color: $orange;
              }

              &:last-of-type {
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }

    &__settings {
      background: $dark-gray-2;
      border-radius: 15px;
      margin-bottom: 10px;

      &.open {
        .notification-screen__settings--body {
          max-height: 100vh;
          overflow: visible;
        }

        .icon--arrow {
          transform: rotate(180deg);
        }
      }

      &--title {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 0 3vh;
      }

      &--buttons {
        display: flex;

        .btn-allow,
        .btn-deny {
          background: $dark-gray-1;
          border-radius: 5vw;
          padding: 10px;
          color: $white;
          font-family: $font;
          font-size: 12px;
          min-width: 80px;

          @include grid-media($md) {
            padding: 1.5vh 3vh;
          }
        }

        button:first-of-type {
          margin-right: 1vh;
        }

        .icon--arrow {
          margin-left: 10px;
          transition: all 0.5s ease-in-out;
          width: 15px;
        }
      }

      &--options {
        border-top: 1px solid $gray;
        display: flex;
        flex-wrap: wrap;
        padding: 0 3vh;
        width: 100%;
      }

      &--actions {
        display: flex;
        justify-content: flex-end;
        padding: 3vh;
        width: 100%;
      }

      &--option {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 2%;
        margin-right: 2%;
        position: relative;
        width: 100%;

        @include grid-media($md) {
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          max-width: 31%;
        }

        @include grid-media($md) {
          &:before {
            content: '';
            background: $gray;
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            right: -4%;
          }

          &:nth-of-type(3n) {
            margin-right: 0;

            &:before {
              content: unset;
            }
          }
        }

        .header__switch {
          max-width: 25%;

          input {
            &:checked + .slider {
              background: $dark-gray-1;

              &:before {
                background: $orange;
              }
            }
          }
          .slider {
            background: $dark-gray-1;
          }
        }
      }

      &--body {
        display: flex;
        flex-wrap: wrap;
        transition: all 0.5s ease-in-out;
        max-height: 0;
        overflow: hidden;
        width: 100%;
      }

      &.admin-notification {
        display: flex;
        flex-wrap: wrap;

        .notification-screen__settings--title {
          width: 100%;
        }

        .notification-screen__settings--options-column {
          width: 100%;

          @include grid-media($md) {
            max-width: 33%;
          }

          h4 {
            font-weight: $font-bold;
            padding: 0 3vh;
          }

          .notification-screen__settings--option:before {
            content: none;
          }

          .notification-screen__settings--option {
            max-width: 100%;
          }
        }
      }
    }
  }

  &-list {
    &__wrapper {
    }
    &__title {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;

      h2 {
        font-size: 4vh;
        font-weight: $font-bold;
        margin-top: 0;
        // padding-left: 3vh;
      }

      .Dropdown-root {
        margin-bottom: 0 !important;
        width: 10% !important;

        .Dropdown-control {
          border-radius: 2vw;
          padding: 1vh 2vh;

          .Dropdown-placeholder.is-selected {
            width: 85%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:before {
            right: 9% !important;
          }
        }
      }
    }
    &__user {
      &--selection {
        margin-bottom: 30px;

        a {
          color: $dark-gray-1;
          font-size: 20px;
          margin-right: 25px;

          &.active {
            color: $orange;
            position: relative;

            &:after {
              background: $orange;
              content: '';
              height: 2px;
              left: 0;
              position: absolute;
              bottom: -5px;
              width: 100%;
            }
          }
        }
      }
    }
    &__content {
      align-items: center;
      background: $dark-gray-3;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 10px;
      padding: 20px 25px;
      position: relative;

      @include grid-media($md) {
        flex-direction: row;
        padding: 30px 40px;
      }

      &--closeBtn {
        svg {
          position: absolute;
          right: 20px;
          top: -50px;
          width: 15px;
        }
      }

      &--list {
        margin-bottom: 10px;
        height: 55vh;
        overflow-x: scroll;
      }

      &--leftcol,
      &--rightcol {
        width: 100%;

        @include grid-media($md) {
          width: unset;
        }
      }

      &--leftcol {
        p {
          color: $orange;
          font-size: 14px;
          margin-top: 0;
        }
        h3 {
          color: $white !important;
          font-size: 20px;
          font-weight: $font-bold;
          margin-bottom: 0;
          margin-top: 0;
        }

        span {
          font-weight: 16px;
        }
      }
      &--rightcol {
        p {
          color: $light-gray;
        }
      }
    }
  }
}
