// TYPOGRAPHY

$font: 'Roboto', Helvetica Neue, Helvetica, Arial, sans-serif;

/* Headline */

%h1 {
  color: $white;
  font-family: $font;
  font-size: 28px;
  font-weight: normal;
  line-height: 1.1;

  @include grid-media($md) {
    font-size: 3vw;
  }
}

%h2 {
  color: $white;
  font-family: $font;
  font-size: 16px;
  font-weight: normal;
  //line-height: 1.1;

  @include grid-media($md) {
    font-size: 1.6vw;
  }
}

%h3 {
  color: $white;
  font-family: $font;
  font-size: 14px;
  font-weight: normal;
  //line-height: 1.1;

  @include grid-media($md) {
    font-size: 1.2vw;
  }
}

%h4 {
  color: $white;
  font-family: $font;
  font-size: 12px;
  font-weight: normal;
  //line-height: 1.1;

  @include grid-media($md) {
    font-size: 1vw;
  }
}

%h5 {
  color: $white;
  font-family: $font;
  font-size: 12px;
  font-weight: normal;
  //line-height: 1.1;

  @include grid-media($md) {
    font-size: 0.8vw;
  }
}

%h6 {
  color: $white;
  font-family: $font;
  font-size: 10px;
  font-weight: normal;
  //line-height: 1.1;

  @include grid-media($md) {
    font-size: 0.7vw;
  }
}

p {
  color: $white;
  font-family: $font;
  font-size: 12px;
  font-weight: normal;
  //line-height: 1.1;

  @include grid-media($md) {
    font-size: 0.9vw;
  }
}

body {
  color: $white;
  font-family: $font;
}

input {
  font-family: $font;
}

@mixin h1() {
  @extend %h1;
}

@mixin h2() {
  @extend %h2;
}

@mixin h3() {
  @extend %h3;
}

@mixin h4() {
  @extend %h4;
}

@mixin h5() {
  @extend %h5;
}

@mixin h6() {
  @extend %h6;
}

/* Heading 1 */
h1 {
  @include h1;
}

/* Heading 2 */
h2 {
  @include h2;
}

/* Heading 3 */
h3 {
  @include h3;
}

/* Heading 4 */
h4 {
  @include h4;
}

/* Heading 5 */
h5 {
  @include h5;
}

/* Heading 6 */
h6 {
  @include h6;
}
