/**
* Buttons
*/

button {
  border-radius: 0;
  border: none;
  cursor: pointer;
  outline: none;
}

.btn {
  background-color: $white;
  border-radius: 10px;
  font-family: $font;
  font-size: 16px;
  line-height: 1;
  color: $black;
  height: 40px;
  min-width: 80px;
  padding: 2px 15px;
  transition: all ease 0.5s;

  @include grid-media($md) {
    border-radius: 0;
    font-size: 1vw;
    height: 4vh;
    min-width: 6vw;
    padding: 1vh 2vh;
  }

  &:disabled {
    cursor: not-allowed;
    filter: grayscale(70%);
  }

  &--primary {
    background: $orange;
    color: $white;
  }

  &--secondary {
    background: $dark-gray-1;
    color: $white;
  }

  &--tertiary {
    background: $dark-gray-2;
    color: $white;
  }

  &--createnew {
    height: 5vh;
  }

  &--request {
    position: relative;
    width: 30vw;

    &:before {
      background-image: url('../../../images/svg-images/icon-request.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      content: '';
      height: 100%;
      left: 0.5vw;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1.2vw;
    }
  }

  &--roundEdges {
    border-radius: 10px;
  }

  &--curved {
    border-radius: 30px;
  }

  &--long {
    min-width: 13vw !important;
    padding-right: 0;

    &.reel-table-download {
      height: 5vh;
      padding-right: 2vh;
    }
  }

  &--back {
    color: $gray;
    cursor: pointer;
    font-size: 1.2vw;
    font-weight: $font-thin;
    display: inline-block;
    padding-left: 1.8vw;
    position: relative;

    &:before {
      background-image: url($icon-backarrow);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1.2vw;
    }
  }

  &--clicked {
    -moz-box-shadow: inset 0 0 10px #0000009e;
    -webkit-box-shadow: inset 0 0 10px #0000009e;
    box-shadow: inset 0 0 10px #0000009e;
    filter: brightness(50%);
  }

  &--loader {
    animation: rotate 1.4s ease 0.5s infinite;
    background: none;
    border: 3px solid $orange;
    border-bottom: 3px solid white;
    border-radius: 50%;
    font-size: 0;
    height: 25px;
    min-width: 1vw !important;
    min-height: 1vw;
    padding: 0;
    width: 25px;

    @include grid-media($md) {
      height: 30px;
      width: 30px;
    }
  }

  &-close {
    cursor: pointer;

    &.closemodale {
      font-size: 24px;
    }
  }
}

.delete-item {
  margin: 2vh 1.5vh;
  text-align: right;
  width: 100%;

  span {
    cursor: pointer;
    padding-left: 3.5vh;
    position: relative;

    &:before {
      background-image: url('../../../images/svg-images/icon-delete-white.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1.2vw;
    }
  }
}

// .btn {
//     border: none;
//     color: $white;
//     cursor: pointer;
//     font-family: $font;
//     font-size: 18px;
//     font-weight: $font-semibold;
//     height: 100%;
//     margin-bottom: 10px;
//     padding: 0;
//     width: 100%;
//     transition: ease-in-out 0.25s;
//     @include grid-media($md) {
//         font-size: 1vw;
//     }
//     &:hover {
//         filter: brightness(50%);
//     }
//     &--last {
//         margin: 0;
//     }
//     &--secondary {
//         background-color: $nero;
//         border-radius: 1vw;
//     }
// }
