.group-users {
	&-table {
		margin-top: 5vh;
		
		.Dropdown-root {
			margin-bottom: 0 !important;
		}

		.btn--back {
			display: inline-block;
			margin-left: 12%;
		}

		&--wrapper {
			display: flex;
			flex-direction: row;

			&-column {
				width: 100%; 
				&:first-of-type {
					max-width: 20%;
				}

				&:last-of-type {
					max-width: 80%;
					padding-left: 3vw;
				}
			}
		}

		&--options {}
		&--option {
			align-items: center;
			display: flex;
			flex-direction: column;
			height: 17vh;
			justify-content: center;
			position: relative;
			margin-right: 2vh;

			&:before {
				content: "";
				background: $dark-gray-2;
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;
				width: 0.4vw;
			}

			&.active {

				&:before {
					background: $orange;
				}
				

				h3 {
					color: $orange;
					font-weight: $font-bold;
				}

				.group-users-table--option-icon {
					&.groupimg {
						background-image: url($icon-group-hover);
					}

					&.userimg {
						background-image: url($icon-user-hover);
					}

					&.reelimg {
						background-image: url($icon-reel-hover);
					}

					&.assessmentimg {
						background-image: url($icon-assessment-hover);
					}
				}
			}

			&:hover {
				cursor: pointer;

				h3 {
					color: $orange;
					font-weight: $font-bold;
				}

				.group-users-table--option-icon {
					&.groupimg {
						background-image: url($icon-group-hover);
					}

					&.userimg {
						background-image: url($icon-user-hover);
					}

					&.reelimg {
						background-image: url($icon-reel-hover);
					}

					&.assessmentimg {
						background-image: url($icon-assessment-hover);
					}
				}
			}

			&-icon {
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				height: 2vw;
				width: 5vw;

				&.groupimg {
					background-image: url('../../../images/svg-images/icon-group.svg');
				}

				&.userimg {
					background-image: url('../../../images/svg-images/icon-user.svg');
				}

				&.reelimg {
					background-image: url('../../../images/svg-images/icon-reel.svg');
				}

				&.assessmentimg {
					background-image: url('../../../images/svg-images/icon-assessment.svg');
				}
			}

			&.disabled {
				pointer-events: none;
				opacity: 0.3;
			}

			h3 {
				font-size: 1vw;
			}
		}

		&--page {
      &-button-title {
        display: flex;
				flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }
			&-title {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-bottom: 2vh;
			}
			&-selection {
				max-width: 80%;
				width: 100%;

				h2 {
					font-size: 2vw;
			        font-weight: $font-bold;
			        margin: 0;
			        max-width: 30%;
                    overflow: hidden;
	                padding: 0 1vh;
	                padding-left: 0;
	                text-overflow: ellipsis;
			        width: 100%;
	                white-space: nowrap;
				}

				.breadcrumbs {
					color: $gray;
					cursor: pointer;

					a {
						color: $white;
						text-decoration: underline;
					}
				}
			}

			&-settings {
				align-items: center;
				display: flex;
				flex-direction: row;
				margin-top: 6vh;
				max-width: 50%;
				width: 100%;

				.form-input--search {
					background: url('../../../images/svg-images/icon-search-gray.svg') right 0.9vw center no-repeat;
					background-color: $dark-gray-2;
					background-size: 1.3vw !important;
					border-radius: 0;
					height: 5vh;
					margin-right: 2vh;
					padding-left: 1vw;
					padding-right: 2.5vw;
					width: 100%;
				}

				.Dropdown-control {
					margin-right: 2vh !important;
					padding: 1.3vh 1vh !important;
				}

				.btn--createnew {
					font-size: 1vw;
					margin-right: 2vh;
					min-width: 10vw;
					padding-left: 2%;
					position: relative;
					// width: 100%;
					

					&:before {
						content: "+";
						font-size: 2vw;
						font-weight: $font-thin;
						left: 8%;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}

		&--table {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;

			&-header,
			&-body {
				display: flex;
			    flex-direction: row;
			    justify-content: space-between;
				width: 100%;
			}

			&-header {
				border-bottom: 0.1vw solid $dark-gray-2;

				.all-users-table--table-cell {
					h5 {
						font-size: 1vw;
						font-weight: $font-bold;
						margin: 2vh 0;
					}
				}
			}

			&-body {
				flex-wrap: wrap;

				.all-users-table--table-row {
					&:hover {
						background-color: $dark-gray-3;
					}
				}

				.all-users-table--table-cell {
					&:nth-of-type(5) {
						p {
							padding-left: 2vh;
						}
					}
				}
			}

			&-row {
				align-items: center;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding: 1vh 0;
				width: 100%;

				&:hover {
					background: $dark-gray-1;
					cursor: pointer;
				}
			}

			&-cell {
				&:nth-of-type(1) {
				  text-align: center; 
				  max-width: 4%;
				  width: 100%;
				}
				&:nth-of-type(2) {
				  max-width: 9%;
				  width: 100%;
				}
				&:nth-of-type(3) {
				  max-width: 17%;
				  width: 100%;
				}
				&:nth-of-type(4) {
				  max-width: 13%;
				  width: 100%;
				}
				&:nth-of-type(5) {
				  max-width: 13%;
				  width: 100%;
				}
				&:nth-of-type(6) {
				  max-width: 13%;
				  width: 100%;
				}
				&:nth-of-type(7) {
				  max-width: 13%;
				  width: 100%;
				}
				&:nth-of-type(8) {
				  max-width: 13%;
				  width: 100%;
				}

				p {
					color: $gray;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.Dropdown-root {
					width: 50% !important;
				}
			}

			&-avatarimg {
				border: 0.1vw solid $white;
				border-radius: 10vw;
			    height: 2vw;
				width: 2vw;
			}
		}

		&--grid {
			display: flex;
			flex-direction: column;
			background: $dark-gray-4;

			&-wrapper {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
		    	padding: 0 1.5vw;
		    	height: 100%;
	    		overflow: hidden;
		    	transition: height 0.5s ease;

		    	&.closed {
		    		height: 0;
		    	}
			}

			&-header {
				    align-items: center;
				    border-bottom: 0.1vw solid $dark-gray-2;
				    display: flex;
				    flex-direction: row;
				    justify-content: space-between;
				    margin-bottom: 3vh;
		            position: relative;

		            &.accordion {
		            	cursor: pointer;

		            	&:before {
				          background-image: url('../../../images/svg-images/reel-left-arrow.svg');
				          background-repeat: no-repeat;
				          background-position: center;
				          background-size: contain;
				          content: "";
				          height: 30%;
				          right: 1%;
				          position: absolute;
				          top: 50%;
				          transform: translateY(-50%) rotate(-90deg);
			              transition: ease 0.5s;
				          width: 1.2vw ;
				        }

				        &.closed {
					    	&:before {
					          transform: translateY(-50%) rotate(90deg);
					        } 
					    }
		            }

				    p {
				    	color: $orange;
				    	font-size: 1vw;
				    	padding-left: 1.5vw;
				    }

				    span {
					    align-items: center;
			    	    display: flex;
				    	font-size: 1vw;
				    	padding-right: 2.5vw;
				    }
			}

			&-card {
				align-items: center;
				background-color: $dark-gray-2;
				display: flex;
				flex-direction: column;
				justify-content: center; 
				margin-bottom: 1.5vw;
				margin-right: 1%;
				max-width: 15.5%;
				padding: 0.7vw 0vw;
				width: 100%;

				.Dropdown-control {
					&:before {
						right: 0 !important;
					}
				}

				&:nth-of-type(6) {
				  // margin-right: 0;
				}

				h5 {
					font-size: 1.1vw;
					font-weight: $font-bold;
					margin-bottom: 1vh;
					margin-top: 3vh;
				    overflow: hidden;
				    text-align: center;
				    text-overflow: ellipsis;
					white-space: nowrap;
				    width: 90%;
				}

				p {
					color: $light-gray-2;
					border-bottom: 0.1vw solid $dark-gray-1;
					margin-top: 0;
					padding-bottom: 2vh;
					text-align: center;
					width: 100%;
				}

				button.btn.btn--primary {
					font-size: 1vw;
					border-radius: 10vw;
					height: 4vh;
				}

				.Dropdown-control {
					background: $orange !important;
				    border-radius: 100%;
				    cursor: pointer;
				    float: right;
				    height: 2vh !important;
				    margin-right: 1vh;
				    min-width: unset !important;
				    padding: 0 !important;
				    width: 2vh;

				    &:before {
				    	height: 55% !important;
				    	transform: translateY(-45%) rotate(-90deg) !important; 
				    	width: 100% !important;
				    }

				    .Dropdown-placeholder {
				    	display: none !important;
				    }
				}

			    .Dropdown-menu {
			    	background-color: $dark-gray-3 !important;
		    	    box-shadow: -3px 5px 6px $dark-gray-1;
				    right: 0;
		    	    top: 0;
				    width: 50%;
			    }
			}

			&-avatarimg {
				border: 0.1vw solid $white;
				border-radius: 10vw;
				height: 6vw;
				// margin-top: 3vh;
				width: 6vw;
			}

			&-personimg {
				margin-right: 0.5vw;
			}
		}

		&--image {
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			cursor: pointer;
			width: 10vh;
			height: 100%;

			&.grid {
				background-image: url('../../../images/svg-images/icon-gridview.svg');
				margin-right: 2vh;

				&:hover,
				&.active {
					background-image: url($icon-gridview-hover);
				}
			}

			&.list {
				background-image: url('../../../images/svg-images/icon-listview.svg');

				&:hover,
				&.active {
					background-image: url($icon-listview-hover);
				}
			}
		}

		&--popup {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $popup-black;

			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 15;

			&-container {
				background: $dark-gray-3;
				max-width: 40%;
				width: 100%; 
			}

			&-header {
				border-bottom: 0.1vh solid $light-gray-2;
				display: flex;
				justify-content: space-between;
				padding-top: 3vh;
				position: relative;

				h3 {
					font-weight: $font-bold;
					padding-left: 4vh;
					margin-top: 0;
				}

				a {
					color: $white;
					padding-right: 4vh;
					top: 49%;

					&:hover {
						color: $white;
					}
				}
			}

			&-body {
				padding: 4vh;
			}

			&-row {
				margin-bottom: 3vh;

				&:last-of-type {
					display: flex;
					justify-content: flex-end;
					margin-bottom: 0;
				}

				label {
					display: inline-block;
					font-size: 1vw;
					margin-bottom: 2vh;
				}

				.Dropdown-root {}

				button {
					&:last-of-type {
						margin-left: 3vh;
						min-width: 12vw;
					}
				}
			}

			&-field {
				.multi-select {
					margin-right: 0;
				}
				.suspend-user {
					opacity: 0.3;
				}
			}
		}

		&--row {
			margin-bottom: 2vh;
		}

		&--creategroup {
			input {
				background: none;
				border: none;
				border-bottom: 0.05vw solid $light-gray-2;
				color: $white;
				padding: 1.5vh 1vh;
				padding-bottom: 0.5vh;
				padding-left: 0;
				width: 30%;

				&::-webkit-input-placeholder { /* Edge */
		          color: $white;
		        }

		        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
		          color: $white;
		        }

		        &::placeholder {
		          color: $white;
		        }
			}

			button {
				&:first-of-type {
					margin-right: 2vh;
					margin-top: 3vh;
				}
        &:nth-of-type(2) {
					margin-right: 2vh;
					margin-top: 3vh;
				}
			}
		}

		.unpublished {
			position: relative;
			// width: 100%;
			
			&:before {
		      align-items: center;
		      content: "Unpublished";
		      display: flex;
		      font-size: 1vw;
		      font-weight: $font-medium;
		      justify-content: center;
		      height: 100%;
		      left: 0;
		      position: absolute;
		      top: 50%;
		      transform: translateY(-50%);
		      width: 100%;
		      z-index: 2;
			}

			&:after {
			  background-color: $dark-gray;
			  content: "";
			  left: 0;
			  height: 100%;
			  opacity: 0.8;
			  position: absolute;
			  top: 0;
			  width: 100%;
			  z-index: 1;
			}

			.reel-view--grid-video-settings {
				z-index: 2;
			}
		}
	}
}