// PPIS theme color
$orange: #ff9900;
$lightOrange: #ffd100;

// MSDCA theme colors
$orange-msdca: #f2522e;
$light-orange--msdca: #ff9120;

// APIIT colors
$blue-apiit: #01b4ad;
$light-blue-apiit: #80d9d6;

// ASB colors
$blue-asb: #036bde;
$light-blue-asb: #81b5ee;

// Australasia colors
$blue-australasia: #3c91d8;
$light-blue-australasia: #7cb5e5;

// Knod-lanka colors
$yellow-knod-lanka: #eed012;
$light-yellow-knod-lanka: #d7bc0f;

// GSA colors
$green-gsa: #00a04e;
$light-green-gsa: #7fcfa6;

// IYF colors
$orange-iyf: #fc6002;
$light-orange-iyf: #fdbf99;

// QRIUS colors
$blue-qrius: #01c2ff;
$light-blue-qrius: #7fbed1;

// F17 colors
$yellow-f17: #c59606;
$light-yellow-f17: #e7d59b;

// LB colors
$red-lb: #ff0000;
$light-red-lb: #ffb2b2;

// ISR colors
$orange-isr: #f2522e;
$light-orange-isr: #ff9120;

// TRIPLESKILLZ colors
$blue-tripleskillz: #01b4ad;
$light-blue-tripleskillz: #80d9d6;
$red-tripleskillz: #d91d28;
$yellow-tripleskillz: #ffc400;

// UBF colors
$blue-ubf: #3b5beb;
$light-blue-ubf: #5a9bf0;

// SAVILLS colors
$darkBlue-savills: #213d64;
$blue-savills: #2473e2;
$light-blue-savills: #6ca4f3;
$yellow-savills: #e9cc0b;

// KOACH-CRICKET colors
$green-koach-cricket: #02c536;
$light-green-koach-cricket: #9df1b5;

// REALESTATE colors
$green-koach-realestate: #8cbc23;
$light-green-koach-realestate: #d9fa92;

// COTN colors
$blue-cotn: #15ceff;
$light-blue-cotn: #8ee5fd;
$orange-cotn: #ffc108;
$darkBlue-cotn: #000b33;
$veryDarkBlue-cota: #010823;

// BPCC
$blue-koach-bpcc: #008cf8;
$light-blue-koach-bpcc: #8accff;
$yellow-bpcc: #ffc108;

// SCHOOLOFLIGHT colors
$blue-koach-schooloflight: #259dee;
$light-blue-koach-schooloflight: #a4d7fa;
$purple-schooloflight: #8d20ff;

// Orange soccer
$orange-osa: #ff5408;
$light-orange-osa: #ffbca0;

// My africa
$blue-myafrica: #0873ff;
$light-blue-myafrica: #70aaf5;

// meuLabs
$yellow-meulabs: #FCCA00;
$light-yellow-meulabs:#f9e7a0;
$purple-meulabs: #8d20ff;

// dxdy
$blue-dxdy: #15B6DA;
$light-blue-dxdy:#cbf2fb;
$purple-dxdy: #8d20ff;

//Training
$green-training: #2DA896;
$light-green-training: #33C6C4;

// COLORS
$black: #000;
$popup-black: rgba($black, 0.9);
$white: #fff;
$green: #00cf29;
$nero: #1c1c1c;
$red: #ff0000;
$hover-red: #c73032;
$gray: #828282;
$gray-light: #c2c2c2;
$light-gray: #7b7b7b;
$light-gray-1: #e5e5e5;
$light-gray-2: #707070;
$dark-gray: #0e0e0e;
$dark-gray-1: #242424;
$dark-gray-2: #0f0f0f;
$dark-gray-3: #191919;
$dark-gray-4: #070707;
$backgroundgray: #dcdcdc;
$unseengray: #151515;
$lightmode-lightgray: #b5b5b5;
$gray-light: #727272;
$brown: #8e7a3f;
$buttonbrown: #352b2b;
$brownmedium: #4f4423;
$dark-brown: #0e0c06;
$light-brown: #a49465;
$light-brown-2: #998752;
$light-brown-3: #716132;
// $blue: #001489;
$blue: #0059de;
$buttonblue: #091754;
// $light-blue: #7D87C3;
$light-blue: #00a2ff;
$light-blue-2: #3343a1;
$light-blue-3: #bdc1e1;
$dark-blue: #00062d;

// FONT WEIGHTS
$font-thinnest: 100;
$font-thinner: 200;
$font-thin: 300;
$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;

// BREAKPOINTS

// Mobiles
$xxs: (
  media: 460px,
);

$xs: (
  media: 576px,
);

// Tablets (portrait view)
$sm: (
  media: 768px,
);

// Tablets (Landscape view)
$md: (
  media: 992px,
);

// Small desktops
$lg: (
  media: 1200px,
);

// Large desktops
$xl: (
  media: 1440px,
);

// TRANSITIONS
$cubic-transition: cubic-bezier(0.51, 0.92, 0.24, 1.15);
$cubic-transition-2: cubic-bezier(0, 0, 0.2, 1);

// Font Family
$font-family: 'Roboto', sans-serif;
