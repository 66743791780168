// Animation
.loading-screen {
  align-items: center;
  background: $black;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 50;

  &-parent {
    position: relative;

    .loading-screen {
      height: 100%;
      width: 100%;
    }

    &.setheight {
      height: 50vh;
    }
  }

  .loading-screen-before {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    content: '';
    height: 100px;
    width: 100px;
    z-index: 55;

    @include grid-media($md) {
      height: 7vw;
      width: 8vw;
    }
  }

  /* Lines Load */
  .lds-facebook {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;

    @include grid-media($md) {
      width: 3.5vw;
      height: 3.5vw;
    }
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 1.7vh;
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 0;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    background: $orange;
    left: 2.9vh;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 5.8vh;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%,
    100% {
      top: 24px;
      height: 32px;
    }
  }
}

// Assessment Counter

#countdown {
  align-items: center;
  background: $black;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 50;
}

.base-timer {
  position: relative;
  width: 300px;
  height: 300px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: $dark-gray;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: $orange;
}

.base-timer__path-remaining.orange {
  color: $brownmedium;
}

.base-timer__path-remaining.red {
  color: $lightOrange;
}

.base-timer__label {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
}

// Dotted Circle
.loading--animation-area {
  display: flex;
  justify-content: center;
}

.loading-dotted-circle {
  display: inline-block;
  margin: 2vh;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading-dotted-circle div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: $orange;
  border-radius: 50%;
  animation: loading-dotted-circle 1.2s linear infinite;
}
.loading-dotted-circle div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.loading-dotted-circle div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.loading-dotted-circle div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.loading-dotted-circle div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.loading-dotted-circle div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.loading-dotted-circle div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.loading-dotted-circle div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.loading-dotted-circle div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.loading-dotted-circle div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.loading-dotted-circle div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.loading-dotted-circle div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.loading-dotted-circle div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes loading-dotted-circle {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
