.footer {
  padding: 5vh 0;

  @include grid-media($md) {
    margin-top: 8vh;
  }

  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    width: 100%;

    @include grid-media($md) {
      flex-direction: row;
    }
  }

  &__group {
    bottom: 0;
    cursor: pointer;
    position: absolute;
    right: 0;

    @include grid-media($md) {
      bottom: 5vh;
      position: unset;
      right: 5vh;
    }

    .icon--goup {
      cursor: pointer;
      background-image: url($icon-goup) !important;
      height: 7vh;
      width: 7vh;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    max-width: 75%;
    width: 100%;

    @include grid-media($md) {
      display: inline;
      margin-bottom: 0;
    }

    img {
      height: 10vh;
    }
  }

  &__menu {
    max-width: 60%;
    width: 100%;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;
      margin-top: 0;

      li {
        max-width: 33%;
        width: 100%;

        a {
          color: $white;
          text-decoration: none;
          font-size: 1vw;
          font-weight: $font-thin;
          line-height: 2;
        }
      }
    }
  }

  &__social-menu {
    margin-bottom: 20px;
    max-width: 70%;
    width: 100%;

    @include grid-media($md) {
      margin-bottom: 0;
      max-width: 20%;
    }

    p {
      font-size: 16px;
      font-weight: $font-thin;
      line-height: 2;
      margin: 0;
      margin-bottom: 20px;
      text-align: center;

      @include grid-media($md) {
        font-size: 1.1vw;
        margin-bottom: 0;
        text-align: left;
      }
    }

    ul {
      display: flex;
      justify-content: space-evenly;
      padding-left: 0;
      margin-top: 0;
      width: 100%;

      @include grid-media($md) {
        display: inline;
      }

      li {
        display: inline-block;

        @include grid-media($md) {
          margin-right: 2vw;
        }
      }
    }
  }
}
