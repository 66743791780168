/**
* Override Styles
*/
body,
div {
  &::-webkit-scrollbar {
    height: 1vh;
    width: 1vh;
  }
}

body,
div {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

body,
div {
  &::-webkit-scrollbar-thumb {
    background-color: $orange;
  }
}

.container {
  position: relative;
}

.react-pdf__Page__textContent {
  display: none !important;
}

.disable_click {
  pointer-events: none !important;
}

.slick-prev,
.slick-next {
  top: 40% !important;
}

.item-policy {
  .slick-prev,
  .slick-next {
    top: 50% !important;
  }
}

.middle-video-reel {
  .slick-prev,
  .slick-next {
    top: 46% !important;
  }
}

.video-info.animation-on {
  margin-left: -1vh;
  width: calc(100% + 2vh);
}

.multi-select {
  .select-item.false {
    &:hover,
    &:focus {
      background: none !important;
    }
  }

  .dropdown-container[aria-expanded='false'] .dropdown-heading:before {
    transform: rotate(90deg);
    top: 35%;
  }
}

ul.react-paginate {
  display: flex;
  padding-left: 0;
  padding-right: 15px;
  margin: 0;
  height: 30px;

  li {
    align-items: center;
    background-color: gray;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-right: 15px;
    min-width: 30px;
    min-height: 20px;
    user-select: none;

    &.active {
      z-index: 3;
      color: #fff;
    }

    &.previous,
    &.next {
      background: none;

      &.disabled {
        a {
          &:before {
            opacity: 0.2;
          }
        }
      }

      a {
        color: transparent;
        position: relative;

        &:before {
          content: '';
          background-image: url('../../../images/svg-images/reel-left-arrow.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 50%;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
        }
      }
    }

    &.next {
      margin-right: 0;

      a {
        &:before {
          transform: rotate(180deg);
        }
      }
    }

    a {
      font-size: 14px;
      padding: 5px;
    }
  }
}

.noContent {
  &--wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 30vh;
    width: 100%;
  }
}
