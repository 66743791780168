.signup-form {
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 15;

  height: calc(100vh + 10vh);
  margin-top: -15vh;
  // padding-top: 15vh;

  @include grid-media($md) {
    background-position: 0% 20%;
    background-size: 135%;
    height: calc(100vh + 5vh);
  }

  &:before {
    background: $black;
    content: '';
    left: 0;
    position: absolute;
    height: calc(100vh + 5vh);
    opacity: 0.75;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &__wrapper {
    position: relative;
    z-index: 20;
  }

  &__form {
    background: $black;
    border-radius: 50px;
    margin: 0 auto;
    padding: 4vh;
    width: 95%;

    @include grid-media($md) {
      width: 40%;
    }

    &.form {
    }
    &-header {
      position: relative;

      h3 {
        font-size: 18px;
        font-weight: $font-bold;
        margin-top: 0;

        @include grid-media($md) {
          font-size: 1vw;
        }
      }
      .btn-close {
        color: $white;
        right: 0;
      }
    }

    &-body {
      display: flex;
      flex-wrap: wrap;
    }

    &-row {
      margin-top: 2vh;
      max-width: 100%;
      width: 100%;

      &:first-of-type {
        margin-top: 0;
      }

      .Dropdown-root {
        margin-top: 10px;
        margin-bottom: 0 !important;

        .Dropdown-control {
          border-radius: 10px;
        }

        .Dropdown-option,
        .Dropdown-placeholder {
          font-size: 14px !important;
        }
      }

      &.userimage {
        margin-right: 5%;
        max-width: 40%;
        width: 10vw;

        img {
          height: 10vw;
          width: 10vw;
        }
      }

      &.uploadsection {
        max-width: 55%;
        width: 100%;

        label {
          font-weight: $font-bold;
          &:last-of-type {
            margin-top: 3vh;
          }
        }
      }

      label {
        font-size: 1.5vh;
        font-weight: $font-bold;
      }

      input[type='radio']:not(:checked) + label:before,
      input[type='radio']:not(:checked) + label:after,
      input[type='radio']:checked + label:before,
      input[type='radio']:checked + label:after {
        height: 0.8vw;
        width: 0.8vw;
      }
    }

    .upload-btn-wrapper {
      display: inline-block;
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    .btn--upload {
      align-items: center;
      background-color: $dark-gray-2;
      background-image: url('../../../images/svg-images/icon-photo.svg');
      background-position: center 5vh;
      background-repeat: no-repeat;
      background-size: 20%;
      border-radius: 1.5vw;
      color: $white;
      display: flex;
      flex-direction: column;
      font-size: 1vw;
      justify-content: flex-end;
      height: 20vh;
      padding: 0 1vw;
      padding-bottom: 3vh;
      width: 100%;
    }

    .upload-btn-wrapper input[type='file'] {
      cursor: pointer;
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }

    #upload-preview-img,
    #upload-preview-url,
    input {
      background: $dark-gray-2;
      border: none;
      border-radius: 10px;
      color: #fff;
      font-size: 14px;
      margin-top: 1vh;
      padding: 1vh;
      width: 100%;
    }

    .signupbutton {
      border-radius: 5px;
      width: 100%;
    }
  }
}
