.assessment {
  &__result {
    height: 100%;
    max-height: 75%;
    max-width: 80%;
    // padding: 70px 110px;
    padding: 30px;
    width: 100%;

    @include grid-media($md) {
      max-width: 850px;
      padding: 7vh;
    }

    .assessment__scrollbar {
      height: 100%;
      max-height: 55vh;

      @include grid-media($md) {
        max-height: 45vh;
      }
    }

    .modal-popup__header {
      .closemodale,
      .closemodale:hover {
        color: $white;
        right: -2.5vw;
        top: 0.5vh;
        display: none;
      }
    }

    &--title {
      width: 80%;

      h2 {
        color: $white;
        font-family: $font;
        font-size: 18px;
        font-weight: 500;
        line-height: 1;
        position: relative;
        text-align: left;
        width: 85%;

        @include grid-media($xs) {
          font-size: 26px;
        }

        @include grid-media($md) {
          font-size: 50px;
          width: 100%;
        }

        span {
          bottom: -50px;
          color: $orange;
          font-size: 16px;
          line-height: 23px;
          left: 0;
          position: absolute;

          @include grid-media($xs) {
            bottom: -25px;
          }
        }
      }
    }

    &--count {
      background-color: $orange;
      border-radius: 0 0 2vw 2vw;
      color: $white;
      padding: 1vh;
      // padding-bottom: 0;
      position: absolute;
      right: 1vw;
      top: 0;
      width: 100px;

      @include grid-media($md) {
        width: 11vw;
      }

      p {
        font-size: 21px;
        font-weight: 400;
        line-height: 30px;
        margin: 0;
      }

      h2 {
        color: $white;
        font-size: 43px;
        font-weight: 700;
        line-height: 1;
      }
    }

    &--wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      text-align: left;
    }

    &--list {
      padding-top: 1vh;
    }

    &--question {
      margin-top: 3vh;
      position: relative;

      h5 {
        color: $white;
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        padding-left: 25px;

        @include grid-media($md) {
          padding-left: 0;
        }
      }

      p {
        color: $white;
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
      }
    }

    &--result {
      left: 0;
      position: absolute;
      top: 0;

      @include grid-media($md) {
        left: 13%;
      }

      &-correct,
      &-incorrect,
      p {
        color: transparent;
        margin: 0;
        padding-left: 5%;
        position: relative;
        height: 100%;

        &:before {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-60%);
          width: 20px;

          @include grid-media($md) {
            width: 1.2vw;
          }
        }
      }

      &-correct {
        &:before {
          background-image: url('../../../images/green-tick.svg');
        }
      }
      &-incorrect {
        &:before {
          background-image: url('../../../images/red-cross.svg');
        }
      }
    }

    &--answer {
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;

        &.assessment__result--answer-youranswer {
          color: $light-gray;
        }

        &.assessment__result--answer-correct {
          color: $green;
        }
      }
    }
    &--footer {
      bottom: -55px;
      left: 50%;
      position: absolute;

      transform: translateX(-50%);
      width: 100%;

      @include grid-media($md) {
        bottom: -6vh;
        left: unset;
        right: -2.1vh;
        transform: unset;
      }
    }
  }

  &__endvideo {
    margin-top: -1vw;
    text-align: left;

    &--heading {
      h3 {
        color: $orange;
        font-size: 20px;
        font-weight: $font-bold;
        line-height: 24px;
        margin-bottom: 1vh;
      }
    }

    &--context {
      p {
        color: $white;
        font-size: 2.5vh;
        font-weight: $font-normal;
        line-height: 1.5;
        margin: 1vh 0;
      }

      border-bottom: 0.1vw solid $gray;
    }

    &--info {
    }
    &--duration,
    &--questions-count {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 1vh 0;

      p {
        color: $white;
        font-size: 2.5vh;
        font-weight: $font-bold;
        line-height: 1.5;
        margin: 0;
        // max-width: 7vw;
      }

      span {
        color: $orange;
        font-size: 2.5vh;
        font-weight: $font-normal;
        line-height: 1.5;
        text-align: right;
      }
    }

    &--submission {
      text-align: right;
    }
  }
}

.endVideoAssessment {
  .modal-popup__header {
    padding: 0;

    a {
      color: $white;
      right: -2vw;
    }
  }

  .modal-popup__footer {
    display: none;
  }
}

// .assessment__scrollbar {
//   @include grid-media($md) {
//     overflow: unset !important;
//   }
// }
