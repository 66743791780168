// Modal Popup
.btn-close {
  @include font(38, 10);
  color: $black;
  text-decoration: none;
  position: absolute;
  right: 3%;
  top: 0.2vw;
  width: 15px;

  @include grid-media($md) {
    width: 1.4vw;
  }

  &:hover {
    color: $dark-gray;
  }
}

.btn-bigscreen {
  top: 5vh;
}

.btn-zoomin {
  top: 11vh;
}

.btn-zoomout {
  top: 17vh;
}

.modal-popup {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
  &:before {
    content: '';
    background: $popup-black;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.7s 0.5s $cubic-transition;
    z-index: 10;
  }

  .btn-close {
    color: $white;
    right: -25px;

    @include grid-media($md) {
      right: -2vw;
    }
  }

  &__dialog {
    @include padding(25px);
    background: $dark-gray-3;
    text-align: center;
    position: fixed;
    box-shadow: 0 5px 10px rgba($black, 0.3);
    opacity: 0;
    transform: translateY(-150px);
    transition: all 0.5s 1s $cubic-transition;
    width: 80%;
    z-index: 11;

    @include grid-media($md) {
      width: 40%;
    }

    &.do-later-popup {
      .modal-popup__header {
        .btn-close {
          color: $white;
          right: -2vw;
        }
      }

      .assessment__endvideo--submission {
        display: flex;
        justify-content: space-around;
      }
    }

    &.welcome-video {
      background: none;
      display: flex;
      flex-direction: column;
      height: 70%;
      max-width: 90%;
      width: 100%;

      @include grid-media($md) {
        flex-direction: row;
      }

      .modal-popup__header {
        width: 100%;

        @include grid-media($md) {
          max-width: 30%;
        }

        h2 {
          color: $white;
          font-size: 45px;
          line-height: 1;
          padding-right: 5vh;
          text-align: left;
          // text-transform: uppercase;
          // word-break: break-all;

          @include grid-media($md) {
            font-size: 5vw;
            float: right;
            text-align: right;
            width: 30vw;
          }
        }

        .btn-close {
          right: 1%;
          top: 5%;
        }
      }

      .video-info__container {
        width: 100%;

        @include grid-media($md) {
          max-width: 69%;
        }

        .plyr--video {
          box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.360784313);
          width: 100%;
        }

        .plyr.plyr--full-ui.plyr--video.plyr--html5.plyr--pip-supported.plyr--fullscreen-enabled.plyr--playing {
          pointer-events: all !important;
        }
      }
    }

    &.statisticspopup {
      max-width: 55vw;
      padding: 0;
      width: 100%;

      h2 {
        font-size: 3vh;
      }

      .modal-popup__header {
        padding: 3vh 0;
      }

      .assessment__endvideo--submission {
        padding: 3vh;
      }
    }

    &.dateTimeReminder {
      width: 60%;

      @include grid-media($md) {
        width: 50%;
      }
    }

    &.pdf-modal {
      width: 95%;
    }
  }
  &.active {
    z-index: 9999;
    &:before {
      opacity: 1;
    }
    .modal-popup__dialog {
      transform: translateY(0);
      opacity: 0.9;
    }
  }
  &__header,
  &__body {
    @include padding(null null 25px);
  }
  &__header {
    h2 {
      @include font(26, 28);
      @include margin(0);

      color: $white;
      font-weight: $font-bold;

      @include grid-media($md) {
        @include font(30, 39);
      }
    }

    a {
      cursor: pointer;
    }
  }

  &__title {
    left: 0;
    position: absolute;
    top: -6vh;
  }

  &__pdfscreen {
    max-height: 70vh;
    overflow: auto;
  }
}

.temp_black_color {
  color: #000 !important;
}

.reminder {
  margin-top: 1vh;

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__calendar,
  &__clock {
    align-items: center;
    display: flex;
    justify-content: center;
    // max-width: 50%;
    // width: 100%;

    .form-control {
      display: none;
    }
  }

  &__calendar {
    .rdtDay {
      cursor: pointer;
      height: 2vw;
      width: 2vw;

      &.rdtToday,
      &.rdtActive {
        border-radius: 2vw;
        font-weight: bold;
      }

      &.rdtToday {
        border: 0.15vw solid $orange;
      }

      &.rdtActive {
        background: $orange;
        color: $white;
      }
    }

    .rdtDays {
      .dow {
        color: $orange;
      }
    }
  }

  &__clock {
    margin: 1vw 0;

    .rdtCounters {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: 1.2vw;

      .rdtCounter {
        margin-right: 2vw;
        position: relative;

        &:first-of-type {
          margin-right: 1vw;
        }

        &:last-of-type {
          margin: 0;
        }

        .rdtBtn {
          color: $orange;
          cursor: pointer;
          font-size: 0.5vw;
          right: -0.8vw;
          position: absolute;

          &:first-of-type {
            top: 0.2vw;
          }

          &:last-of-type {
            bottom: 0.2vw;
          }
        }

        .rdtCount {
          font-weight: bold;
        }
      }

      .rdtCounterSeparator {
        margin-right: 1vw;
      }
    }
  }

  &__footer {
    display: inline-block;
    margin-bottom: 1vw;
    width: 100%;
  }
}

.dateTimeReminder {
  .modal-popup__header {
    border-bottom: 0.1vh solid $gray;
    padding: 0;

    a {
      color: $white;
      right: -2vw;
    }

    h2 {
      color: $white;
      font-weight: $font-bold;
      text-align: center;
    }
  }

  .picky-date-time__calendar,
  .picky-date-time__clock {
    margin-bottom: 20px;

    @include grid-media($md) {
      margin-bottom: 40px;
    }
  }

  .picky-date-time {
    align-items: center;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;

    @include grid-media($md) {
      flex-direction: row;
    }
  }

  .picky-date-time__close {
    display: none;
  }

  .picky-date-time-calendar {
    &__today {
      bottom: -2.5vh;

      span {
        font-family: $font !important;

        &.picky-date-time-refresh {
          font-family: picky-date-time !important;
        }
      }

      &:hover {
        background: $orange;
        border: 0;
        color: $white;
      }
    }

    &__table {
      width: 100%;

      &-caption {
        .picky-date-time-calendar__table-cel.no-border.xs {
          font-size: 1.3vh;
          font-weight: bold;
        }
      }
    }

    &__table-cel,
    &__title span {
      font-family: $font !important;
    }

    &__title {
      &:hover {
        color: $orange;
      }
      span {
        font-size: 16px;
        font-weight: $font-bold;

        &:hover {
          color: $orange;
        }
      }
    }

    &__table-cel.xs {
      height: 2.3vw;
      width: 2.3vw;
    }

    &__content {
      .picky-date-time-calendar__body-container.xs {
        height: 20vh;

        @include grid-media($md) {
          height: 28vh;
        }
      }
    }

    &__sub-previous {
      .picky-date-time-keyboard_arrow_left {
        position: relative;

        &:before {
          background-image: url('../../../images/svg-images/icon-calendar-previous.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          content: '' !important;
          height: 0.7vw;
          left: -2.2vw;
          position: absolute;
          top: 50%;
          transform: translateY(-60%);
          width: 1.2vw;
        }
      }
    }

    &__next {
      .picky-date-time-keyboard_arrow_right {
        position: relative;

        &:before {
          background-image: url('../../../images/svg-images/icon-calendar-next.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          content: '' !important;
          height: 0.7vw;
          right: -2.2vw;
          position: absolute;
          top: 50%;
          transform: translateY(-60%);
          width: 1.2vw;
        }
      }
    }

    &__sub-next,
    &__previous {
      display: none;
    }

    &__header {
      .picky-date-time-calendar__title span {
        color: $white;
      }

      .picky-date-time__col {
        .picky-date-time-calendar__next,
        .picky-date-time-calendar__sub-previous {
          svg {
            fill: $white;
          }
        }
      }
    }

    &__body-container {
      .picky-date-time-calendar__date-item.xs {
        &.active {
          background: $orange !important;

          &.today {
            color: $white;
          }
        }

        &:hover {
          background: $orange !important;
        }
      }
    }
  }

  .picky-date-time-dropdown-calendar {
    &__month-item,
    &__year-item {
      .xs {
        color: $orange;
        font-family: $font;

        &:hover {
          background: $orange;
          color: $white;
        }
      }

      &.active {
        .xs {
          background: $orange;
          color: $white;
        }
      }
    }
  }

  .picky-date-time-clock {
    &__input {
      font-family: $font !important;
    }

    &__inputer-wrapper {
      margin-top: 6vh;

      .picky-date-time-clock__inputer {
        background: $white;
      }
    }

    &__circle {
      background: $white;
    }
  }
}

.pdfViewer {
  // bottom: 0;
  max-height: 80vh;
  position: relative;

  .modal-popup__header {
    a {
      color: $white;
      right: -2vw;

      &.closemodale {
        top: 0.5vh;
      }
    }
  }

  .react-pdf__Document {
    canvas {
      // width: 100% !important;
    }
  }

  .assessment__scrollbar {
    max-height: 70vh;
  }
}

.react-pdf__message {
  &.react-pdf__message--error {
    border-top: 0.1vw solid red;
    color: $red;
    font-weight: $font-bold;
    padding-top: 3vh;
  }
}
