.wrapperItem {
  flex-direction: row;
  display: flex;
  // justify-content: flex-end;
  // margin-top: 5vh;
  width: 100%;
  padding: 16px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 8px;
  width: 85%;
}

.progressBar {
  width: 100%;
  height: 15px;
  background-color: lightgray;
  margin-bottom: 8px;
  border-radius: 20px;
}

.progressBar > div {
  height: 15px;
  background-color: lightgreen;
  border-radius: 20px;
}

.leftSide > label {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.percentage {
  margin-left: 12px;
  text-align: center;
  width: 15%;
}
