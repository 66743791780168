.theatre {
  background: $black;
  color: $white;
  font-family: $font-family;
  display: flex;
  flex-direction: column;
  height: calc(100vh + 15vh);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;

  @include grid-media($md) {
    height: 100%;
  }

  &.mini {
    .theatre {
      &__player {
        &.smallScreen {
          margin-bottom: 50px;
          width: 100% !important;

          @include grid-media($md) {
            margin-bottom: 0;
            width: 78.5% !important;
          }

          .plyr {
            &__video-wrapper {
              max-width: 100% !important;
              // pointer-events: none;

              video {
                // pointer-events: none;
              }
            }

            &__controls {
              padding: 35px 10px 12px !important;
            }
          }
        }
      }

      &__controllers {
        width: 100%;
        // padding: 10px 0;
        padding: 0.5%;

        @include grid-media($md) {
          // width: 75%;
          margin: 0 auto;
          margin-top: -1.5vw;
          width: 75vw;
        }

        .btn--back {
          color: transparent;
          left: 3vh;
          height: 30px;
          position: absolute;
          top: -50vh;
          width: 30px;
          z-index: 9999;

          &:before {
            width: 100%;
          }

          @include grid-media($md) {
            height: 4vh;
            top: -89vh;
            width: 4vh;
          }
        }
      }

      &__info {
        @include grid-media($md) {
          width: 100%;
        }
      }

      &__info {
        width: 100%;

        @include grid-media($md) {
          position: absolute;
          top: 0;
          right: 0;
          width: 20%;
        }

        &-contact {
          // .theatre__info_scroll {
          //     .scroll-content {
          //         .theatre__info_scroll--content {
          //             transition: all 1.5s linear;
          //         }
          //     }
          // }
          .ScrollbarsCustom {
            &-Content {
              transition: all 1.5s linear;
            }
          }
        }
      }
    }

    .admin-theater--icons {
      background: #00000099;
      bottom: 10vh;
      display: none !important;
      flex-direction: row;
      padding: 1vw 1.2vw;
      position: absolute;

      img {
        height: 4vh;
      }

      hr {
        background: $gray-light;
        border: 0;
        height: auto;
        margin: 0 1.5vw;
        width: 0.1vw;
      }
    }
  }

  &__player {
    position: relative;

    &_playTime {
      bottom: 5vh;
      color: rgba($white, 0.5);
      // color: $orange;
      display: flex;
      font-size: 10px;
      font-weight: $font-thin;
      // justify-content: space-between;
      // left: 2.5vh;
      margin: 0 1vh;
      // position: absolute;
      // width: calc(100% - 2vw);

      @include grid-media($md) {
        font-size: 0.9vw;
      }

      span {
        margin: 0 0.5vh;
      }
    }

    .plyr {
      &--video {
        background: $dark-gray-1;
      }

      &--menu-open {
        .plyr__menu__container {
          background: $black;

          &::after {
            border-top-color: $black;
          }

          div {
            div {
              &:nth-child(2) {
                [role='menu'] {
                  button {
                    &:hover {
                      background: $black;
                      color: $red;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.smallScreen {
      .plyr {
        &__video-wrapper {
          max-width: 150vh;
          margin: 0 auto;
        }

        &__controls {
          padding: 35px 10px 0px !important;
        }

        &--full-ui {
          input[type='range'] {
            height: 14px;
          }
        }

        [data-plyr='fullscreen'],
        [data-plyr='pip'],
        [data-plyr='captions'],
        [data-plyr='play'],
        .plyr__time,
        .plyr__volume,
        .plyr__menu {
          display: none;
        }
      }
    }

    &.fullScreen {
      .theatre__video-player {
        position: relative;

        &:hover {
          .theatre__controllers {
            // display: flex;
          }
        }

        .theatre__controllers {
          background: #0009;
          display: none;
          position: absolute;
          width: 100%;
          bottom: 0;
          z-index: 99999999;
        }
      }
    }
  }

  &__video-player {
    height: 60vh;
    z-index: 1;

    @include grid-media($md) {
      height: 92vh;
    }

    & > div {
      overflow: unset !important;
    }
  }

  &__controllers {
    @include padding(12px 10px);
    bottom: -40px;
    display: flex;
    // height: 50px;
    // height: 4.5vh;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: absolute;
    z-index: 9999;

    @include grid-media($md) {
      bottom: unset;
      position: relative;
    }

    &--item {
      height: 100%;
      max-width: 4%;
      text-align: center;
      width: 100%;
      z-index: 10;

      svg {
        height: 100%;
        width: 100%;
      }

      img,
      svg {
        cursor: pointer;
        height: 12px;
        width: 100%;
        // max-height: 55%;

        @include grid-media($md) {
          height: 2.5vh;
        }
      }
    }

    &_play {
      width: 5%;
      display: flex;
      justify-content: center;
      cursor: pointer;
      min-width: 30px;

      div {
        // width: 30px;
        // height: 30px;
        svg {
          // stroke: rgba($white, .5);
          stroke: $white;

          path {
            fill: $white;
          }
        }

        &:hover {
          svg {
            stroke: $orange;
          }
        }
      }
    }

    &_title {
      align-items: center;
      color: $white;
      display: none;
      font-size: 13px;
      font-weight: $font-semibold;
      margin-left: 10px;
      max-width: 35%;
      // padding-left: 2%;
      width: 80%;
      text-align: left;

      @include grid-media($md) {
        display: flex;
        font-size: 0.8vw;
        max-width: 40%;
      }

      span {
        color: $gray-light;
      }
    }

    &_quality {
      cursor: pointer;
      position: relative;
      width: 5%;
      display: flex;
      justify-content: center;
      min-width: 30px;

      .show {
        display: block;
      }

      .hide {
        display: none;
      }

      .svg {
        // width: 30px;
        // height: 30px;
        svg {
          // color: rgba($white, .5);
          color: $white;
        }

        &:hover {
          svg {
            // color: $orange;
            color: inherit;
          }
        }
      }
    }

    &_menu {
      position: absolute;
      width: auto;
      display: block;
      bottom: 5vh;
      background: $black;

      &:before {
        top: 88%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(0, 0, 0, 0);
        border-top-color: #000000;
        border-width: 19px;
        margin-left: -19px;
      }

      ul {
        margin: 0;
        padding: 10px;
        display: block;

        li {
          color: rgba($white, 0.5);
          font-size: 14px;
          margin-bottom: 5px;
          transition: color 0.4s $cubic-transition-2;

          &:hover {
            color: $red;
          }
        }
      }
    }

    &_maxScreen {
      cursor: pointer;
      position: relative;
      width: 5%;
      display: none;
      justify-content: center;
      min-width: 30px;

      @include grid-media($md) {
        display: flex;
      }

      div {
        width: 30px;
        height: 30px;

        svg {
          color: rgba($white, 0.5);
        }

        &:hover {
          svg {
            color: $red;
          }
        }
      }
    }

    &_fullScreen {
      cursor: pointer;

      div {
        // width: 30px;
        // height: 30px;
        svg {
          color: rgba($white, 0.5);
        }

        &:hover {
          svg {
            color: $red;
          }
        }
      }
    }

    &_info {
      display: flex;
    }

    &_volume {
      @include padding(0 10px 0 10px);
      align-items: center;
      display: flex;
      justify-content: center;
      max-width: 9%;
      position: relative;

      .slidecontainer {
        width: 100%;

        .slider {
          border-radius: 5px;
          background: #d3d3d3;
          height: 0.3vh;
          left: 50%;
          opacity: 0.9;
          outline: none;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: opacity 0.2s;
          width: 80%;
          -webkit-transition: 0.2s;
          -webkit-appearance: none;
        }

        .slider::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 1.5vh;
          height: 1.5vh;
          border-radius: 50%;
          background: $orange;
          cursor: pointer;
        }

        /** FF*/
        .slider::-moz-range-progress {
          background-color: #43e5f7;
        }

        .slider::-moz-range-track {
          background-color: #9a905d;
        }

        /* IE*/
        .slider::-ms-fill-lower {
          background-color: #43e5f7;
        }

        .slider::-ms-fill-upper {
          background-color: #9a905d;
        }
      }
    }

    &_next {
      &:hover {
        .theatre__controllers_next-hover {
          display: block;
        }
      }
    }

    &_next-hover {
      display: none;
      background: $orange;
      height: 7vw;
      max-width: 100%;
      position: absolute;
      padding: 0.5vw;
      right: 5.5vw;
      top: -16.5vh;
      width: 10vw;
      z-index: 15;

      &:before {
        content: '';
        position: absolute;
        border-left: 1.5vw solid transparent;
        border-right: 1.5vw solid transparent;
        border-top: 1.5vw solid $orange;
        bottom: -2.2vh;
        left: 50%;
        transform: translateX(-50%);
      }

      &--thumbnail {
        // background-image: url(https://media-cdn.tripadvisor.com/media/photo-s/12/c1/99/52/not-a-traditional-tea.jpg);
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
      }
    }
  }

  &__info-contact {
    width: 100%;

    @include grid-media($md) {
      display: flex;
      height: auto;
      //height: 27.8vh;
      margin-top: auto;
      width: inherit;
    }
  }

  &__contact {
    // @include padding(10px);
    background: $dark-gray;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include grid-media($md) {
      width: 100%;
    }

    &_data {
      display: none;

      &.active {
        display: block;
      }

      > .info {
        @include padding(10px);

        a {
          @include padding(10px);
          color: $white;
          text-decoration: none;
        }
      }
    }

    &_title {
      display: flex;
    }

    &--item {
      //height: calc(100% / 3);
      width: calc(100% / 3);
      display: flex;
      align-items: center;
      transition: background 0.4s $cubic-transition-2;
      justify-content: center;
      cursor: pointer;

      .icon {
        @include margin(0 10px 0 10px);

        div {
          width: 40px;
          height: 40px;
          display: flex;

          @include grid-media($md) {
            width: 50px;
            height: 50px;
          }

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .info {
        color: rgba($white, 0.5);
        transition: color 0.4s $cubic-transition-2;
        text-transform: uppercase;
        font-weight: $font-bold;
        font-size: 14px;
        display: none;

        span {
          font-size: 18px;
        }

        @include grid-media($md) {
          font-size: 17px;

          span {
            font-size: 22px;
          }
        }
      }

      &:hover {
        background: $black;

        .info {
          color: $white;
        }
      }
    }
  }

  &__info {
    width: 100%;

    @include grid-media($md) {
      width: 80%;
    }

    // &_scroll {
    .ScrollbarsCustom {
      @include padding(0 1.5vw);
      // height: 300px;
      // overflow-y: auto;
      height: calc(100vh - 18vh) !important;

      &-Scroller {
        padding: 0 1.5vw;
        scroll-behavior: smooth;
        transition: all 2s linear;
      }
    }

    &--item {
      @include margin(20px null 40px);

      border-bottom: 0.01vw solid $gray-light;
      cursor: pointer;
      // font-size: 1.05vw;
      font-size: 12px;
      font-weight: $font-thin;
      line-height: 1.4;
      padding-bottom: 4vh;
    }

    &--timing {
      color: $gray-light;
      display: inline-block;
      margin-top: 4.7vh;
      position: relative;
      width: 100%;

      &:before {
        background: url($summary-play);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 12px;
        position: absolute;
        left: 12%;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;

        @include grid-media($md) {
          height: 2vw;
          left: 6vw;
          width: 2vw;
        }
      }
    }

    &_tab {
      display: flex;
      flex-direction: column;
    }

    &_tab-title {
      // @include padding (null null null 15px);
      align-items: center;
      // background: $dark-gray;
      border-radius: 40vw;
      cursor: pointer;
      display: flex;
      font-size: 12px;
      font-weight: bold;
      justify-content: center;
      height: 5vh;
      padding: 1vw;
      width: 40%;

      @include grid-media($md) {
        font-size: 1.1vw;
        font-weight: 300;
      }

      &.active {
        background: $orange;
      }

      &_wrapper {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        height: 8.5vh;
      }
    }

    &_tab_data {
      display: none;

      &.active {
        display: block;

        .theatre__info_scroll {
          height: calc(100vh - 18vh) !important;
          padding-left: 1vw;
        }
      }
    }

    &__message {
      &-input {
        @include padding(10px);

        input {
          width: 100%;
          background-color: $dark-gray-2;
          height: 30px;
          outline: none;
        }
      }

      &_item {
        @include margin(2vh 1vh);
        @include padding(1.1vh 2.5vh);

        align-items: center;
        background-color: $dark-gray-2;
        border-radius: 2vh;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        &.user {
          border-radius: 0 15px 15px 0;
        }

        &.sender {
          border-radius: 15px 0 0 15px;
          text-align: right;
        }

        &_time {
          @include margin(5px null 5px);
          font-size: 12px;
        }

        p {
          padding: 0 1vw;
          text-align: left;
          width: 100%;
        }

        .icon--download {
          cursor: pointer;
          width: 2vh;
        }
      }
    }
  }

  &__tutor-info {
    @include margin(10vh null 5vh);
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: 4.5vh;
    width: 75%;

    &_image {
      display: none;

      div {
        @include margin(nul 10px null);
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
    }

    &_name {
      width: 100%;
    }

    &_description {
      margin-top: 1vh;
      width: 100%;
    }
  }
}

.theater-page {
  .footer {
    display: none;
  }
}

.middle-video-reel {
  position: relative;

  .theatre__assessment-pagination {
    right: 1.3vh;
    top: 50px;

    @include grid-media($md) {
      top: 2px;
    }
  }

  .slick-slider {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.MuiGrid-root.MuiGrid-container {
  .MuiSlider-root.MuiSlider-colorPrimary {
    color: $orange;
    height: 4px;

    .MuiSlider-track {
      height: 4px;
    }
  }
}
