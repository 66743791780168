.assessment {
  align-items: center;
  display: flex;
  flex-direction: column;
  background: $black;
  left: 0;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;

  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  &:before {
    background-color: rgba(0, 0, 0, 0.8);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  .container {
    padding: 0;
    position: unset !important;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
  }

  &__questions {
    &--wrapper {
      display: flex;
      flex-direction: column;
      // height: 85%;

      @include grid-media($md) {
        flex-direction: row;
        height: 100%;
      }
    }
    &-column {
      background: $dark-gray;
      width: 100%;

      @include grid-media($md) {
        max-width: 25%;
      }
    }
    &--counter {
      text-align: right;

      p {
        font-size: 18px;
      }
    }
    &--list {
      max-height: 360px;
      overflow-y: auto;

      @include grid-media($md) {
        height: auto;
      }

      h5 {
        margin: 0;
      }

      p {
        color: $gray;
        // margin-bottom: 0;
      }
    }
  }

  &__question {
    cursor: pointer;
    padding: 5% 6%;
    position: relative;
    border-bottom: 1px solid $dark-gray-3;

    &-column {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 80px;
      position: relative;
      width: 100%;

      @include grid-media($md) {
        max-width: 75%;
        padding-top: 0;
      }

      &--number,
      &--question,
      &--answer {
        max-width: 90%;
        width: 100%;
      }

      &--number {
        display: flex;
        flex-direction: column;

        @include grid-media($md) {
          flex-direction: row;
        }

        &.oriented {
          h4 {
            font-size: 24px;
            margin-bottom: 2vh;
            margin-top: 0;
            max-width: 100%;
            width: 100%;

            @include grid-media($md) {
              font-size: 1.5vw;
            }
          }

          h3 {
            font-size: 20px;
            margin-bottom: 5vh;
            margin-top: 0;

            @include grid-media($md) {
              font-size: 1.5vw;
            }
          }

          .assessment__question-column--question_img {
            max-width: 100%;

            @include grid-media($md) {
              margin-right: 5vh;
              max-width: 45%;
            }

            img {
              // height: 100vh;
              max-height: 100%;
            }
          }

          &.orientation-1 {
            .assessment__question-column--question_img {
              @include grid-media($md) {
                margin-right: 5vh;
              }
            }
          }

          &.orientation-2 {
            flex-direction: row-reverse;

            .assessment__question-column--question {
              margin-right: 5vh;
            }
          }

          &.orientation-3 {
          }
        }
      }

      &--timer {
        color: $white;
        font-family: $font;
        font-size: 4.5vh;
        font-weight: $font-normal;
        position: absolute;
        right: 5%;
        top: 5%;
      }
    }

    &:before {
      background-color: $orange;
      content: '';
      position: absolute;
      width: 1.5%;
      height: 0;
      top: 0;
      left: 0;
      transition: height 0.25s ease-in;
    }

    &.selected {
      background-color: $dark-gray;
      position: relative;

      &:before {
        height: 100%;
      }
    }

    &--result {
      left: 95px;
      position: absolute;
      top: 20px;

      @include grid-media($md) {
        left: 6.5vw;
        top: 2.3vh;
      }

      &-correct,
      &-incorrect,
      p {
        color: #fff0;
        margin: 0;
        padding-left: 5%;
        position: relative;
        height: 100%;

        &:before {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          content: '';
          position: absolute;
          // width: 4%;
          width: 20px;
          height: 100%;
          top: 50%;
          transform: translateY(-50%);
          left: 0;

          @include grid-media($md) {
            width: 1.5vw;
          }
        }
      }

      &-correct {
        &:before {
          background-image: url('../../../images/green-tick.svg');
        }
      }
      &-incorrect {
        &:before {
          background-image: url('../../../images/red-cross.svg');
        }
      }
    }
  }

  &__scrollbar {
    max-height: 100%;
    overflow: unset !important;
  }

  .form {
    &__form {
      &--fields {
        margin-bottom: 4vh;
        padding-left: 5%;

        label {
          font-size: 18px;

          @include grid-media($md) {
            font-size: 1.5vw;
          }
        }
      }
    }

    &--buttons {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      height: 15%;
      margin-top: 20px;
      position: relative;
      text-align: right;

      @include grid-media($md) {
        align-items: flex-end;
        margin-top: 0;
      }

      .btn {
        height: 6vh;
        margin-bottom: 2%;
      }

      .btn--primary {
        margin-right: 4%;
        width: 10%;
      }

      .btn--secondary {
        margin-right: 2%;
        width: calc(25% - 4%);
        z-index: 1;
      }

      .assessment--btn {
        background: $dark-gray;
        height: 100%;
        position: absolute;
        width: 25%;
      }
    }
  }

  &__start {
    max-width: 90vw;
    margin: 0 auto;
    width: 100%;

    @include grid-media($md) {
      max-width: 75vw;
    }

    &--heading {
      h2 {
        font-size: 24px;
        font-weight: $font-bold;

        @include grid-media($md) {
          font-size: 3vw;
        }
      }
    }

    &--context {
      p {
        font-size: 1.2vw;
        font-weight: $font-thin;
        line-height: 1.5;
      }
    }

    &--info {
      margin: 4vh 0;
    }

    &--duration,
    &--questions-count {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 1vh 0;

      p {
        color: $white;
        font-size: 2.5vh;
        line-height: 1.5;
        margin: 0;
      }

      span {
        color: $white;
        font-size: 2.5vh;
        font-weight: $font-bold;
        line-height: 1.5;
        text-align: right;
      }
    }

    &--related-content {
      h4 {
        color: $orange;
        font-size: 3vh;
        font-weight: $font-bold;
      }
    }
    &--reel {
      display: flex;
      flex-direction: row;
      // justify-content: space-between;

      &-tile {
        cursor: pointer;
        display: flex;

        flex-direction: column;
        margin-right: 1%;
        width: 50%;

        @include grid-media($md) {
          width: 24%;
        }

        &:last-of-type {
          margin-right: 0;
        }

        img {
          height: 150px;

          @include grid-media($md) {
            height: 23vh;
          }
        }

        p {
          font-size: 2vh;
          font-weight: $font-thinner;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &--submission {
      margin-top: 3vh;
      text-align: right;

      .btn--secondary {
        margin-right: 1vw;
      }
    }
  }
}

.assessment-page {
  .header__nav-item.user .user_avatar .userdropdown--settings ul li {
    &:hover {
      a {
        color: $orange;
      }
    }

    a {
      pointer-events: none;
    }
  }
}
