.training-summary {
  &--wrapper {
    display: flex;
    flex-direction: column;
  }	

  &--row {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__scrollbar {
    max-height: 60vh;
    margin-top: 3vh;
  }

  &--page {
    &-title {
      h2 {
        font-size: 2.8vw;
        font-weight: $font-bold;
        margin: 0;
        max-width: 30%;
        width: 100%;
      }
    }

    &-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-column {
      max-width: 30%;
      width: 100%;

      &--title {
        padding: 3vh;
        padding-bottom: 0;
        border-bottom: 1px solid $white;

        h3 {
          margin-top: 0;
        }
      }

      &--content {
        padding: 3vh;
        padding-top: 0;

        svg {
          margin: auto;
          margin-top: 3vh;
          width: 85%
        }
      }

      &--description {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 3vh;

        &-text {
          max-width: 50%;
          padding-left: 2vw;
          position: relative;
          text-align: left;
          width: 100%;

          &:before {
            content: "";
            left: 1%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 1vw;
            height: 1vw;
          }

          &.Extremely.Satisfied {
            &:before {
              background: #001489;
            }
          }

          &.Satisfied {
            &:before {
              background: #7D87C3;
            }
          }

          &.Neutral,
          &.Yes {
            &:before {
              background: #3343A1;
            }
          }

          &.Unsatisfied {
            &:before {
              background: #BDC1E1;
            }
          }

          &.Extremely.Unsatisfied,
          &.No {
            &:before {
              background: #00062D;
            }
          }            
        }
      }
    }
  }

  &--comment {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // padding-top: 3vh;
    margin-bottom: 3vh;

    &-image {
      border-radius: 50%;
      height: 5.5vh;
      max-width: 10%;
      width: 100%;
    }

    &-name {
      max-width: 90%;
      padding-left: 2vh;
      width: 100%; 

      h5 {
        font-size: 1.1vw;
        font-weight: $font-bold;
        margin: 0;
      }

      span {
        font-size: 1vw;
        font-weight: $font-thin;
      }
    }

    &-body {
      border-bottom: 1px solid $white;
      max-width: 100%;
      padding-bottom: 1vh;
      width: 100%;  
    }
  }

  &--feedback {
    background: $dark-gray-1;
    cursor: pointer;
    margin-bottom: 3vh;
    padding: 2vh;

    &.selected,
    &:hover {
      background: $orange;
    }

    h5 {
      font-size: 1vw;
      margin: 0;
    }
  }

  &--training-id {
    p {
      margin-top: -4vh;
    }

    .Dropdown-root {
      margin-bottom: 0 !important;
    }
  }

  &--download {
    .btn--downloadpdf {
      font-size: 1vw;
      padding-left: 15%;
      position: relative;
      width: 100%;
      min-width: 130px;

      &:before {
        background: url('../../../images/svg-images/icon-pdf-white.svg');
        background-size: contain;
        background-repeat: no-repeat;
        content: "";
        font-size: 2vw;
        font-weight: $font-thin;
        left: 10%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 2.8vh;
        height: 2.8vh;
      }
    }
  }

  &--training-id,
  &--download {
    max-width: 11%;
    width: 100%;
  }

  &--budget {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 40%;
    position: relative;
    width: 100%;

    .progress {
      width: 70%;
      position: relative;

      .progress-bar {
        position: relative;
        color: transparent;

        &:before,
        &:after,
        .totalSpent {
          content: "";
          position: absolute;
          font-size: 0.7vw;
          color: $white;
        }

        &:before {
          content: "Total Spent";
          right: -1.5vw;
          top: -1.5vw;
        }

        &:after,
        .totalSpent {
          bottom: -1.5vw;
          right: -0.8vw;
        }
      }
      .totalSpent {
        content: "";
        position: absolute;
        font-size: 0.7vw;
        color: $white;
        bottom: -1.5vw;
        text-align: right;
        left: 1vw;
      }
    }
  }

  .btn--back {
    font-size: 1vw;
  }
}

.progress,
.progress-bar {
    display: flex;
    // overflow: hidden;
}

.progress {
  height: 1rem;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: 1vw;
}

.progress-bar {
  border-radius: 1vw 0 0 1vw;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: $orange;
  transition: width .6s ease;
}