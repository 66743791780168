body {
  #root {
    .Dropdown-root {
      margin-bottom: 5vh;
      position: relative;
      user-select: none;
      width: 100%;

      .Dropdown-control {
        background-color: $dark-gray-2;
        border: 0;
        display: flex;
        height: 5.5vh;
        min-width: 7vw;
        padding: 1vh;
        padding-right: 4vh;
        position: relative;
        // z-index: 2;

        &:before {
          background-image: url('../../../images/svg-images/reel-left-arrow.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          content: '';
          height: 30%;
          right: 2%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(-90deg);
          width: 12px;

          @include grid-media($md) {
            width: 1.2vw;
          }
        }
      }

      .Dropdown-placeholder.is-selected,
      .Dropdown-placeholder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1.5;
        font-size: 14px;
        border: none;
        border-radius: 0;
        cursor: default;
        color: $gray;
        position: relative;

        @include grid-media($md) {
          font-size: 1vw;
        }
      }

      .Dropdown-arrow {
        display: none;
      }

      .Dropdown-menu {
        background-color: $dark-gray-2;
        border: 0;
        color: $white;
        font-size: 14px;
        // margin-top: -1.5vw;
        // padding-top: 5vh;
        // z-index: 1;

        @include grid-media($md) {
          font-size: 1vw;
        }

        &::-webkit-scrollbar {
          // width: 1em;
          width: 1vh;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          // margin-top: 2vw;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $orange;
          outline: 1px solid $dark-gray-2;
        }
      }

      .Dropdown-option.is-selected,
      .Dropdown-option:hover {
        background-color: $orange;
        color: $white;
      }

      .Dropdown-option {
        color: $white;
      }
    }
  }

  .light {
    .Dropdown-root {
      .Dropdown-option.is-selected,
      .Dropdown-option:hover,
      .Dropdown-option {
        color: $gray !important;
      }
    }
  }

  .multi-select {
    margin-right: 2vh;
    min-width: 10vw;

    .dropdown {
      &-container {
      }
      &-heading {
        height: 5vh;

        &:before {
          // right: 2.6% !important;
          // width: 0.5vw !important;
        }

        &-value {
          span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 1.5;
            font-size: 1vw;
            border: none;
            border-radius: 0;
            cursor: default;
            color: $gray;
            position: relative;
          }
        }
        &-dropdown-arrow {
        }
      }
    }

    .panel-content {
      input[type='search'] {
        padding-left: 2vh !important;
      }
    }
  }

  .multi-level-selector-container {
    .multi-selector {
      &-container {
        background: $dark-gray-2;
        border: 0;
        font-family: $font;
      }

      &-placeholder {
        color: $white !important;
        font-size: 1vw;
        font-weight: $font-bold;
      }
    }

    .options-selected-container {
      border: 0;
      border-radius: unse !important;
      background: $dark-gray-2 !important;

      div {
        .options-group {
          color: $white !important;
        }
      }
    }

    .multi-level-options-container {
      &.menu-open {
        background: $dark-gray-1;
        border: 0;
        border-radius: 0;
        box-shadow: unset;
        position: relative;

        .options-container {
          background-color: $dark-gray-2 !important;
          flex-wrap: wrap;
          padding-left: 0;
          padding-right: 0;

          .options-label {
            padding-left: 2vh;
          }

          &:hover {
            padding-bottom: 0;

            .options-label {
              // padding-bottom: 2vh;
            }

            & > .arrow-right {
              transform: rotate(225deg);
            }
          }

          &:first-of-type {
            &:hover {
              .options-label {
                padding-bottom: 2vh;
              }

              .options-container {
                .options-label {
                  padding-bottom: 0;
                }
              }
            }
          }

          .arrow-right {
            border: solid $white;
            border-left: 0 !important;
            border-width: 0 0.2vh 0.2vh 0;
            height: 0.7vw;
            margin-right: 1vh;
            position: absolute;
            right: 2vh;
            top: 2vh;
            transform: rotate(45deg);
            width: 0.7vw;
          }

          .options-sub-menu-header {
            display: none;
          }

          .options-sub-menu-container {
            background: unset;
            border: 0;
            border-radius: 0;
            box-shadow: unset;
            position: relative;
            transform: unset;
            width: 100%;

            .options-container {
              background: $dark-gray-3 !important;

              .options-sub-menu-container {
                .options-sub-menu {
                  .options-label {
                    padding-bottom: 0;
                  }
                }

                label {
                  background: $dark-gray-3;
                }
              }
            }

            // & > .options-container {
            //   padding: 0;
            // }

            .options-sub-menu {
              &:hover {
                background-color: unset;

                .checkbox {
                  .checkmark {
                    background: unset;
                  }
                }
              }

              .checkbox {
                .checkmark {
                  background: unset;
                  border-radius: 0;
                  height: 1vw;
                  width: 1vw;
                }
              }

              input:checked ~ .checkbox .checkmark {
                background: $orange;
                border: 0;

                &:after {
                  border-width: 0 0.2vh 0.2vh 0;
                  height: 0.7vw;
                  left: 50%;
                  top: 0;
                  transform: translateX(-50%) rotate(45deg);
                  width: 0.2vw;
                }
              }
            }

            label {
              background: $dark-gray-2;
              margin: 0;
              width: 100%;

              &:first-of-type {
                // margin-top: 2vh;
              }
            }
          }
        }

        .options-container {
          .options-sub-menu-container {
            .options-container {
              padding-left: 10%;
            }
          }
        }
      }
    }
  }
}
