/**
* Hero Component
*/

.hero {
  display: flex;
  align-items: center;
  padding-top: 34%;

  @include grid-media($md) {
    min-height: 55vh;
    padding-top: 0 !important;
  }

  .container {
    font-family: $font-family;
    max-width: 300px;

    @include grid-media($md) {
      padding: 0;
      max-width: 700px;
      margin-left: 130px;
    }

    @include grid-media($md) {
      width: 50%;
    }
  }

  &__title {
    margin: 0;
    color: $white;
  }

  &__sub-title {
    color: #8e8e8e;
  }
}
