.policy {
  &__reel {
    padding-top: 4vh;
    position: relative;

    &:hover {
      .policy__icons {
        // display: flex;
      }
    }
  }

  &__tile {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 45vh;
    margin-right: 0.2vw;
    position: relative;

    &:before {
      background-color: #242424;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      opacity: 0.89;
      top: 0;
      width: 100%;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      left: 50%;
      position: absolute;
      top: 50%;
      text-transform: uppercase;
      text-align: center;
      transform: translate(-50%, -100%);

      @include grid-media($md) {
        font-size: 1vw;
      }
    }
  }

  &__icons {
    background: black;
    display: none;
    bottom: -1px;
    flex-direction: row;
    justify-content: space-between;
    left: 0;
    opacity: 0.7;
    pointer-events: auto !important;
    position: absolute;
    padding: 2vh;
    width: calc(100% - 0.2vw);
    z-index: 10;

    img {
      height: 2.5vh;
    }
  }
}

.admin-policy--icon {
  align-items: center;
  display: none;
  flex-direction: row;
  position: absolute;
  right: 5.5vh;
  top: -8vh;

  .icon--pdf {
    background-image: url($icon-pdf-fill-invert);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    margin-right: 1vh;
    width: 15px;
  }

  p {
    cursor: pointer;
    font-size: 2vh;
    font-weight: 400;
    display: inline-block;
    margin: 0;
  }
}
