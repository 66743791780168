/**
* Header
*/

.header {
  position: fixed;
  width: 100%;
  z-index: 15;
  top: 0;
  height: 12vh;
  ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 40vh;
    margin: 0;
    padding: 0;
    width: 100%;
    @include grid-media($md) {
      flex-direction: row;
      height: unset;
    }
  }
  &.background {
    background-color: $black;
    transition: background-color 1s ease;
  }
  &__icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    @include grid-media($md) {
      justify-content: space-between;
    }
  }
  &__mobile-menu,
  &__search-icon {
    @include grid-media($md) {
      display: none;
    }
  }
  &__mobile-menu {
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    height: 25px;
    width: 25px;
    svg {
      fill: $white;
      height: 100%;
      width: 100%;
    }
  }
  &__search-icon {
    width: 36px;
    height: 36px;
    svg {
      fill: $white;
      height: 100%;
      width: 100%;
    }
  }
  @include grid-media($md) {
    display: flex;
  }
  .container {
    display: flex;
    flex-direction: row-reverse;
    @include grid-media($md) {
      background: #000;
      padding-top: 0;
      padding-bottom: 0;
      width: 100%;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    justify-content: center;
    @include grid-media($md) {
      align-items: center;
      justify-content: space-between;
    }
  }
  &__nav {
    display: none;
    @include grid-media($md) {
      align-items: center;
      display: flex;
      height: 100%;
      max-width: 50%;
      width: 100%;
      ul {
        justify-content: space-evenly;
        .Dropdown-root {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  &__switch {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 14%;
    width: 100%;
    .switch {
      position: relative;
      display: inline-block;
      width: 35px;
      height: 20px;
    }
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      height: 1.2vw;
      transition: 0.4s;
      width: 2.1vw;
    }
    .slider:before {
      position: absolute;
      content: '';
      height: 10px;
      width: 10px;
      left: -4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      top: 50%;
      transform: translate(8px, -50%);
      @include grid-media($md) {
        height: 1vw;
        left: 0;
        transform: translate(0.6vh, -50%);
        width: 1vw;
      }
    }
    input:checked + .slider {
      background-color: $orange;
    }
    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }
    input:checked + .slider:before {
      -webkit-transform: translate(3.5vh, -50%);
      -ms-transform: translate(3.5vh, -50%);
      transform: translate(1.7vh, -50%);
    }
    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
  }
  .notification {
    position: relative;
    &__icon {
      height: 40px;
      padding-right: 22%;
      width: 40px;
      @include grid-media($md) {
        height: 3.4vh;
        width: 3.6vw;
      }
    }
    &__count {
      background: $orange;
      border-radius: 100%;
      font-size: 16px;
      height: 25px;
      left: 30px;
      font-weight: bold;
      line-height: 24px;
      position: absolute;
      text-align: center;
      top: -10px;
      width: 25px;
      @include grid-media($md) {
        font-size: 10px;
        height: 18px;
        left: 13px;
        line-height: 18px;
        top: -5px;
        width: 18px;
      }
      @include grid-media($xl) {
        height: 20px;
        left: 25px;
        line-height: 19px;
        width: 20px;
      }
    }
    &__dropdown {
      .dropdown {
        &:hover {
          margin: 0;
        }
        &-content {
          background-color: $dark-gray-2;
          opacity: 0.9;
          min-width: 325px;
          right: 2vw;
          top: 2.5vw;
          width: 100%;
          &.open {
            display: block;
          }
          ul {
            flex-direction: column;
            li {
              border-bottom: 0.1vw solid $gray;
              cursor: pointer;
              padding: 1vw 0;
              position: relative;
              width: 25vw;
              &:hover {
                background-color: #e4e4e4;
                p,
                span {
                  color: $black;
                }
              }
              &:last-of-type {
                border-bottom: 0;
              }
              img {
                width: 2.5vw;
              }
              p {
                color: $black;
                font-size: 2.2vh;
                font-weight: 500;
                padding-left: 3vw;
                padding-right: 7vw;
                position: relative;
                margin: 0;
                width: 100%;
                &.unread {
                  &:before {
                    content: '';
                    background: $red;
                    border-radius: 5vw;
                    height: 1.5vh;
                    left: 1.5vw;
                    position: absolute;
                    top: 25%;
                    width: 1.5vh;
                  }
                }
                &.mandatory {
                  &:after {
                    background-color: $red;
                    border-radius: 2vw;
                    content: 'Mandatory!';
                    color: $white;
                    font-size: 0.8vw;
                    position: absolute;
                    top: 0;
                    right: 0.5vw;
                    padding: 0.6vh 1.5vh;
                  }
                }
                span {
                  display: none;
                  &.type {
                    background-color: $red;
                    border-radius: 2vw;
                    color: $white;
                    display: block;
                    font-size: 0.8vw;
                    margin-top: 0;
                    padding: 0.6vh 1.5vh;
                    position: absolute;
                    right: 0.5vw;
                    top: -0.3vh;
                  }
                }
              }
              span {
                color: $gray;
                display: inline-block;
                font-size: 1.6vh;
                font-weight: $font-thin;
                padding-left: 3vw;
                padding-right: 1vw;
                margin-top: 2vh;
              }
            }
          }
        }
        &__header {
          flex-direction: column;
          .titles {
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
            p {
              color: $white;
              font-size: 18px;
              font-weight: $font-bold;
            }
            span {
              align-items: center;
              display: flex;
              font-size: 14px;
            }
            .icon--settings {
              background-image: url($icon-settings);
              height: 10px;
              margin-left: 10px;
              width: 10px;
            }
          }
          .user-selection {
            display: flex;
            margin-top: 20px;
            width: 100%;
            a {
              background: none;
              border-bottom: 0;
              border-radius: 35px;
              color: $white;
              font-size: 18px;
              margin-right: 10px;
              padding: 15px 35px;
              &.active,
              &:hover {
                background-color: $orange;
              }
            }
          }
        }
        &__body {
          height: 42.5vh;
          overflow-x: scroll;
          .notification--alert {
            border-bottom: 1px solid $dark-gray-1;
            padding: 25px 45px;
            p {
              font-size: 18px;
              margin-bottom: 15px;
              margin-top: 0;
            }
            span {
              color: $light-gray;
              font-size: 14px;
            }
            &:last-of-type {
              border: none;
            }
            &:hover {
              background: $dark-gray-3;
            }
            &.new {
              p {
                position: relative;
                &:before {
                  background: $red;
                  border-radius: 100%;
                  content: '';
                  height: 12px;
                  left: -25px;
                  position: absolute;
                  top: 4px;
                  width: 12px;
                }
              }
            }
          }
        }
        .view-all {
          color: $orange;
          background: none;
          border: none;
          text-align: center;
        }
      }
    }
  }
  &__search {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    max-width: 30%;
    width: 100%;
  }
  &__user {
    align-items: center;
    display: none;
    height: 100%;
    width: 100%;
    @include grid-media($md) {
      display: flex;
      max-width: 15%;
    }
    ul {
      justify-content: space-evenly;
      li {
        padding-right: 0;
      }
    }
  }
  &__nav-item {
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-family: $font-family;
    font-size: 2vh;
    padding-right: 2vw;
    position: relative;
    &:last-of-type {
      padding-right: 0;
      .admin-nav--icons {
        transform: translateX(-50%);
      }
    }
    .admin-nav--icons {
      align-items: center;
      bottom: -3.5vh;
      display: none;
      height: 19px;
      left: 50%;
      position: absolute;
      transform: translateX(-75%);
      width: 4vw;
      hr {
        background: $gray-light;
        border: 0;
        height: 100%;
        margin: 0;
        width: 0.1vw;
      }
      img {
        &:first-of-type {
          margin-right: 0.75vw;
        }
        &:last-of-type {
          margin-left: 0.75vw;
        }
      }
    }
    @include grid-media($md) {
      &.user {
        .user_avatar {
          background-color: #fff;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 25%;
          border: 2px solid $orange;
          cursor: pointer;
          height: 2.2vw;
          position: relative;
          width: 2.2vw;
          &:after {
            content: '';
            position: absolute;
            background: url('../../../images/svg-images/icon-avatar_dropdown.svg')
              left center no-repeat;
            background-size: 1vw !important;
            height: 1vw;
            right: -1.5vw;
            top: 50%;
            transform: translateY(-50%);
            width: 1vw;
          }
          .dropdown-content {
            display: block;
            right: -3vh;
            top: 1.5vh;
            z-index: 9999;
          }
          &:hover {
            .dropdown-content {
            }
          }
          .userdropdown {
            background: $black;
            padding: 3vh;
            width: 20vw;
            transition: all 0.5s linear;
            &--wrapper {
              display: flex;
              flex-direction: column;
            }
            &--settings {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              &_navigation,
              &_manage,
              &_loggedin,
              &_mode-switch {
                max-width: 50%;
                position: relative;
                width: 100%;
              }
              &_mode-switch {
                h4 {
                  &:before {
                    content: none !important;
                  }
                }
              }
              h4 {
                font-size: 0.7vw;
                font-weight: $font-medium;
                margin-top: 0;
                position: relative;
                &:before {
                  content: '';
                  position: absolute;
                  width: 80%;
                  height: 0.1vh;
                  bottom: -0.5vw;
                  left: 0;
                  background: $gray;
                  opacity: 0.5;
                }
              }
              ul {
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                li {
                  margin-bottom: 3vh;
                  a {
                    border: 0;
                    font-size: 0.9vw;
                    padding: 0;
                    &:hover {
                      background-color: unset;
                      color: $lightOrange;
                    }
                    &.active {
                      color: $lightOrange;
                      font-weight: $font-medium;
                    }
                  }
                }
              }
              &_mode-switch {
                .header__switch {
                  max-width: 100%;
                }
              }
            }
            &--logout {
              a {
                align-items: center;
                background: none;
                border: 0;
                display: flex;
                flex-direction: row;
                font-size: 2vh;
                font-weight: $font-medium;
                justify-content: flex-end;
                padding: 0;
                &:hover {
                  background-color: unset;
                  color: $orange;
                }
              }
              .icon--upload {
                height: 2.5vh;
                margin-right: 1vw;
                transform: rotate(90deg);
              }
            }
          }
        }
      }
      &.login {
        div {
          @include padding(5px 10px);
          background-color: #c83232;
          border-radius: 20px;
          min-width: 129px;
          text-align: center;
        }
      }
    }
  }
  &__logo {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    max-width: 55px;
    width: 100%;
    @include grid-media($md) {
      max-width: 4%;
    }
    img {
      cursor: pointer;
      width: 80%;
    }
    &.mobile {
      display: block;
      @include grid-media($md) {
        display: none;
      }
    }
    &.desktop {
      display: none;
      @include grid-media($md) {
        display: block;
      }
    }
  }
  &__off-canvas-menu {
    background: #000;
    height: 100vh;
    margin-top: -60px;
    padding-top: 60px;
    overflow: auto;
    transform: translate(-250px, 0);
    width: 250px;
    z-index: 100;
    @include grid-media($md) {
      display: none;
    }
    ul {
      li {
        @include padding(0 15px 10px);
        color: $white;
        cursor: pointer;
        font-family: $font-family;
        font-size: 16px;
        text-transform: uppercase;
        @include grid-media($md) {
          @include padding(0 25px 10px);
        }
        &.user {
          .user_avatar {
            background-color: #fff;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            .dropdown {
              &:hover {
                margin-top: 0;
                margin-left: 0;
                @include grid-media($md) {
                  &:hover {
                    margin-top: 40px;
                    margin-left: -120px;
                  }
                }
              }
            }
            .dropdown-content {
              display: block;
              @include grid-media($md) {
                left: unset;
                top: unset;
              }
              .userdropdown--settings_manage,
              .userdropdown--settings_loggedin {
                ul {
                  justify-content: unset;
                  li {
                    &:first-of-type {
                      padding-left: 0;
                    }
                  }
                }
              }
              .userdropdown--logout {
                display: flex;
                justify-content: center;
                margin-top: 20px;
                a {
                  display: flex;
                  padding-left: 0;
                  img {
                    margin-right: 10px;
                    width: 15px;
                  }
                }
              }
            }
          }
        }
        &.notification {
          .dropdown:hover .dropdown-content {
            display: none;
          }
          .dropdown-content.open {
            left: 0;
            right: 0;
            z-index: 10;
            opacity: unset;
            .dropdown__body {
              height: 38.5vh;
            }
          }
        }
        &.mobileMenu {
          margin-top: 25px;
          width: 100%;

          a {
            font-size: 12px;
          }

          .dropdown-content {
            display: flex;
            min-width: unset;
            width: 85%;

            .userdropdown {
              width: 100%;

              &--settings_loggedin {
                ul {
                  li {
                    a {
                      text-align: center;
                      width: 150px;
                    }
                  }
                }
              }

              &--logout {
                a {
                  align-items: center;
                  display: flex;
                  img {
                    margin-right: 10px;
                    max-width: 10%;
                  }
                }
              }
            }
          }

          .userdropdown {
            width: 100%;

            &--logout {
              margin-top: 30px;

              a {
                align-items: center;
                display: flex;
                img {
                  margin-right: 10px;
                  max-width: 10%;
                }
              }
            }
          }
        }
      }
    }
    .header__nav {
      display: block;
    }
    .header__user {
      display: block;
      height: auto;
      @include grid-media($md) {
        display: none;
      }
      ul {
        flex-direction: row;
        flex-wrap: wrap;
        height: unset;
        h4 {
          font-size: 10px;
          font-weight: $font-medium;
          margin-top: 0;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 0.1vh;
            bottom: -0.5vw;
            left: 0;
            background: $gray;
            opacity: 0.5;
          }
        }
        a {
          border-bottom: unset;
        }
      }
    }
    &.off-canvas {
      animation-duration: 0.4s;
      animation-fill-mode: forwards;
      animation-name: off-canvas;
      animation-timing-function: ease;
    }
    /* Standard syntax */
    @keyframes off-canvas {
      from {
        transform: translate(0, 0);
      }
      to {
        transform: translate(-250px, 0);
      }
    }
    &.on-canvas {
      animation-duration: 0.4s;
      animation-fill-mode: forwards;
      animation-name: on-canvas;
      animation-timing-function: ease;
    }
    /* Standard syntax */
    @keyframes on-canvas {
      from {
        transform: translate(-250px, 0);
      }
      to {
        transform: translate(0, 0);
      }
    }
  }
}

// APIIT LOGO
body.theme--apiit {
  .header__logo {
    max-width: 100px;
    @include grid-media($md) {
      max-width: 55px;
    }
  }
}
