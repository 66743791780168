@mixin login_screen_change($theme-color) {
  // Login Screen
  .loginscreen {
    background-position: center !important;
    background-size: cover !important;
    padding-top: 3vh !important;

    &:before {
      opacity: 0.8 !important;
    }

    &__wrapper {
      position: relative !important;
    }

    &__logo {
      background-color: $theme-color !important;
      border-radius: 0 0 1vw 1vw;
      position: absolute !important;
      padding: 0 1vw 1vw 1vw;
      left: 10vw !important;
      width: 9vw !important;
      top: 0 !important;
    }

    &__form {
      position: unset !important;
      height: 100vh !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      flex-direction: column !important;
      padding-top: 15vh !important;

      .icon--logo {
        margin-bottom: 0;
        margin-top: 1vw;
        width: 100%;
      }

      .form__form--fields {
        width: 100% !important;

        .form {
          &--buttons {
            button {
              border-radius: 1vh !important;
              color: #000 !important;
              font-weight: $font-bold !important;
            }
          }

          &--field {
            label {
              font-weight: $font-medium !important;
            }

            .form-input {
              border: none !important;
              border-radius: 1vh !important;
              padding: 0 1.5vh !important;
            }

            &.password-field {
              a {
                font-weight: $font-medium !important;
              }

              input {
                background-size: 2vh !important;
              }

              button {
                height: 2vh !important;
                position: absolute !important;
                right: 1.5vh !important;
                top: 5vh !important;
                width: 2vh !important;
              }
            }
          }
        }
      }

      h2 {
        color: $theme-color !important;
        font-size: 2.8vw !important;
        text-align: center;
      }

      h4 {
        font-weight: $font-medium !important;
        margin-bottom: 10vh !important;
      }
    }
  }
}