// ==========================================================================
// Tooltips
// ==========================================================================

$plyr-tooltip-bg: rgba(#fff, 0.9) !default;
$plyr-tooltip-color: $plyr-color-gray-7 !default;
$plyr-tooltip-padding: ($plyr-control-spacing / 2) !default;
$plyr-tooltip-arrow-size: 4px !default;
$plyr-tooltip-radius: 3px !default;
$plyr-tooltip-shadow: 0 1px 2px rgba(#000, 0.15) !default;
