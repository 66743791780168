.forgot {
  &-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // height: 100vh;
    margin-top: -10vh;
    width: 100%;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: calc(100vh + 5vh);
    margin-top: -15vh;
    padding-top: 15vh;
    position: relative;

    @include grid-media($md) {
      background-position: 0% 20%;
      background-size: 135%;
    }

    &:before {
      background: #000;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      opacity: 0.75;
      top: 0;
      width: 100%;
    }

    .icon--logo {
      width: 100%;
      max-width: 80px;
      margin-bottom: 40px;
      position: relative;
      z-index: 10;

      @include grid-media($md) {
        margin-bottom: 90px;
        max-width: 160px;
      }
    }

    button {
      float: right;
    }
  }

  &-form {
    margin: 0;
    max-width: 70%;
    position: relative;
    width: 100%;

    @include grid-media($md) {
      max-width: 32%;
    }

    &_field {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;

      &.password {
        position: relative;

        input {
          background-position: 98% 50%;
          background-repeat: no-repeat;
          padding-right: 5vh;
        }

        input[type='password'] {
          background-image: url('../../../images/svg-images/icon-password--show.svg');
        }

        input[type='text'] {
          background-image: url('../../../images/svg-images/icon-password--hide.svg');
        }
      }

      button {
        background: none;
        color: transparent;
        height: 1vw;
        position: absolute;
        right: 1.2vh;
        top: 4.5vh;
        width: 1.5vw;
      }
    }

    &_title {
      color: $white;
      cursor: pointer;
      font-family: $font;
      font-size: 18px;
      font-weight: normal;
      height: 100%;
      font-weight: 300;
      margin-bottom: 30px;

      @include grid-media($md) {
        font-size: 1.4vw;
      }
    }

    &_label {
      color: $white;
      cursor: pointer;
      font-family: $font;
      font-size: 12px;
      font-weight: normal;
      height: 100%;
      font-weight: 300;
      margin-bottom: 1vh;

      @include grid-media($md) {
        font-size: 0.9vw;
      }
    }

    &_input {
      @include padding(null 15px);
      // @include font(18, 24);

      background: $dark-gray;
      background-size: 18px 18px !important;
      border: 1px solid $gray-light;
      color: $white;
      height: 5.5vh;
      outline: none;
      width: 100%;

      @include grid-media($md) {
        font-size: 1vw;
      }
    }
  }
}
