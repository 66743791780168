.statspopup {
	&__wrapper {
		display: flex;
	    flex-direction: column;

	    h4 {
	    	margin: 0;
	    }
	}

	&__header {
		border-top: 0.1vh solid $light-gray-1;
		display: flex;
	    flex-direction: row;
		padding: 3vh;

		&--content {
			border-right: 0.1vh solid $light-gray-1;
			max-width: 20%;
		    width: 100%;

		    &:last-of-type {
		    	border: none;
		    }

			&-title {
				margin-bottom: 1vh;

				h4 {
					color: $orange;
					font-size: 2.1vh;
					font-weight: $font-medium;
				}
			}

			&-count {
				h4 {
					color: $black;
					font-size: 3.2vh;
					font-weight: $font-bold;
				}
			}
		}
	}

	&__data {
		&--table {
		    display: flex;
		    flex-direction: column;

			&-header {
				background-color: $light-gray-1;
				margin-bottom: 2vh;
				padding: 3vh;
			}

			&-body {
				.statspopup__data--table-row {
					border-bottom: 0.1vh solid $light-gray-1;
					margin: 0 3vh;
				}
			}
			&-row {
			    display: flex;
			    flex-direction: row;
			}
			&-cell {
				max-width: 20%;
			    width: 100%;

			    &:nth-child(3),
				&:nth-child(4) {
				  max-width: 10%;
				}

				h4 {
					color: $orange;
				}

				p {
					color: $gray;
				}
			}		
		}
	}
}