/**
* Mini Player
*/
.miniPlayer {
  @include aspect-ratio(16, 9);
  display: flex;
  margin: 0 2px;
  position: relative;
  transition: border 0.6s $cubic-transition-2;

  &.active {
    &:hover {
      .plyr {
        &--video {
          opacity: 1;
        }
      }

      .miniPlayer__teaserimg {
        display: none;
      }
    }
  }

  &.inactive {
    &:hover {
      .miniPlayer {
        &__info-arrow {
          opacity: 1;
        }
      }
    }
  }

  .plyr {
    display: none;

    @include grid-media($md) {
      display: block;
    }

    &--video {
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 1s 0.5s $cubic-transition-2;
      z-index: 0;
    }

    &__controls,
    &__control {
      display: none !important;
    }
  }

  &__info-link {
    height: 100%;
    width: 100%;
    display: block;
    @include grid-media($md) {
      display: none;
    }
  }

  &__info-action {
    @include padding(0 10px);
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;

    @include grid-media($md) {
      display: flex;
    }

    .admin-reel_video--icons {
      align-items: center;
      background: $black;
      display: none;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      padding-bottom: 0.2vw;
      position: absolute;
      opacity: 0.7;
      right: -2px;
      top: 0;
      width: 2.5vw;
      z-index: 13;

      img {
        height: 2vh;
      }
    }
  }

  &__teaserimg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 21.3vh;
    position: absolute;
    width: 100%;
  }

  &__info,
  &__action,
  .admin-reel_video--icons .miniPlayer__info-arrow {
  }

  &__click {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  &__action {
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.2vw;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 2vw;
    z-index: 13;

    @include grid-media($md) {
      // top: unset;
    }

    &--audio-off,
    &--audio-on {
      display: none !important;

      &.active {
        display: flex !important;
      }
    }

    &--myList {
      &.active {
        .myList-add {
          display: none;
        }

        .myList-added {
          display: flex;
        }
      }

      &.inactive {
        .myList-add {
          display: flex;
        }

        .myList-added {
          display: none;
        }
      }
    }
  }

  &__info {
    position: absolute;
    display: flex;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    align-self: center;
    flex-direction: column;
    z-index: 13;

    > div {
      @include margin(5px 0 0);
    }

    &--title {
      color: $white;
      font-family: $font-family;
      font-size: 2vh;
      margin-top: 1.2vh;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.watched {
        padding-left: 5vh;
        position: relative;

        &:before {
          background-image: url('../../../images/svg-images/icon-watched-check.svg');
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          left: 1vh;
          height: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;

          @include grid-media($md) {
            background-size: 6.5%;
          }
        }
      }
    }

    &--play-icon {
      border-radius: 50%;
      border: 1px solid rgba($white, 0.5);
      display: flex;
      height: 25px;
      margin-top: 0.25em;
      padding: 6px;
      transition: border 0.4s $cubic-transition-2;
      width: 25px;

      svg {
        fill: $orange;
        height: 100%;
        width: 100%;
      }

      &:hover {
        border: 1px solid $white;
      }
    }

    &--stats {
      font-family: $font-family;
      font-size: 13px;
      color: $white;
    }

    &--info {
      font-family: $font-family;
      font-size: 11px;
      color: $white;
      font-weight: $font-thin;
    }

    &--duedate {
      align-items: center;
      display: flex;
      background: $black;
      padding: 0.7vh 2vh;
      width: 80%;
      position: absolute;
      opacity: 0.75;
      bottom: -1px;
      left: 0;
      z-index: 2;

      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: $black;
        transform-origin: bottom left;
        transform-origin: top right;
        -ms-transform: skew(40deg, 0deg);
        -webkit-transform: skew(40deg, 0deg);
        transform: skew(40deg, 0deg);
      }

      img {
        margin-right: 0.5vw;
        width: 1.2vw;
      }

      span {
        color: $white;
        font-size: 0.8vw;
      }
    }
  }

  &__action-icon {
    border-radius: 50%;
    border: 1px solid rgba($white, 0.5);
    display: flex;
    height: 25px;
    margin-top: 5px;
    padding: 6px;
    transition: border 0.4s $cubic-transition-2;
    width: 25px;

    @include grid-media($md) {
      height: 1.7vw;
      margin-top: 0.25em;
      width: 1.7vw;
    }

    svg {
      fill: rgba($white, 0.5);
      height: 100%;
      transition: fill 0.4s $cubic-transition-2;
      width: 100%;
    }

    &:hover {
      border: 1px solid $white;

      svg {
        fill: $white;
      }
    }

    &.likeBtn {
      &.inactive {
        display: none;
      }

      &.active {
        display: flex;
      }
    }
  }

  &__info-action,
  &__info-arrow {
    opacity: 0;
    transition: opacity 0.4s $cubic-transition-2;
  }

  &__play-arrow {
    position: absolute;
  }

  &__player-wrapper {
    height: 21.3vh !important;
    overflow: hidden;
  }

  &__info-arrow {
    @include margin(auto 0 5px);

    cursor: pointer;
    display: none;
    height: 1vw;

    @include grid-media($md) {
      display: block;
      position: relative;
      width: 100%;
    }

    svg {
      fill: $white;
      left: 50%;
      height: 100%;
      position: absolute;
      top: -3.5vh;
      transform: translateX(-100%);
      transition: fill 0.5s ease;
      width: 15%;

      &:hover {
        fill: $orange;
      }
    }
  }

  &__content {
    bottom: 0;
    height: 21.3vh;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__title,
  &__sub-title {
    &.title {
      padding-left: 20px;
    }
  }

  &__play-arrow {
    cursor: pointer;
    height: 40px;
    width: 40px;

    @include grid-media($md) {
      height: 2vw;
      width: 2vw;
    }
  }
}

.fade {
  animation: fadeout 2s;
  -moz-animation: fadeout 2s; /* Firefox */
  -webkit-animation: fadeout 2s; /* Safari and Chrome */
  -o-animation: fadeout 2s; /* Opera */
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeout {
  /* Firefox */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeout {
  /* Safari and Chrome */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fadeout {
  /* Opera */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.item-Mandatory {
  .slick-slider {
    .miniPlayer__info--duedate {
      display: flex;
    }
  }
}

.item-policy {
  margin: 3vh 1vh;
  position: relative;

  .featured-video__title.moveup {
    @include grid-media($md) {
      top: 6%;
    }
  }
}

// !important use to override the library
.slick-slider {
  padding: 1.5% 1% !important;

  .miniPlayer__info--duedate {
    display: none;
  }

  .slick-prev,
  .slick-next {
    width: 9%;
    z-index: 10 !important;

    svg,
    img {
      height: 5vh;
      width: 2vh;
    }

    &:before {
      content: '';
    }
  }

  .slick-prev {
    left: 0 !important;
    margin-left: 0.2vw;

    svg {
      margin-left: 0.2vw;
    }
  }

  .slick-next {
    right: 0.5% !important;
    text-align: right;

    svg {
      margin-right: 0.2vw;
      transform: rotate(180deg);
    }
  }
}

.bottom-player {
  .slick-prev {
    margin-left: 1vw;
    width: 1%;
  }

  .slick-next {
    right: 1vw !important;
    width: 1%;
  }

  &.theatre__assessment-pagination {
    top: 2px;
  }
}

.plyr__control--overlaid,
.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded='true'] {
  background: $orange !important;
}

.fullScreen {
  .plyr__progress {
    input::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 1vh;
      height: 1vh;
      border-radius: 50%;
      background: $orange !important;
      cursor: pointer;
    }
  }
}

.fullScreen,
.smallScreen {
  .plyr--full-ui input[type='range'] {
    color: $orange !important;
  }
}

.plyr__controls .plyr__menu,
.plyr__controls .plyr__progress,
.plyr__controls .plyr__time,
.plyr__controls .plyr__volume,
.plyr__controls > .plyr__control {
  margin-left: 0;
}
