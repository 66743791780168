.all-users {
  &-table {
    margin-top: 5vh;

    .Dropdown-root {
      margin-bottom: 0 !important;
    }

    &--wrapper {
    }

    &--page {
      &-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5vh;
      }
      &-selection {
        max-width: 15%;
        width: 100%;

        .Dropdown {
          &-control {
            border-radius: 5vw;

            &:before {
              right: 7% !important;
            }
          }
          &-placeholder {
            color: $white !important;
            // font-size: 1.5vw !important;
            font-weight: $font-bold !important;
            padding-left: 0.5vw;
          }
        }
      }

      &-settings {
        align-items: center;
        display: flex;
        flex-direction: row;
        max-width: 65%;
        width: 100%;

        .form-input--search {
          background: url('../../../images/svg-images/icon-search-gray.svg')
            right 0.9vw center no-repeat;
          background-color: $dark-gray-2;
          background-size: 1.5vw !important;
          border-radius: 0;
          height: 5.5vh;
          margin-right: 2vh;
          padding-left: 1vw;
          padding-right: 2.5vw;
          width: 100%;
        }

        .Dropdown-control {
          margin-right: 2vh !important;
          padding: 1.3vh 2vh !important;
        }

        .btn--createnew {
          font-size: 1vw;
          height: 5.5vh;
          margin-right: 2vh;
          min-width: 10vw;
          padding-left: 2%;
          position: relative;
          // width: 100%;

          &:before {
            content: '+';
            font-size: 2vw;
            font-weight: $font-thin;
            left: 5%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .btn--download {
          font-size: 1vw;
          height: 5.5vh;
          margin-right: 2vh;
          min-width: 10vw;
          padding-left: 2%;
          position: relative;
        }
      }
    }

    &--table {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      &-header,
      &-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }

      &-header {
        border-bottom: 0.1vw solid $dark-gray-2;

        .all-users-table--table-cell {
          h5 {
            font-size: 1vw;
            font-weight: $font-bold;
            margin: 2vh 0;
          }
        }
      }

      &-body {
        flex-wrap: wrap;

        .all-users-table--table-row {
          &:hover {
            background-color: $dark-gray-3;
          }
        }

        .all-users-table--table-cell {
          &:nth-of-type(5) {
            p {
              // padding-left: 2vh;
            }
          }
        }

        .btn-stats-download {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      &-row {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1vh 0;
        width: 100%;
      }

      &-cell {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:nth-of-type(1) {
          text-align: center;
          max-width: 15%;
          width: 100%;

          h5,
          p {
            text-align: left;
          }
        }
        &:nth-of-type(2) {
          max-width: 20%;
          width: 100%;
        }
        &:nth-of-type(3) {
          max-width: 17%;
          width: 100%;
        }
        &:nth-of-type(4) {
          max-width: 13%;
          width: 100%;
        }
        &:nth-of-type(5) {
          max-width: 13%;
          text-align: center;
          width: 100%;
        }
        &:nth-of-type(6) {
          max-width: 13%;
          width: 100%;
        }
        &:nth-of-type(7) {
          max-width: 14%;
          width: 100%;
        }
        &:nth-of-type(8) {
          max-width: 13%;
          width: 100%;
          overflow: unset;
        }

        p {
          color: $gray;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .Dropdown-root {
          width: 50% !important;
        }
      }

      &-avatarimg {
        border: 0.1vw solid $white;
        border-radius: 10vw;
        height: 2vw;
        width: 2vw;
      }
    }

    &--grid {
      display: flex;
      flex-direction: column;

      &-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // padding: 0 1.5vw;
      }

      &-header {
        align-items: center;
        border-bottom: 0.1vw solid $dark-gray-2;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 3vh;

        p {
          color: $orange;
          font-size: 1vw;
          padding-left: 1.5vw;
        }

        span {
          align-items: center;
          display: flex;
          font-size: 1vw;
          padding-right: 1.5vw;
        }
      }

      &-card {
        align-items: center;
        background-color: $dark-gray-2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 1%;
        margin-right: 1%;
        max-width: 15.8%;
        padding: 0.7vw 0vw;
        width: 100%;

        &:nth-child(6n) {
          margin-right: 0;
        }

        h5 {
          font-size: 1.1vw;
          font-weight: $font-bold;
          margin-bottom: 1vh;
          margin-top: 3vh;
        }

        p {
          color: $light-gray-2;
          border-bottom: 0.1vw solid $dark-gray-1;
          margin-top: 0;
          padding-bottom: 2vh;
          text-align: center;
          width: 100%;
        }

        button.btn.btn--primary {
          font-size: 1vw;
          border-radius: 10vw;
          height: 4vh;
        }
      }

      &-avatarimg {
        border: 0.1vw solid $white;
        border-radius: 10vw;
        height: 6vw;
        margin-top: 3vh;
        width: 6vw;
      }

      &-personimg {
        margin-right: 0.5vw;
      }
    }

    &--image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 2.5vh;
      cursor: pointer;
      width: 10vh;
      height: 100%;

      &.grid {
        background-image: url('../../../images/svg-images/icon-gridview.svg');
        margin-right: 2vh;

        &:hover,
        &.active {
          background-image: url($icon-gridview-hover);
        }
      }

      &.list {
        background-image: url('../../../images/svg-images/icon-listview.svg');

        &:hover,
        &.active {
          background-image: url($icon-listview-hover);
        }
      }
    }

    &.userrequests {
      .btn-read-more {
        cursor: pointer;
      }

      .group-label {
        background-color: $dark-gray-2;
        padding: 10px 20px 10px 20px;
        color: $gray;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1.5;
        font-size: 1vw;
        border-radius: 0;
        cursor: default;
        border-radius: 30px;
        padding-left: 15px;
      }

      .all-users-table--page-title {
        .all-users-table--page-settings {
          max-width: 35%;
        }
      }

      .all-users-table--table-cell {
        display: flex;
        align-items: center;
        height: 55px;

        p {
          margin: 0;
        }

        &:nth-of-type(1) {
          align-items: center;
          display: flex;
          justify-content: center;
          max-width: 3%;
          padding-left: 15px;
          width: 100%;
        }
        &:nth-of-type(2) {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          max-width: 14%;
          width: 100%;

          img {
            border: none;
            margin-right: 15px;
          }

          p {
            width: 150px;
          }
        }
        &:nth-of-type(3) {
          max-width: 17%;
          width: 100%;
        }
        &:nth-of-type(4) {
          max-width: 10%;
          width: 100%;
        }

        &:nth-of-type(5) {
          max-width: 13%;
          text-align: left;
          width: 100%;
          overflow: initial; // Added this to show the dropdown
        }

        &:nth-of-type(6) {
          text-overflow: unset;
          white-space: unset;
          text-align: justify;
          padding-right: 5px;
          max-width: 10%;
          width: 100%;
          p {
            padding-left: 0;
            text-overflow: unset;
            white-space: unset;
          }
        }
        &:nth-of-type(7) {
          max-width: 10%;
        }

        &:nth-of-type(8) {
          max-width: 15%;
        }

        .Dropdown-root {
          width: 80% !important;

          &.is-open {
            .Dropdown-control {
              border-radius: 30px 30px 0px 0px !important;
            }
          }

          .Dropdown-control {
            border-radius: 30px !important;
            padding-left: 15px !important;
          }
        }

        .form-input--checkbox {
          & + label {
            &:before,
            &:after {
              border-radius: 5px;
              top: -13px;
            }
          }
        }

        .btn.requestbutton {
          border-radius: 5vh;

          button:disabled,
          button[disabled] {
            background-color: $light-gray !important;
          }

          &:first-of-type {
            margin-right: 2vh;
          }
        }
      }

      .all-users-table--table-footer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 30px;

        button {
          width: 130px;
          &:first-of-type {
            margin-right: 20px;
          }
        }
      }
    }

    .btn--request {
      height: 5.5vh;
      margin-right: 2vh;
      padding-right: 0;
    }
  }
}
