.loginscreen {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: calc(100vh + 10vh);
  margin-top: -15vh;
  padding-top: 15vh;
  position: relative;
  @include grid-media($md) {
    background-position: 0% 20%;
    background-size: 135%;
    height: 100vh;
  }
  &:before {
    background: $black;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    opacity: 0.75;
    top: 0;
    width: 100%;
  }
  .container {
    height: 100%;
  }
  h2 {
    font-size: 20px;
    font-weight: $font-bold;
    margin: 0;
    @include grid-media($md) {
      font-size: 1.45vw;
    }
  }
  h4 {
    color: $white;
    font-size: 16px;
    font-weight: $font-thin;
    margin: 0;
    margin-top: 1vh;
    margin-bottom: 3vh;
    @include grid-media($md) {
      font-size: 1vw;
    }
  }
  a {
    color: $light-gray-1;
    font-size: 14px;
    font-weight: $font-thinner;
    margin-top: 0;
    position: absolute;
    right: 0;
    text-decoration: none;
    top: 0;
    @include grid-media($md) {
      font-size: 0.9vw;
    }
  }
  label {
    font-size: 14px;
    font-weight: $font-thin;
    margin-bottom: 1vh;
    @include grid-media($md) {
      font-size: 0.9vw;
    }
  }
  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    &.password {
      justify-content: center;
    }
  }
  &__form {
    margin: 0;
    max-width: 90%;
    position: relative;
    width: 100%;
    @include grid-media($md) {
      max-width: 32%;
    }
    .form--field {
      position: relative;
      &.last {
        margin: 0;
      }
      &.password-field {
        input[type='text'],
        input[type='password'] {
          background-size: contain;
          background-position: 98% 50%;
          background-repeat: no-repeat;
          padding-right: 5vh;
        }
        input[type='password'] {
          background-image: url('../../../images/svg-images/icon-password--show.svg');
        }
        input[type='text'] {
          background-image: url('../../../images/svg-images/icon-password--hide.svg');
        }
        button {
          background: none;
          color: transparent;
          height: 15px;
          position: absolute;
          right: 1.5vh;
          top: 5.7vh;
          width: 15px;
          font-size: 5px;
        }
        .form-input {
          background-size: 2.3vh !important;
        }
      }
      label {
        font-size: 1.2vw;
      }
      .form-input {
        background-color: $white;
        border-radius: 1vh;
        border: none;
        color: $black;
      }
      &.text {
        margin: 40px;
        text-align: center;
      }
      &.federate-sign-button {
        width: 100%;
        border-radius: 15px;
        font-size: 0.8vw;
        color: $black;
        background-color: $white !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .form--buttons {
      margin-top: 0;
      text-align: right;
      button {
        &:first-of-type {
          margin-right: 2vh;
        }
      }
      .btn {
        border-radius: 1vh;

        &:first-of-type {
          color: $black;
        }

        &.btn--loader {
          background-color: transparent !important;
          border-radius: 100%;
          position: relative;
          top: -0.2vw;
        }
      }
    }
    .icon--logo {
      display: flex;
      margin: 0 auto;
      margin-bottom: 3vw;
      width: 50%;
    }

    h2 {
      font-size: 1.2vw;
      font-weight: $font-normal;
      margin-bottom: 4vh;
      text-align: center;
    }
    h4 {
      display: none;
    }
  }
  &__resetpassword {
    background: $black;
    margin: 0;
    margin-top: 12vh;
    max-width: 40%;
    position: relative;
    width: 100%;
    &--page {
      &-title {
        border-bottom: 0.1vw solid $dark-gray-3;
        p {
          font-size: 1vw;
          font-weight: $font-bold;
          padding-left: 1.2vw;
        }
        a {
          color: $white;
          font-size: 2vw;
          font-weight: $font-normal;
          margin: 1.2vw;
        }
      }
      &-body {
        padding: 1.2vw;
      }
      &-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 3vh;
        &:last-of-type {
          justify-content: flex-end;
          margin-bottom: 0;
        }
        &.password-field {
          position: relative;
          input[type='text'],
          input[type='password'] {
            background-size: 3%;
            background-position: 98% center;
            background-repeat: no-repeat;
            padding-right: 5vh;
          }
          input[type='password'] {
            background-image: url('../../../images/svg-images/icon-password--show.svg');
          }
          input[type='text'] {
            background-image: url('../../../images/svg-images/icon-password--hide.svg');
          }
          button {
            background: none;
            color: transparent;
            height: 1vw;
            padding: 0;
            position: absolute;
            right: 1.2vh;
            top: 4.5vh;
            width: 1.5vw;
            &.top-button {
              bottom: 1vh;
              top: unset;
            }
          }
        }
      }
      &-column {
        display: flex;
        flex-direction: column;
        p {
          color: $light-gray-1;
          font-weight: $font-thin;
          margin-top: 0;
        }
      }
      &-userimage {
        border-radius: 1vw;
        margin-right: 3vh;
        width: 5vw;
      }
    }
    h3 {
      color: $red;
      margin-top: 0;
      width: 100%;
    }
    h4 {
      font-size: 1.5vw;
      font-weight: $font-bold;
      margin-bottom: 1vh;
    }
    label {
      font-size: 0.9vw;
      font-weight: $font-bold;
      width: 100%;
    }
    input {
      &[type='password'],
      &[type='text'] {
        background-color: $dark-gray-2;
        border: none;
        color: $white;
        padding: 1vh 1.5vh;
        width: 100%;
        &::-webkit-input-placeholder {
          /* Edge */
          color: $white;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $white;
        }
        &::placeholder {
          color: $white;
        }
      }
    }
  }
}

.changepassword-popup {
  .reel-view--popup-container {
    @include grid-media($sm) {
      height: 70%;
      overflow-y: scroll;
    }
  }

  .reel-view--popup-field {
    position: relative;
    input[type='text'],
    input[type='password'] {
      background-size: 20px;
      background-position: 96% 50%;
      background-repeat: no-repeat;
      padding-right: 5vh;
      @include grid-media($md) {
        background-size: 3.5%;
        background-position: 98% 50%;
      }
    }
    input[type='password'] {
      background-image: url('../../../images/svg-images/icon-password--show.svg');
    }
    input[type='text'] {
      background-image: url('../../../images/svg-images/icon-password--hide.svg');
    }
    button {
      background: none;
      color: transparent;
      height: 1vw;
      min-width: unset !important;
      position: absolute;
      right: 1.2vh;
      top: 1.5vh;
      width: 1.5vw;
      z-index: 9999;
    }
  }
}

// Any Background to be centered
.theme--apiit {
  .loginscreen {
    background-position: -750px 0;
    @include grid-media($xxs) {
      background-position: right;
    }
    @include grid-media($md) {
      background-size: cover;
    }
    @include grid-media($xl) {
      background-size: 135%;
    }
  }
}
