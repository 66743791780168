.dropdown {
  overflow: hidden;
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  background-color: inherit;
  font-family: inherit;

  &:hover {
    margin-top: 40px;
    margin-left: -120px;
  }

  &__header {
    border-bottom: 0.1vw solid $gray;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1vh 2vh;

    p {
      color: $black;
      font-size: 2vh;
      font-weight: $font-medium;
      margin: 0;
    }

    span {
      color: $gray;
      font-size: 2vh;
      font-weight: $font-thin;
    }
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  // border-width: 0.2vh;
  // border-color: $hover-red;
  // border-style: solid;
}

.dropdown-content a {
  background-color: $black;
  border-bottom: solid 1px $buttonblue;
  color: #ffffff;
  display: block;
  float: none;
  font-size: 12px;
  padding: 7% 10%;
  text-align: left;
  text-decoration: none;

  @include grid-media($md) {
    font-size: 1.7vh;
  }
}

.dropdown-content a:hover {
  // background-color: $orange;
  color: $white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.multi-select {
  .dropdown-content {
    display: block;
  }

  .dropdown-heading-dropdown-arrow {
    display: none;
  }

  .select-item {
    width: 100%;

    span {
      color: $light-gray;
      font-size: 1vw;
    }
  }

  .panel-content {
    background-color: $dark-gray-2 !important;
    border-radius: 0;

    input[type='search'] {
      background-color: $dark-gray-2 !important;
      color: $white;
    }
  }

  .dropdown-container {
    background-color: $dark-gray-2 !important;
    border: 0;
    border-radius: 0;

    .dropdown-heading {
      &:before {
        background-image: url('../../../images/svg-images/reel-left-arrow.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        content: '';
        height: 32%;
        right: 2%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        width: 1.5vw;
      }

      span {
        font-size: 1vw;
      }
    }
  }
}
