/*
 * Layout
 */

* {
  box-sizing: border-box;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

html {
  height: 100%;
}

body {
  margin: 0;
  background: $black;
  padding-top: 15vh !important;

  &.popup-active {
    overflow: hidden;
  }

  &.admin {
    padding-top: 12vh !important;
  }
}

ul {
  li {
    list-style: none;
  }
}

img {
  max-width: 100%;
}

svg {
  transition: all 0.4s $cubic-transition-2;
}

.container {
  @include padding(0 15px);

  @include grid-media($md) {
    @include padding(0 1vw);
  }
}

section {
  width: 100%;

  @include grid-container;
}

.hide {
  display: none !important;
}

.content-area--wrapper {
  margin-top: -10vh;
  @include grid-media($md) {
    margin-top: 0;
    min-height: 59vh;
  }
}
