/**
* Bottom Scroll More Component
*/

.scroll-more {
  position: fixed;
  bottom: 0;
  height: 73px;
  width: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%);

  &__container {
    bottom: 0;
     color: $white;
    font-family: $font-family;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    text-transform: uppercase;
    transform: translate(-50%);
    height: 50px;
    width: 100%;

    
    @include grid-media($sm) {
      width: 100%;
    }
  }

  &__title {
    font-size: 12px;
    text-align: center;

    @include grid-media($sm) {
      font-size: 15px;
    }
  }

  &__down-arrow {
    width: 26px;
    height: 15px;
    transform: rotate(270deg);
    margin: 0 auto;

  }
}